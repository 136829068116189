import React, { useState } from 'react'
import propTypes from 'prop-types'
import DialogComponent from '../../transportTender/common/DialogComponent'

import Dropzone from '../../common/Dropzone'
import { withStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import ImportExcludeSupplierTemplate from '../../../resources/files/ImportExcludeSupplierTemplate.xlsx'
import { CloudDownload } from '@material-ui/icons'

const ImportExcludeSupplierFromExcelDialog = (props) => {
	const { onClose, classes, open, onAddExcludeSupplierFromExcelFileFromBackEnd, isSaving } = props

	const [file, setfile] = useState([])

	/**
	 *
	 * @param {*} files
	 * @returns set file in state
	 */
	const onChangeFile = (files) => {
		setfile(files)
		return Promise.resolve('Ok')
	}
	const onDeleteFile = (fileName) => {
		let newFiles = file.filter((file) => {
			return file.name !== fileName
		})
		setfile(newFiles)
		return Promise.resolve('Ok')
	}

	const canBeSaved = () => {
		if (file.length === 0) return false
		return true
	}
	return (
		<DialogComponent
			title={'Importar proveedores a excluir'}
			open={open}
			onSave={()=>onAddExcludeSupplierFromExcelFileFromBackEnd(file)}
			onClose={onClose}
			isSaving={isSaving}
			canBeSaved={canBeSaved()}
			view={'SHOW_EDIT_VIEW'}
			children={
				<div>
					<div>
						<Typography variant='caption'>
							Por favor descarga y llena la plantilla para poder excluir proveedores.
						</Typography>
						<Button
							type='button'
							color='primary'
							variant='outlined'
							className={isSaving ? classes.btnCreateDisable : classes.btnCreate}
							disabled={isSaving}
							href={ImportExcludeSupplierTemplate}
						>
							<CloudDownload />
							<Typography className={classes.titleBtn}>Descargar plantilla</Typography>
						</Button>
					</div>
					<Typography variant='caption' className={classes.title}>
						Por favor, ingresa la plantilla con los proveedores a excluir, ten en cuenta que el campo código
						de proveedor es obligatorio, de lo contrario esos registros serán ignorados.
					</Typography>
					<Dropzone
						files={file}
						onChange={(event) => onChangeFile(event)}
						onDeleteFile={onDeleteFile}
						baseApi={''}
						acceptedFiles={['XLSX']}
						isDisabled={isSaving}
						disabledLink={false}
						maxFiles={1}
						dropzoneText={<Translate id='dropzone.dropzoneBaseText' />}
					/>
				</div>
			}
		/>
	)
}
ImportExcludeSupplierFromExcelDialog.propTypes = {
	title: propTypes.string,
	open: propTypes.bool,
	canBeSaved: propTypes.bool,
	isSaving: propTypes.bool,
	onClose: propTypes.func,
	onSave: propTypes.func,
	onDownloadTemplateFilled: propTypes.func,
}
ImportExcludeSupplierFromExcelDialog.defaultProps = {
	title: '',
	open: false,
	canBeSaved: false,
	isSaving: false,
	onClose: () => console.warn('No [onClose] CallBack defined'),
	onSave: () => console.warn('No [onSave] CallBack defined'),
	onDownloadTemplateFilled: () => console.warn('No [onDownloadTemplateFilled] CallBack defined'),
}
const styles = (theme) => ({
	btnCreate: {
		marginTop: '10px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
	btnCreateDisable: {
		marginTop: '10px',
		width: '100%',
		'&.Mui-disabled': {
			opacity: 0.5,
		},
	},
	titleBtn: {
		color: theme.palette.common.white,
		marginLeft: '8px',
	},
	title: {
		marginTop: '10px',
		display: 'flex',
	},
	errorTitle: {
		color: `${theme.palette.error.main} !important`,
	},
	errorIcon: {
		color: `${theme.palette.error.main} !important`,
	},
})
export default withStyles(styles, { withTheme: true })(ImportExcludeSupplierFromExcelDialog)
