///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import Toaster from '../common/Toaster'
/**Import actions section */
import SendIcon from '@material-ui/icons/Send' // Puedes agregar el icono que prefieras
import FolderIcon from '@material-ui/icons/AttachFile'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import {
	addSurveyAnswer,
	downloadSuveySharedWhithSupplierFile,
	getAvailableSurveysBySupplier,
	updateSupplierSurveyResponseStatusFromBackend,
} from '../../store/survey/SurveyActions'
import LoadingPage from '../common/LoadingPage'
import ShowDesktopTemplates from '../surveyTemplates/components/show/ShowDesktop'
import { converListToArray } from '../../store/helpers/ListHelper'
import {
	Button,
	Grid,
	Tab,
	Tabs,
	Typography,
	Paper,
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
	Divider,
} from '@material-ui/core'
import SendSurveyResponseToReview from './show/SendSurveyResponseToReview'

import { SentimentDissatisfied } from '@material-ui/icons'

const ShowSupplier = (props) => {
	/**Render hooks section */
	const dispatch = useDispatch()

	/**Redux store information section*/

	const isLoading = useSelector((state) => state.surveys.get('isLoading'))
	const isSaving = useSelector((state) => state.surveys.get('isSaving'))
	// const isSaving = useSelector((state) => state.surveys.get('isSaving'))
	/**Redux store information section*/
	const surveys = useSelector((state) =>
		state.surveys.get('surveysAvailable') ? converListToArray(state.surveys.get('surveysAvailable')) : []
	)

	const [selectedTab, setSelectedTab] = useState(0)
	const [sendToReviewDialog, setSendToReviewDialog] = useState({ opened: false, item: null })
	/**Redux store information section*/
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	/**Use effect section */
	useEffect(() => {
		dispatch(getAvailableSurveysBySupplier())
	}, [dispatch])

	if (isLoading) return <LoadingPage />

	const handleChange = (event, newValue) => {
		setSelectedTab(newValue)
	}

	const onOpenCloseSendToReviewPanel = (item) => {
		setSendToReviewDialog({ opened: !sendToReviewDialog.opened, item: item })
	}

	const onAddSurveyAnswerFromBackEnd = (surveyId, questionId, answer) => {
		// if (event) event.stopPropagation()
		return dispatch(addSurveyAnswer(surveyId, questionId, answer))
			.then(() => {
				// dispatch(getAvailableSurveysBySupplier())
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onUpdateSupplierSurveyResponseStatus = () => {
		return dispatch(updateSupplierSurveyResponseStatusFromBackend(sendToReviewDialog.item))
			.then(() => {
				onOpenCloseSendToReviewPanel(null)
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.removeCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onDownloadSuveySharedWhithSupplierFile = (id, fileName) => {
		return dispatch(downloadSuveySharedWhithSupplierFile(id, fileName))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
				return Promise.resolve()
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	return (
		<>
			{surveys.length === 0 && (
				<Grid container justifyContent='center' alignItems='center' style={{ minHeight: '60vh' }}>
					<Paper
						elevation={3}
						style={{
							padding: '30px',
							textAlign: 'center',
							borderRadius: '15px',
							backgroundColor: '#f5f5f5',
							boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
							// maxWidth: '400px',
							width: '100%',
						}}
					>
						<SentimentDissatisfied style={{ fontSize: '50px', color: '#FF6F61' }} />
						<Typography variant='h5' style={{ marginTop: '20px', fontWeight: '500' }}>
							No hay encuestas disponibles
						</Typography>
						<Typography variant='body2' style={{ marginTop: '10px', color: '#555' }}>
							Actualmente no hay encuestas disponibles para este proveedor. Por favor, vuelve más tarde.
						</Typography>
					</Paper>
				</Grid>
			)}

			<Tabs value={selectedTab} onChange={handleChange} aria-label='survey tabs'>
				{surveys.map((survey, index) => (
					<Tab key={survey.Id} label={survey.Name} />
				))}
			</Tabs>
			{surveys.map((survey, index) => {
				return (
					<div
						key={survey.Id}
						role='tabpanel'
						hidden={selectedTab !== index}
						id={`tabpanel-${index}`}
						aria-labelledby={`tab-${index}`}
						sx={{ p: 3 }}
					>
						{selectedTab === index && (
							<>
								{survey &&
									survey.SupplierSurveyResponse &&
									survey.SupplierSurveyResponse.HasSupplierCompletedSurvey && (
										<Grid container>
											<Grid xs={10}></Grid>
											<Grid xs={2}>
												<Button
													onClick={() => onOpenCloseSendToReviewPanel(survey)}
													variant='contained' // Establecemos el tipo de botón (puede ser 'outlined' o 'text')
													color='primary' // Puedes cambiar esto por 'secondary' o un color personalizado
													startIcon={<SendIcon />} // Agregamos un ícono al botón
													style={{
														padding: '10px 20px', // Personalización de padding
														borderRadius: '20px', // Bordes redondeados
														fontWeight: 'bold', // Texto en negrita
														boxShadow: '0 4px 8px rgba(0,0,0,0.2)', // Efecto de sombra
													}}
													onMouseEnter={(e) => (e.target.style.backgroundColor = '#3f51b5')} // Cambio de color en hover
													onMouseLeave={(e) => (e.target.style.backgroundColor = '')} // Vuelve al color original
												>
													Enviar encuesta
												</Button>
											</Grid>
										</Grid>
									)}

								<Grid container>
									<Grid item xs={12}>
										<List>
											{survey &&
												survey.Attachments.map((file) => {
													return (
														<>
															<ListItem>
																<ListItemAvatar>
																	<Avatar>
																		<FolderIcon />
																	</Avatar>
																</ListItemAvatar>
																<ListItemText primary={file.name} />
																<ListItemSecondaryAction>
																	<IconButton
																		aria-label='download'
																		onClick={() =>
																			onDownloadSuveySharedWhithSupplierFile(
																				survey.Id,
																				file.name
																			)
																		}
																		disabled={isSaving}
																	>
																		<CloudDownloadIcon />
																	</IconButton>
																</ListItemSecondaryAction>
															</ListItem>
															<Divider />
														</>
													)
												})}
											{survey && survey.Attachments.length === 0 && (
												<Typography>No existen archivos cargados hasta el momento</Typography>
											)}
										</List>
									</Grid>
								</Grid>

								<ShowDesktopTemplates
									canBeAddedResponse={true}
									isDndEnabled={false}
									surveyTemplate={survey.SurveyTemplate}
									supplierSurveyResponse={survey.SupplierSurveyResponse}
									survey={survey}
									canBeEditData={false}
									onAddSurveyAnswerFromBackEnd={onAddSurveyAnswerFromBackEnd}
								/>
							</>
						)}
					</div>
				)
			})}
			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>

			{sendToReviewDialog.opened && (
				<SendSurveyResponseToReview
					{...sendToReviewDialog}
					isSaving={isSaving}
					open={sendToReviewDialog.opened}
					onClose={onOpenCloseSendToReviewPanel}
					onConfirmOperation={onUpdateSupplierSurveyResponseStatus}
				/>
			)}
		</>
	)
}

const styles = (theme) => ({})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(ShowSupplier)))
