import React, { useState } from 'react'
import {
	Paper,
	IconButton,
	Tooltip,
	Drawer,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	Button,
} from '@material-ui/core'
import { Close as CloseIcon, Info as InfoIcon } from '@material-ui/icons'
import ChatBootIcon from '../../resources/images/ChatBootIcon.png'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'

const ChatBoot = (props) => {
	const { classes } = props
	const [open, setOpen] = useState(false)
	const [openInfo, setOpenInfo] = useState(false)
	
	const handleOpen = () => {
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
	}
	const handleInfoOpen = () => {
		setOpenInfo(true)
	}
	const handleInfoClose = () => {
		setOpenInfo(false)
	}
	return (
		<Paper className={classes.paperContainer}>
			<Tooltip title={'ChatBoot'}>
				<IconButton className={classes.buttonIcon} onClick={handleOpen} style={{ padding: 0 }}>
					<img className={classes.information} src={ChatBootIcon} alt='ChatBoot' />
				</IconButton>
			</Tooltip>
			<Drawer anchor='right' open={open} onClose={handleClose}>
				<div className={classes.container}>
					<IconButton className={classes.iconButtonClose} onClick={handleClose}>
						<CloseIcon />
					</IconButton>
					<Tooltip title='Información'>
						<IconButton className={classes.iconButtonInfo} onClick={handleInfoOpen}>
							<InfoIcon />
						</IconButton>
					</Tooltip>
					<Dialog open={openInfo} onClose={handleInfoClose}>
						<DialogTitle>{<Translate id='chatBoot.dialogInfo.title' />}</DialogTitle>
						<DialogContent>
							<Typography variant='body2' style={{ maxWidth: '200px', wordWrap: 'break-word' }}>
								{<Translate id='chatBoot.dialogInfo.dialogText' />}
							</Typography>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleInfoClose} color='primary'>
								{<Translate id='chatBoot.dialogInfo.close' />}
							</Button>
						</DialogActions>
					</Dialog>

					<h2 className={classes.titleBoot}>{<Translate id='chatBoot.title' />}</h2>
					<iframe
						className={classes.containerFrame}
						src="https://copilotstudio.microsoft.com/environments/Default-8059ac43-14d4-4083-9d01-0804d7a57980/bots/crd7e_fqaSip/webchat?__version__=2"
						title="ChatBot"
						frameBorder="0"
						style={{ width: '100%', height: '100%' }}
					/>
				</div>
			</Drawer>
		</Paper>
	)
}
const styles = (theme) => ({
	paperContainer: {
		backgroundColor: 'transparent',
	},
	information: {
		width: '68px',
		height: '68px',
	},
	buttonIcon: {
		backgroundColor: theme.palette.colors.blue,
		color: theme.palette.colors.white,
	},
	container: {
		width: '400px',
		padding: '16px',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		boxSizing: 'border-box',
		position: 'relative',
		backgroundColor: '#F0F0F0',
	},
	iconButtonClose: {
		position: 'absolute',
		top: '8px',
		right: '8px',
		zIndex: 1,
	},
	iconButtonInfo: {
		position: 'absolute',
		top: '8px',
		left: '8px',
		zIndex: 1,
	},
	titleBoot: {
		marginBottom: '16px',
		textAlign: 'center',
	},
	containerFrame: {
		border: 'none',
		flexGrow: 1,
		borderRadius: '8px',
	},
})
export default withStyles(styles, { withTheme: true })(ChatBoot)
