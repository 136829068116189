///Import react section
import React from 'react'
import moment from 'moment'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'
import { useLocation, useHistory } from 'react-router-dom'

/** Import component section */

/** Material-UI imports section */
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'
import CommentIcon from '@material-ui/icons/Comment'
import Badge from '@material-ui/core/Badge'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import DataTable from '../../common/DataTable'
import LoadingPage from '../../common/LoadingPage'
import { descriptionTransportTEnderStatus } from '../../../store/helpers/TransportTenderHelper'
import { COLOR_STATUS_DANGER, COLOR_SECONDARY_BLUE_LIGHT } from '../../../store/helpers/StatusColorConstants'
import { Checkbox, Tooltip } from '@material-ui/core'
const TransportTenderTable = (props) => {
	const {
		transportTenderinfo,
		transportTenderdata,
		isInternalUser,
		isVisibleColumnLinkTracking,
		selectedTokens,
		onSelectedChecks,
		activeTab,
		isAdministratorTransportTender
	} = props

	let location = useLocation()
	let history = useHistory()

	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onRowClick = (transportTender, event) => {
		props.history.push(`/transportTenderShow/${transportTender.Id}`)
	}
	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		// queryParams.pageNumber = page
		// queryParams.rowsPerPage = rowsPerPage
		if (queryParams.rowsPerPage && parseInt(queryParams.rowsPerPage) !== parseInt(rowsPerPage)) {
			queryParams.pageNumber = 1
		} else {
			queryParams.pageNumber = page + 1
		}

		history.push({ search: queryString.stringify(queryParams) })
	}
	if (transportTenderinfo.isLoadingTransportTender === true) {
		return <LoadingPage />
	}

	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'CheckboxOpion') {
			return (
				<Checkbox
					onClick={(event) => {
						event.preventDefault() // Evita el comportamiento por defecto
						event.stopPropagation() // Detiene la propagación
						onSelectedChecks(event, item) // Ejecuta la lógica de selección
					}}
					checked={selectedTokens.includes(item.Id)}
				/>
			)
		}
		if (dataSource === 'Status') {
			return <Typography variant='caption'>{descriptionTransportTEnderStatus(item.Status)}</Typography>
		}
		if (dataSource === 'DateServiceRequest') {
			return (
				<Typography
					style={{
						maxWidth: '200px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{item && item.DateServiceRequest ? moment(item.DateServiceRequest).format('YYYY/MM/DD') : ''}
				</Typography>
			)
		}
		if (dataSource === 'PlannedClosingDate') {
			return (
				<Typography
					style={{
						maxWidth: '200px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{item && item.PlannedClosingDate ? moment(item.PlannedClosingDate).format('YYYY/MM/DD') : ''}
				</Typography>
			)
		}
		if (dataSource === 'CreationDate') {
			return (
				<Typography
					style={{
						maxWidth: '200px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{item && item.CreationDate ? moment(item.CreationDate).format('YYYY/MM/DD') : ''}
				</Typography>
			)
		}
		if (dataSource === 'ChargingSchedule') {
			return (
				<Typography
					style={{
						maxWidth: '200px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{item && item.StartTime ? moment(item.StartTime).format('HH:mm A') : '-'} -
					{item && item.EndTime ? moment(item.EndTime).format('HH:mm A') : '-'}
				</Typography>
			)
		}
		if (dataSource === 'participatedNumber') {
			return (
				<Badge color='secondary' badgeContent={item.TotalProposals}>
					<GroupOutlinedIcon />
				</Badge>
			)
		}

		if (dataSource === 'ExistsSharedLink') {
			return item.ExistsSharedLink ? (
				<Chip style={{ backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color: 'white' }} label='Si' />
			) : (
				<Chip label='No' style={{ backgroundColor: COLOR_STATUS_DANGER, color: 'white' }} />
			)
		}
		if (dataSource === 'HasDefaultWinnerSap') {
			return item.HasDefaultWinnerSap ? (
				<Tooltip title={`${item.SupplierCodeDefaulWinnerSap}-${item.SupplierNameDefaulWinnerSap}`}><Chip style={{ backgroundColor: COLOR_SECONDARY_BLUE_LIGHT, color: 'white' }} label='Si' /></Tooltip>
			) : (
				<Chip label='No' style={{ backgroundColor: COLOR_STATUS_DANGER, color: 'white' }} />
			)
		}

		if (dataSource === 'ServiceDescription') {
			return (
				<Tooltip title={item.ServiceDescription}>
					{item.ServiceDescription ? (
						<CommentIcon style={{ color: COLOR_SECONDARY_BLUE_LIGHT }} />
					) : (
						<CommentIcon style={{ color: COLOR_STATUS_DANGER }} />
					)}
				</Tooltip>
			)
		}
	}
	console.log('transportTenderinfo', transportTenderinfo)
	return (
		<>
			<DataTable
				data={transportTenderdata}
				configuration={TableConfiguration(isInternalUser, isVisibleColumnLinkTracking, activeTab, isAdministratorTransportTender)}
				onRenderCellItem={onRenderCellItem}
				onChangeSortCriteria={onChangeSortCriteria}
				onChangePage={(newPage, newRowsPerPage) => {
					onChangePage(newPage, newRowsPerPage)
				}}
				onRowClick={onRowClick}
				isIndex
				showFilters
				totalRows={transportTenderinfo.transportTenderCount}
				sortBy={
					transportTenderinfo.sortCriteria && transportTenderinfo.sortCriteria.sortBy
						? transportTenderinfo.sortCriteria.sortBy
						: 'CreationDate'
				}
				sortAscending={
					transportTenderinfo && transportTenderinfo.sortDirection
						? transportTenderinfo.sortDirection === 'desc'
							? false
							: true
						: false
				}
				page={transportTenderinfo && transportTenderinfo.page ? parseInt(transportTenderinfo.page) : 0}
				rowsPerPage={
					transportTenderinfo && transportTenderinfo.rowsPerPage ? transportTenderinfo.rowsPerPage : 20
				}
			/>
		</>
	)
}

function TableConfiguration(isInternalUser, isVisibleColumnLinkTracking, activeTab, isAdministratorTransportTender) {
	let columns = []
	columns.push({
		header: '',
		dataSource: 'status_indicator',
	})
	if (activeTab === 0 && isAdministratorTransportTender)
		columns.push({
			header: '',
			dataSource: 'CheckboxOpion',
		})
	columns.push({
		header: <Translate id='carrierTenders.table.status' />,
		dataSource: 'Status',
	})
	columns.push({
		header: <Translate id='carrierTenders.table.folio' />,
		dataSource: 'Folio',
	})
	columns.push({
		header: <Translate id='carrierTenders.table.creationDate' />,
		dataSource: 'CreationDate',
		isSortable: true,
	})
	columns.push({
		header: <Translate id='carrierTenders.table.uploadDate' />,
		dataSource: 'DateServiceRequest',
		isSortable: true,
	})
	columns.push({
		header: <Translate id='carrierTenders.table.chargingSchedule' />,
		dataSource: 'ChargingSchedule',
		isSortable: true,
	})
	columns.push({
		header: <Translate id='carrierTenders.shipmentid' />,
		dataSource: 'ShipmentId',
		isSortable: true,
	})
	columns.push({
		header: <Translate id='carrierTenders.folioLetter' />,
		dataSource: 'FolioLetter',
		isSortable: true,
	})
	columns.push({
		header: <Translate id='carrierTenders.detail.programmer' />,
		dataSource: 'ProgrammerName',
		isSortable: true,
	})
	columns.push({
		header: <Translate id='carrierTenders.wineCellar' />,
		dataSource: 'WineCellarName',
		isSortable: true,
	})
	columns.push({
		header: <Translate id='carrierTenders.observations' />,
		dataSource: 'ServiceDescription',
		isSortable: true,
	})
	if (isInternalUser) {
		columns.push({
			header: <Translate id='carrierTenders.roundNumber' />,
			dataSource: 'RoundNumber',
			isSortable: false,
		})

		columns.push({
			header: <Translate id='carrierTenders.participatedNumber' />,
			dataSource: 'participatedNumber',
			isSortable: false,
		})
		columns.push({
			header: <Translate id='carrierTenders.hasDefaultWinnerSap' />,
			dataSource: 'HasDefaultWinnerSap',
			isSortable: false,
		})
	}
	if (isVisibleColumnLinkTracking === 'SubmittedLog') {
		columns.push({
			header: <Translate id='carrierTenders.existsSharedLink' />,
			dataSource: 'ExistsSharedLink',
			isSortable: false,
		})
	}

	return { columns }
}
const styles = (theme) => ({})

export default withRouter(withStyles(styles, { withTheme: true })(TransportTenderTable))
