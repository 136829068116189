import { fromJS } from 'immutable'

import * as supplierDriverBlockingActions from './supplierDriverBlockingActions'

/**
 * supplierVehicleBlockingActions initial state
 */
export const supplierVehicleBlockingInitialState = fromJS({
    isLoading: true,
    identifier: '',
    categories: [],
    supplierDriverBlocking: null,
    supplierDriverBlockingsPaginated: {
        items: [],
        pageNumber: 0,
        pageSize: 30,
        total: 0,
        sort: {
            by: 'Title',
            direction: 'desc',
        },
    },
})

export default function surveyReducer(state = supplierVehicleBlockingInitialState, action) {
    switch (action.type) {
        case supplierDriverBlockingActions.IS_LOADING: {
            return state.merge({
                isLoading: action.isLoading,
                identifier: action.identifier,
            })
        }
        case supplierDriverBlockingActions.IS_SAVING: {
            return state.merge({
                isSaving: action.isSaving,
                identifier: action.identifier,
            })
        }
        case supplierDriverBlockingActions.SET_SUPPLIER_DRIVER_BLOCKING_LIST: {
            return state.merge({
                supplierDriverBlockingsPaginated: fromJS(action.data),
                isSaving: false,
                identifier: '',
            })
        }
        case supplierDriverBlockingActions.ADD_SUPPLIER_DRIVER_BLOCKING: {
            return state
                .updateIn(['supplierDriverBlockingsPaginated','items'], (allItems) =>
                    addSupplierVehicleBlocking(allItems, fromJS(action.item))
                )
                .set('isSaving', false)
        }
        case supplierDriverBlockingActions.EDIT_SUPPLIER_DRIVER_BLOCKING: {
            return state
            .updateIn(['supplierDriverBlockingsPaginated','items'], (allItems) =>
                addSupplierVehicleBlocking(allItems, fromJS(action.item))
            )
            .set('isSaving', false)
        }
        case supplierDriverBlockingActions.SUPPLIER_DRIVER_BLOCKING_DETAIL: {
            console.log("action.item=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", action.item)
            return state.merge({
                supplierDriverBlocking: fromJS(action.item),
                isSaving: false,
            })
        }
        case supplierDriverBlockingActions.DELETE_SUPPLIER_DRIVER_BLOCKING: {
            return state
                .updateIn(['supplierDriverBlockingsPaginated','items'], (allItems) =>
                    allItems.filter((item) => item.get('id') !== action.id)
                )
                .set('isSaving', false)
        }
        default:
            return state
    }
}

const addSupplierVehicleBlocking = (allItems, supplierVehicleBlocking) => {
    let indexOfSupplierVehicleBlocking = allItems.findIndex((d) => d.get('id') === supplierVehicleBlocking.get('id'))
    if (indexOfSupplierVehicleBlocking >= 0) {
        return allItems.setIn([indexOfSupplierVehicleBlocking], supplierVehicleBlocking)
    }
    return allItems.push(supplierVehicleBlocking)
}
