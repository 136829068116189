/**Import react section */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
/**Import MaterialUi section */
import { Grid, Avatar, TextField, Typography, Button } from '@material-ui/core'
import CategoryIcon from '@material-ui/icons/Widgets'
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'

/**Import actionsSection */
import { updateSupplierDriverBlockingsFromBackEnd } from '../../../../store/supplierDriverBlocking/supplierDriverBlockingActions'
import DatePicker from '../../../common/DateTimePicker/DatePicker'
import moment from 'moment'
import SupplierSelector from '../../../common/SupplierSelector'

const EditSupplierDriverBlockingPanel = (props) => {
	/**props section */
	const { onCloseButtonClick, classes, item } = props

	const dispatch = useDispatch()
	const isSaving = useSelector((state) => state.supplierDriverBlockings.get('isSaving'))

	const [formData, setFormData] = useState({
		BlockingDate: '',
		DriverName: '',
		VehicleLicensePlate: '',
		Reason: '',
		TransportLines: '',
		SupplierCode: '',
		SupplierName: '',
	})

	// /**Use effect section */
	useEffect(() => {
		if (props.item) {
			const [day, month, year] = props.item.BlockingDate.split('/') // Dividimos la fecha en partes
			const formattedDate = `${year}-${month}-${day}` // Reorganizamos a YYYY-MM-DD
			setFormData({ ...props.item, BlockingDate: formattedDate })
		}
	}, [])

	const onUpdateSupplierDriverBlocking = () => {
		return dispatch(updateSupplierDriverBlockingsFromBackEnd(item.Id, formData))
			.then(() => {
				onCloseButtonClick()
			})
			.catch((response) => {})
	}

	const canBeSaved = () => {
		return !Object.values(formData).some(IsNullOrEmpty)
	}

	const onPropertyChange = (event) => {
		const { name, value } = event.target
		setFormData({ ...formData, [name]: value })
	}

	const onChangeDate = (propertyName, value) => {
		setFormData({ ...formData, [propertyName]: value })
	}

	const onSelectedChange = (option) => {
		setFormData({
			...formData,
			SupplierCode: option ? option.code : '',
			SupplierName: option ? option.name : '',
			SupplierRFC: option ? option.rfc : '',
		})
	}

	return (
		<LateralPanel onClose={onCloseButtonClick}>
			<PanelHeader
				titlePanel={<span>{<Translate id='supplierDriversBlocking.edit.title' />}</span>}
				onCloseButtonClick={onCloseButtonClick}
				icon={
					<Avatar className={classes.avatar}>
						<CategoryIcon />
					</Avatar>
				}
				isIconMaterial={true}
			/>
			<div>
				<div className={classes.containerInformation}>
					<Grid container>
						<Grid item xs={12} style={{ marginTop: '1rem' }}>
							<Typography>
								<Translate id='supplierDriversBlocking.create.transportLines' />
							</Typography>
							<SupplierSelector onSupplierSelected={(supplier) => onSelectedChange(supplier)} />
						</Grid>

						<Grid item xs={12}>
							<div className={classes.createTextField}>
								<TextField
									value={formData ? formData.SupplierName : ''}
									fullWidth
									margin='normal'
									variant='outlined'
									name='SupplierName'
									label={<Translate id='factoringAffiliation.table.supplierName' />}
									onChange={onPropertyChange}
									disabled={isSaving}
								/>
							</div>
						</Grid>
						<Grid item xs={12}>
							<Typography color='primary' variant='subtitle1'>
								<Translate id='supplierDriversBlocking.create.blockingDate' />
							</Typography>
							<DatePicker
								// opens={'left'}
								onChangeRange={(event) => onChangeDate('BlockingDate', event)}
								// disabled={!canBeEditSupplier}
								startDate={formData.BlockingDate ? moment(formData.BlockingDate) : ''}
								minDate={moment()}
							/>
						</Grid>
						{/** Driver Name */}
						<Grid item xs={12}>
							<TextField
								fullWidth
								name='DriverName'
								label={<Translate id='supplierDriversBlocking.create.driverName' />}
								value={formData.DriverName}
								onChange={onPropertyChange}
								disabled={isSaving}
								variant='outlined' // Borde del campo
								size='small' // Tamaño pequeño
								margin='normal'
							/>
						</Grid>
						{/** Vehicle License Plate */}
						<Grid item xs={12}>
							<TextField
								fullWidth
								name='VehicleLicensePlate'
								label={<Translate id='supplierDriversBlocking.create.vehicleLicensePlate' />}
								value={formData.VehicleLicensePlate}
								onChange={onPropertyChange}
								disabled={isSaving}
								variant='outlined' // Borde del campo
								size='small' // Tamaño pequeño
								margin='normal'
							/>
						</Grid>

						{/** Reason/Details */}
						<Grid item xs={12}>
							<TextField
								fullWidth
								multiline
								rows={4}
								name='Reason'
								label={<Translate id='supplierDriversBlocking.create.reason' />}
								value={formData.Reason}
								onChange={onPropertyChange}
								disabled={isSaving}
								variant='outlined' // Borde del campo
								size='small' // Tamaño pequeño
								margin='normal'
							/>
						</Grid>
					</Grid>
				</div>
				<Grid container>
					<Grid item xs={12} className={classes.containerBtn}>
						<Button
							data-tut='reactour-save'
							data-cy='btn-save'
							type='button'
							color='primary'
							variant='outlined'
							onClick={onUpdateSupplierDriverBlocking}
							disabled={!canBeSaved() || isSaving}
						>
							{<Translate id='common.save' />}
						</Button>
					</Grid>
				</Grid>
			</div>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	expansionPanelSummary: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		paddingLeft: '20px',
		paddingTop: '10px',
		paddingBotton: '20px',
		paddingRight: '20px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	createTextField: {
		paddingTop: '15px',
		PaddingBottom: '15px',
	},
})

EditSupplierDriverBlockingPanel.propTypes = {
	/**
	 * callback executed add new user in the list
	 */
	onSaveButtonClick: propTypes.func,
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseButtonClick: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired,
}

EditSupplierDriverBlockingPanel.defaultProps = {
	onSaveButtonClick: () => console.warn('Callback [onSaveButtonClick] no defined'),
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	isSaving: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(EditSupplierDriverBlockingPanel))
