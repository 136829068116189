import { fromJS } from 'immutable'

import * as surverActions from './SurveyActions'

/**
 * surverActions initial state
 */
export const surveyInitialState = fromJS({
	isLoading: true,
	identifier: '',
	categories: [],
	surveysAvailable: [],
	survey: null,
	surveysPaginated: {
		items: [],
		pageNumber: 0,
		pageSize: 30,
		total: 0,
		sort: {
			by: 'Title',
			direction: 'desc',
		},
	},
})

export default function surveyReducer(state = surveyInitialState, action) {
	switch (action.type) {
		case surverActions.IS_LOADING: {
			return state.merge({
				isLoading: action.isLoading,
				identifier: action.identifier,
			})
		}
		case surverActions.IS_SAVING: {
			return state.merge({
				isSaving: action.isSaving,
				identifier: action.identifier,
			})
		}
		case surverActions.SET_SURVEY_LIST: {
			return state.merge({
				surveysPaginated: fromJS(action.data),
				isSaving: false,
				identifier: '',
			})
		}
		case surverActions.SURVEY_DETAIL: {
			return state.merge({
				survey: fromJS(action.item),
				isSaving: false,
			})
		}
		case surverActions.SET_AVAILABLE_SURVEYS_BY_SUPPLIER: {
			return state.merge({
				surveysAvailable: fromJS(action.items),
				isSaving: false,
			})
		}

		case surverActions.SET_SURVEY_RESPONSE_SUPPLIER: {
			let data = fromJS(action.item)
			return state
				.updateIn(['surveysAvailable'], (allSurveysAvailable) => pushSurveyAnswer(allSurveysAvailable, data))
				.set('isSaving', false)
		}

		case surverActions.DELETE_SURVEY_ANSWERED_BY_SUPPLIER:{
			return state.updateIn(['surveysAvailable'], allSurveysAvailable => allSurveysAvailable.filter(section => section.get('Id') !== action.item.Id)).set('isSaving', false);
		}

		default:
			return state
	}
}

const pushSurveyAnswer = (allSurveysAvailable, newSurveysAvailable) => {
	let indexOfBidding = allSurveysAvailable.findIndex((d) => d.get('Id') === newSurveysAvailable.get('Id'))
	if (indexOfBidding >= 0) {
		return allSurveysAvailable.setIn([indexOfBidding], newSurveysAvailable)
	}
	return allSurveysAvailable.unshift(newSurveysAvailable)
}
