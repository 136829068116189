import React, { useState } from 'react'
import propTypes from 'prop-types'
import DialogComponent from '../../transportTender/common/DialogComponent'

import { withStyles } from '@material-ui/core/styles'
import {  Grid, TextField, Typography } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import moment from 'moment'
import DatePicker from '../../common/DateTimePicker/DatePicker'

const UpdateDatesDialog = (props) => {
	const { onClose, open, onEditSurveyDatesFromBackEnd, isSaving } = props

	const [surveyModel, setSurveyModel] = useState(props.survey)

	const canBeSaved = () => {
		// Add validation logic if needed
		return true
	}

	// Calculate the minimum expiration date
	let minExpirationDate = surveyModel.ActivationDate ? moment(surveyModel.ActivationDate).add(1, 'days') : moment()

	const onUpdateProperty = (property, value) => {
		setSurveyModel({
			...surveyModel,
			[property]: value,
		})
	}

	return (
		<DialogComponent
			title='Actualizar fechas de encuesta'
			open={open}
			onClose={onClose}
			isSaving={isSaving}
			canBeSaved={canBeSaved()}
			view='SHOW_EDIT_VIEW'
			onSave={() => onEditSurveyDatesFromBackEnd(surveyModel)}
			children={
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography>
							<Translate id='officialNotices.activationDate' />
						</Typography>
						<TextField
							fullWidth
							id='name'
							label={<Translate id='surveys.name' />}
							value={surveyModel ? surveyModel.Name : ''}
							margin='dense'
							variant='outlined'
							onChange={(event) => {
								onUpdateProperty('Name', event.target.value)
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<Typography>
							<Translate id='officialNotices.activationDate' />
						</Typography>
						<DatePicker
							startDate={moment(surveyModel.ActivationDate)} // Automatically handles ISO 8601
							minDate={moment()} // Prevent selecting past dates
							onChangeRange={(event) => {
								onUpdateProperty('ActivationDate', event)
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography>
							<Translate id='officialNotices.expirationDate' />
						</Typography>
						<DatePicker
							startDate={moment(surveyModel.ExpirationDate)} // Automatically handles ISO 8601
							minDate={minExpirationDate} // Minimum is one day after activation date
							onChangeRange={(event) => {
								onUpdateProperty('ExpirationDate', event)
							}}
						/>
					</Grid>
				</Grid>
			}
		/>
	)
}

UpdateDatesDialog.propTypes = {
	title: propTypes.string,
	open: propTypes.bool,
	canBeSaved: propTypes.bool,
	isSaving: propTypes.bool,
	onClose: propTypes.func,
	onAddExcludeSupplierFromExcelFileFromBackEnd: propTypes.func,
	survey: propTypes.shape({
		ActivationDate: propTypes.string,
		ExpirationDate: propTypes.string,
	}),
}

UpdateDatesDialog.defaultProps = {
	title: '',
	open: false,
	canBeSaved: false,
	isSaving: false,
	onClose: () => console.warn('No [onClose] CallBack defined'),
	onAddExcludeSupplierFromExcelFileFromBackEnd: () =>
		console.warn('No [onAddExcludeSupplierFromExcelFileFromBackEnd] CallBack defined'),
	survey: {
		ActivationDate: null,
		ExpirationDate: null,
	},
}

const styles = (theme) => ({
	btnCreate: {
		marginTop: '10px',
		width: '100%',
		color: `${theme.palette.secondary.contrastText} !important`,
		backgroundColor: `${theme.palette.primary.main} !important`,
		'&.Mui-disabled': {
			color: `${theme.palette.secondary.contrastText} !important`,
			backgroundColor: `${theme.palette.colors.green} !important`,
			opacity: 0.5,
		},
	},
	btnCreateDisable: {
		marginTop: '10px',
		width: '100%',
		'&.Mui-disabled': {
			opacity: 0.5,
		},
	},
	titleBtn: {
		color: theme.palette.common.white,
		marginLeft: '8px',
	},
	title: {
		marginTop: '10px',
		display: 'flex',
	},
	errorTitle: {
		color: `${theme.palette.error.main} !important`,
	},
	errorIcon: {
		color: `${theme.palette.error.main} !important`,
	},
})

export default withStyles(styles, { withTheme: true })(UpdateDatesDialog)
