import React from 'react'
import {
	Typography,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Avatar,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Chip,
	Grid,
	Button,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CalendarToday, Business } from '@material-ui/icons'
// Importaciones de Material-UI
import { withStyles } from '@material-ui/core/styles'
// import PercentIcon from '@material-ui/icons/Percent';
// import SupplierCard from './SupplierCard'
// import SurveyCard from './SurveyCard'
import ShowDesktopTemplates from '../../surveyTemplates/components/show/ShowDesktop'

const SupplierInSurveyResponses = (props) => {
	const { survey, onDownloadSurverSupplierResponses, isSaving } = props
	return (
		<>
			{/* Información de los proveedores */}
			<div>
				<Typography variant='h6' color='textPrimary' style={{ fontWeight: 'bold', marginBottom: 16 }}>
					Proveedores
				</Typography>

				{/* Botón para descargar respuestas */}
				<Button
					variant='contained'
					color='primary'
					onClick={onDownloadSurverSupplierResponses}
					style={{ marginBottom: 16 }}
					disabled={survey.SupplierSurveyResponses.length === 0 || isSaving}
				>
					Descargar respuestas de proveedores
				</Button>
			</div>

			{/* Lista de respuestas de proveedores */}
			{survey.SupplierSurveyResponses.map((supplierSurveyResponse) => (
				<ExpansionPanel key={supplierSurveyResponse.Id}>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls={`panel-${supplierSurveyResponse.Id}-content`}
						id={`panel-${supplierSurveyResponse.Id}-header`}
					>
						<Grid container alignItems='center' justifyContent='space-between'>
							<Grid item>
								<Typography>
									{supplierSurveyResponse.SurveySupplier.Code +
										' | ' +
										supplierSurveyResponse.SurveySupplier.Name || 'Proveedor Desconocido'}
								</Typography>
							</Grid>
							<Grid item>
								<Chip
									label={`${supplierSurveyResponse.Rating}%`}
									style={{
										backgroundColor: '#4caf50',
										color: '#fff',
										fontWeight: 'bold',
									}}
								/>
							</Grid>
						</Grid>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<Grid container alignItems='center' justifyContent='space-between'>
							<Grid item xs={12}>
								<List>
									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<Business />
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary='Código'
											secondary={supplierSurveyResponse.SurveySupplier.Code || 'No disponible'}
										/>
									</ListItem>

									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<CalendarToday />
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary='Fecha de Respuesta'
											secondary={supplierSurveyResponse.ResponseDate || 'No disponible'}
										/>
									</ListItem>
								</List>
								<ShowDesktopTemplates
									canBeAddedResponse={false}
									isDndEnabled={false}
									surveyTemplate={survey.SurveyTemplate}
									supplierSurveyResponse={supplierSurveyResponse}
									survey={survey}
									canBeEditData={false}
								/>
							</Grid>
						</Grid>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			))}
		</>
	)
}
const styles = (theme) => ({})

export default withStyles(styles)(SupplierInSurveyResponses)
