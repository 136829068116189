import React from 'react'
import classnames from 'classnames'
import autoBind from 'auto-bind'

import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withLocalize, Translate } from 'react-localize-redux'
import Tooltip from '@material-ui/core/Tooltip'
import LanguageIcon from '@material-ui/icons/Language'
import IconButton from '@material-ui/core/IconButton'

/** Import component */
import DialogChangeLanguage from '../common/DialogChangeLanguage'
import DialogChangeCompany from '../common/DialogChangeCompany'

/** Import imges */
import logotypeFemsa from '../../resources/images/logo-femsa.png'

/** Resources import themes portal */
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import themeBase from '../../resources/themes/themeCommon.json'
import themeImbera from '../../resources/themes/themeImbera.json'
import themePtm from '../../resources/themes/themePTM.json'
import themeRepare from '../../resources/themes/themeRepare.json'

import { COMPANY_ACTIVE, COMPANY_IMBERA, COMPANY_PTM, COMPANY_REPARE } from '../../store/helpers/ResourcesHelper'
import Chatboot from '../ChatBoot/ChatBoot'

/**
 * Anonymous application bar component
 *
 * @class AnonymousApplicationBar
 * @extends {React.Component}
 */
class AnonymousApplicationBar extends React.Component {
	/**
	 * Constructor
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openLanguageDialog: false,
			openCompanyDialog: false,
			themeActive: createMuiTheme(themeBase),
		}
	}

	/**
	 *
	 * Action to set open change lenguage
	 * @memberof CardsContainer
	 */
	setOpelLanguage = (openLaguange) => {
		this.setState({
			openLanguageDialog: openLaguange,
		})
	}

	/**
	 * Change the language in portal
	 * @param {*} laguange
	 */
	changeLanguage(laguange) {
		this.props.setActiveLanguage(laguange)
		localStorage.setItem('language', laguange)
		this.setState({ openLanguageDialog: false })
	}

	/**
	 *
	 * Action to set open change company
	 * @memberof
	 */
	setOpenDialogCompany = (openCompany) => {
		this.setState({
			openCompanyDialog: openCompany,
		})
	}

	/**
	 * Function to change company active or selected
	 * @param {*} companyToChange
	 */
	onChangeCompanySelected(companyToChange) {
		if (this.state.grantedCompanies && this.state.grantedCompanies.length > 0) {
			let grantedCompany = this.state.grantedCompanies.filter((company) => {
				return company.document_identifier === companyToChange
			})
			/// setCompanyActive
			if (grantedCompany.length > 0) {
				this.props.changeCompanySelected(grantedCompany[0])
			}
		}
	}

	/**
	 * Change the company in portal and save company active in local starage
	 * @param {*} company
	 */
	changeCompany(company) {
		switch (company) {
			case COMPANY_IMBERA: {
				this.setState({
					themeActive: createMuiTheme(themeImbera),
					openCompanyDialog: false,
				})
				this.onChangeCompanySelected(company)
				break
			}
			case COMPANY_PTM: {
				this.setState({
					themeActive: createMuiTheme(themePtm),
					openCompanyDialog: false,
				})
				this.onChangeCompanySelected(company)
				break
			}
			case COMPANY_REPARE: {
				this.setState({
					themeActive: createMuiTheme(themeRepare),
					openCompanyDialog: false,
				})
				this.onChangeCompanySelected(company)
				break
			}
			default:
				break
		}
		localStorage.setItem(COMPANY_ACTIVE, company)
	}

	/**
	 * Render component
	 *
	 * @returns
	 * @memberof AnonymousApplicationBar
	 */
	render() {
		const { classes, openLogin } = this.props
		return (
			<div>
				<MuiThemeProvider theme={this.state.themeActive}>
					<AppBar position='static' color='primary'>
						<Toolbar>
							<Typography variant='h5' gutterBottom color='inherit' className={classnames(classes.grow)}>
								<img className='logo-femsa-sip' src={logotypeFemsa} alt='logotypeFemsaSip' />
							</Typography>
							<Tooltip title={<Translate id='common.language' />}>
								<IconButton
									onClick={() => this.setOpelLanguage(true)}
									data-cy='btn-exit'
									color='inherit'
								>
									<LanguageIcon color='inherit' />
								</IconButton>
							</Tooltip>

							{!openLogin && (
								<Button color='inherit' onClick={this.props.onLoginRequest}>
									<Translate id='anonymousView.login' />
								</Button>
							)}
						</Toolbar>
					</AppBar>

					{/** Dialog change language */}
					<DialogChangeLanguage
						{...this.props}
						openLanguageDialog={this.state.openLanguageDialog}
						setOpelLanguage={this.setOpelLanguage}
						changeLanguage={this.changeLanguage}
					/>
					{/** Dialog change company */}
					<DialogChangeCompany
						{...this.props}
						openCompanyDialog={this.state.openCompanyDialog}
						setOpenDialogCompany={this.setOpenDialogCompany}
						changeCompany={this.changeCompany}
						grantedCompanies={this.state.grantedCompanies}
					/>
				</MuiThemeProvider>
				{/* <div
					style={{
						position: 'fixed',
						bottom: '16px',
						right: '16px',
						zIndex: 1000,
						backgroundColor: 'transparent',
						padding: '0',
					}}
				>
					<Chatboot />
				</div> */}
			</div>
		)
	}
}

const layoutStyles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	grow: {
		flexGrow: 1,
	},
})

export default withLocalize(withStyles(layoutStyles, { withTheme: true })(AnonymousApplicationBar))
