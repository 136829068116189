import React, { useState } from 'react'
import {
	Card,
	CardContent,
	Typography,
	Divider,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Avatar,
	Tabs,
	Tab,
	ListItemSecondaryAction,
	IconButton,
	Grid,
} from '@material-ui/core'
import { CalendarToday, Edit, Person } from '@material-ui/icons'
import FolderIcon from '@material-ui/icons/AttachFile'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
// Importaciones de Material-UI
import { withStyles } from '@material-ui/core/styles'
// import PercentIcon from '@material-ui/icons/Percent';
// import SupplierCard from './SupplierCard'
// import SurveyCard from './SurveyCard'
import SurveyShareds from './SurveyShareds'
import ShowDesktopSurveyTemplate from '../../surveyTemplates/components/show/ShowDesktop'
import SupplierInSurveyResponses from './SupplierInSurveyResponses'
import ExcludeSuppliers from './ExcludeSuppliers'
const ShowDesktop = (props) => {
	const { survey, isSaving } = props
	const {
		onAddExcludeSupplierFromBackEnd,
		onRemoveExcludeSupplierFromBackEnd,
		onAddExcludeSupplierFromExcelFileFromBackEnd,
		onOpenClosePanelEditDates,
		onDownloadSurverSupplierResponses,
		onDownloadSuveySharedWhithSupplierFile
	} = props

	const [activeTab, setActiveTab] = useState(0)

	if (!survey) {
		return (
			<Typography variant='h6' color='textSecondary' align='center'>
				No hay información disponible.
			</Typography>
		)
	}

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue)
	}

	console.log('survey', survey)
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#f5f5f5',
				padding: 16,
			}}
		>
			<Card
				style={{
					width: '100%',
					borderRadius: 16,
					boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
					backgroundColor: '#ffffff',
				}}
			>
				<CardContent>
					<Typography
						variant='h5'
						color='primary'
						align='center'
						style={{ fontWeight: 'bold', marginBottom: 16 }}
					>
						Detalles de la Encuesta
					</Typography>
					<Divider style={{ marginBottom: 24 }} />

					{/* Tabs Header */}
					<Tabs
						value={activeTab}
						onChange={handleTabChange}
						indicatorColor='primary'
						textColor='primary'
						centered
					>
						<Tab label='Información General' />
						<Tab label='Compartido con proveedores' />
						<Tab label='Proveedores excluidos' />
						<Tab label='Respuesta de proveedores' />
						<Tab label='Plantilla usada' />
					</Tabs>

					<Divider style={{ margin: '16px 0' }} />

					{/* Tabs Content */}
					{activeTab === 0 && (
						<List>
							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<Person />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary='Nombre' secondary={survey.Name || 'No disponible'} />
								<ListItemSecondaryAction>
									<IconButton onClick={onOpenClosePanelEditDates}>
										<Edit />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>

							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<CalendarToday />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary='Fecha de Creación'
									secondary={new Date(survey.CreationDate).toLocaleString() || 'No disponible'}
								/>
							</ListItem>

							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<CalendarToday />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary='Fecha de Activación'
									secondary={new Date(survey.ActivationDate).toLocaleString() || 'No disponible'}
								/>
							</ListItem>

							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<CalendarToday />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary='Fecha de Expiración'
									secondary={new Date(survey.ExpirationDate).toLocaleString() || 'No disponible'}
								/>
							</ListItem>

							<Grid container>
								<Grid item xs={12}>
									<List>
										{survey &&
											survey.Attachments.map((file) => {
												return (
													<>
														<ListItem >
															<ListItemAvatar>
																<Avatar>
																	<FolderIcon />
																</Avatar>
															</ListItemAvatar>
															<ListItemText primary={file.name} />
															<ListItemSecondaryAction>
														
																	<IconButton
																		aria-label='download'
																		onClick={() =>
																			onDownloadSuveySharedWhithSupplierFile(
																				file.name,
																			)
																		}
																		disabled={isSaving}
																	>
																		<CloudDownloadIcon />
																	</IconButton>
															</ListItemSecondaryAction>
														</ListItem>
														<Divider />
													</>
												)
											})}
										{survey && survey.Attachments.length === 0 && (
											<Typography>No existen archivos cargados hasta el momento</Typography>
										)}
									</List>
								</Grid>
							</Grid>
						</List>
					)}

					{activeTab === 1 && <SurveyShareds survey={survey} />}

					{activeTab === 2 && (
						<ExcludeSuppliers
							//bools
							isSaving={isSaving}
							//objects
							survey={survey ? survey : null}
							//functions
							onAddExcludeSupplierFromBackEnd={onAddExcludeSupplierFromBackEnd}
							onRemoveExcludeSupplierFromBackEnd={onRemoveExcludeSupplierFromBackEnd}
							onAddExcludeSupplierFromExcelFileFromBackEnd={onAddExcludeSupplierFromExcelFileFromBackEnd}
						/>
					)}

					{activeTab === 3 && (
						<SupplierInSurveyResponses
							//bools
							isSaving={isSaving}
							survey={survey ? survey : null}
							canBeEditData={false}
							onDownloadSurverSupplierResponses={onDownloadSurverSupplierResponses}
						/>
					)}

					{activeTab === 4 && (
						<ShowDesktopSurveyTemplate
							surveyTemplate={survey ? survey.SurveyTemplate : null}
							canBeEditData={false}
						/>
					)}

					{activeTab === 5 && (
						<Typography variant='body1' color='textSecondary' align='center'>
							Contenido para la tercera sección.
						</Typography>
					)}
				</CardContent>
			</Card>
		</div>
	)
}

const styles = (theme) => ({})

export default withStyles(styles)(ShowDesktop)
