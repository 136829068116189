/** React import section */
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import autoBind from 'auto-bind'

/** Import Material-UI */
import { withStyles } from '@material-ui/core/styles'
import ApplicationBar from './AuthenticatedApplicationBar'
import LateralMenuExternalUser from './LateralMenuExternalUser'
import LateralMenuInternalUser from './LateralMenuInternalUser'
import FooterContainer from './FooterContainer'

/** Import components section */
import userManager from '../../store/helpers/UserManager'

import { IsCurrentActiveDirectoryUser, IsCurrentNotActiveDirectoryUser } from '../../store/helpers/SessionHelper'
import {
	loadLoggedUserProfile,
	loadUserProfileRoles,
	setIsOpenClosedLateralMenu,
} from '../../store/profile/ProfileActions'
import { converListToArray } from '../../store/helpers/ListHelper'
import {
	loadOfficialNoticeForAccepted,
	loadOfficialNoticeInitialView,
	getTotalNotifications,
} from '../../store/administration/OfficialNoticeActions'
import { loadNotificationsByUser, loadNotificationsOutsorcing } from '../../store/notifications/NotificationAction'
import { loadAdvanceOptionsSupplier, loadSupplier } from '../../store/suppliers/SupplierActions'
import { loadEthicCodeFromResponseSupplier } from '../../store/ethicCode/ethicCodeAction'
import { loadContactSupplierForUpdate } from '../../store/contactsupplier/ContactSupplierAction'

/** Import resources */
import '../../resources/styles/authenticatedLayout.css'
import '../../resources/styles/dropzone.css'
import '../../resources/styles/bootstrap.min.css'
import '../../resources/styles/autosuggest.css'
import '../../resources/styles/loader.css'
import '../../resources/styles/main.css'
import '../../resources/styles/ReactCrop.css'
import '../../resources/styles/resetpassword.css'
import '../../resources/styles/dropzone.scss'

import Grid from '@material-ui/core/Grid'
import { Translate } from 'react-localize-redux'
import LoadingPage from '../common/LoadingPage'
import { BACKGROUND_COLOR_GRAY } from '../../store/helpers/StatusColorConstants'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'
import { getActivePrivacyNotice } from '../../store/companies/CompanyActions'
import { checkPrivacyNotice } from '../../store/helpers/NoticeOfPrivacy'
import { getAvailableSurveysBySupplier } from '../../store/survey/SurveyActions'

/**
 * HOC component for wrapp a component in a layout
 *
 * @param {*} WrapperContainer
 * @returns
 */
function withauthenticatedLayoutHOC(WrapperContainer) {
	return class extends React.Component {
		/**
		 * Constructor
		 */
		constructor(props) {
			super(props)
			autoBind(this)
			this.state = {
				open: true,
				openLanguageDialog: false,
				existCompany: false,
			}
		}

		/**
		 * Login request
		 *
		 */
		onLogoutRequest() {
			localStorage.removeItem('relativeUrl')
			userManager.signoutRedirect()
		}

		/**
		 * Set the lateral menu open state
		 *
		 * @param {*} openState
		 */
		setLateralMenuOpenState(openState) {
			this.props.setIsOpenClosedLateralMenu(openState)
			this.setState({
				open: openState,
			})
		}

		/**
		 * Function to desactive menu from module planning
		 */
		startModulePlanningDesactiveMenu() {
			let matchUrl = this.props.match
			let url = matchUrl.url
			if (url.startsWith('/planning/releaseplan/')) {
				this.setState({
					open: false,
				})
			}
		}

		redirectToCustomUrl() {
			const search = localStorage.getItem('url')
			console.log('redirect To ', search)
			if (!IsNullOrEmpty(search)) {
				console.log(this.props)
				//window.location = `noticechanges/create${search}`
				localStorage.setItem('url', '')
				localStorage.setItem('noticeOfChangeFolio', search)
				this.props.history.push(`/noticechanges/create`)
			}
		}

		/**
		 * componentDidMount
		 *
		 */
		componentDidMount() {
			this.startModulePlanningDesactiveMenu()
			if (this.props.user.profile.name !== '') {
				this.props.loadLoggedUserProfile().then(() => {
					this.setState({ existCompany: true })
					this.validateCompaniesGranted()
					this.props.loadUserProfileRoles()

					/// Update notifications by user in view initial
					if (this.props.location.pathname === '/') {
						this.props.loadNotificationsByUser()
					}

					// Get active Privacy Notice
					if (this.props.selectedCompany !== null)
						this.props.getActivePrivacyNotice(this.props.selectedCompany.token, localStorage)

					/** Load official notice by accepted external users */
					if (IsCurrentNotActiveDirectoryUser(this.props.user)) {
						this.props.loadSupplier(this.props.user.profile.company_token)
						this.props.getTotalNotifications()
						this.props.loadOfficialNoticeForAccepted().then(() => {
							this.validateOfficialNoticeToAccepted()
						})
						this.props.loadEthicCodeFromResponseSupplier().then(() => {
							this.validateQuestionnairesToAnswer()
						})
						this.props.getAvailableSurveysBySupplier().then(() => {
							this.validateAvailableSurveyBySupplierLogged()
						})
						this.props.loadContactSupplierForUpdate().then(() => {
							this.validateContactsFromUpdateSupplier()
						})
						this.props.loadNotificationsOutsorcing(this.props.user.profile.company_code)

						/// Is loading supplier
						if (this.props.advanceOptionsSupplier === null) {
							this.props.loadAdvanceOptionsSupplier(this.props.user.profile.company_code)
						}
					}
					/** Load notices initial view */
					this.props.loadOfficialNoticeInitialView()
					//this.redirectToCustomUrl();
				})
			} else {
				//this.redirectToCustomUrl();
			}
		}

		/**
		 * componentDidUpdate
		 *
		 * @param {*} prevProps
		 * @param {*} prevState
		 */
		componentDidUpdate(prevProps, prevState) {
			if (prevProps.user.profile.name !== this.props.user.profile.name) {
				this.props.loadLoggedUserProfile().then(() => {
					this.validateCompaniesGranted()
					this.props.loadUserProfileRoles()
					this.props.loadNotificationsByUser()

					/**  Load official notice by accepted external users */
					if (IsCurrentNotActiveDirectoryUser(this.props.user)) {
						this.props.getTotalNotifications()
						this.props.loadOfficialNoticeForAccepted().then(() => {
							this.validateOfficialNoticeToAccepted()
						})
						/// Is loading supplier
						if (this.props.advanceOptionsSupplier === null) {
							this.props.loadAdvanceOptionsSupplier(this.props.user.profile.company_code)
						}
					}
					/** Load notices initial view */
					this.props.loadOfficialNoticeInitialView()
				})
			}
		}

		/**
		 * Execute the validation of the companies granted to the user
		 *
		 */
		validateCompaniesGranted() {
			if (this.props.grantedCompanies.length === 0) {
				this.props.history.push('/unauthorized')
			}
		}

		/**
		 * Execute the validation of the official notice to accepted by supplier
		 */
		validateOfficialNoticeToAccepted() {
			if (this.props.acceptedNotices && this.props.acceptedNotices.length !== 0) {
				this.props.history.push('/acceptedNotices')
			}
		}

		/**
		 * Vatilade if has any questionnaire to answer, then redirect to questionnaire view
		 *
		 */
		validateQuestionnairesToAnswer() {
			if (
				this.props.ethicQuestionnaires &&
				this.props.ethicQuestionnaires.length > 0 &&
				this.props.acceptedNotices &&
				this.props.acceptedNotices.length === 0
			) {
				this.props.history.push('/ethiccode/questionnaires')
			}
		}

		/**
		 * Vatilade if has any questionnaire to answer, then redirect to questionnaire view
		 *
		 */
		validateAvailableSurveyBySupplierLogged() {
			if (this.props.surveysAvailable && this.props.surveysAvailable.length > 0) {
				this.props.history.push('/survey/showSupplier')
			}
		}

		/**
		 * Validate if has list contact to update información from sales or billis by supplier
		 */
		validateContactsFromUpdateSupplier() {
			if (
				this.props.contactsListUpdate &&
				this.props.contactsListUpdate.length > 0 &&
				this.props.acceptedNotices &&
				this.props.acceptedNotices.length === 0 &&
				this.props.ethicQuestionnaires &&
				this.props.ethicQuestionnaires.length === 0
			) {
				this.props.history.push(`/updatecontacts`)
			}
		}

		/**
		 * Render the wrapped component with the anonymous layout
		 *
		 * @returns
		 */
		render() {
			const { classes } = this.props
			if (!this.state.existCompany) {
				return (
					<Grid
						container
						direction='row'
						justify='center'
						alignItems='center'
						style={{
							width: '100vw',
							height: '100vh',
							textAlign: 'center',
							alignContent: 'center',
						}}
					>
						<Grid item>
							<LoadingPage
								customColor={'rgba(0, 0, 0, 0.58)'}
								customMessage={<Translate id='common.checkingPermissions' />}
							/>
						</Grid>
					</Grid>
				)
			} else {
				return (
					<div className='authenticated-container'>
						<div id='application-bar'>
							<ApplicationBar
								{...this.props}
								onLogoutRequest={this.onLogoutRequest}
								open={this.state.open}
								onMenuIconClick={() => this.setLateralMenuOpenState(true)}
							/>
						</div>

						{IsCurrentActiveDirectoryUser(this.props.user) && (
							<LateralMenuInternalUser
								{...this.props}
								user={this.props.user}
								open={this.state.open}
								onClose={() => this.setLateralMenuOpenState(false)}
							/>
						)}

						{!IsCurrentActiveDirectoryUser(this.props.user) && (
							<LateralMenuExternalUser
								{...this.props}
								user={this.props.user}
								open={this.state.open}
								onClose={() => this.setLateralMenuOpenState(false)}
							/>
						)}

						{this.props.selectedCompany && (
							<main className={classes.content}>
								<div className={classes.toolbar} />
								<WrapperContainer />
								<FooterContainer
									checkPrivacyNotice={() =>
										checkPrivacyNotice(
											this.props.selectedCompany.get_company_identifier,
											localStorage,
											this.props.activeprivacyNotice
										)
									}
								/>
							</main>
						)}
					</div>
				)
			}
		}
	}
}

/**
 *
 * Defines the properties injecteded from the state to App props
 *
 * @param {object} state
 * @returns
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let grantedCompanies = state.profile.get('grantedCompanies')
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		oidc: state.oidc,
		user: user,
		grantedCompanies: grantedCompanies ? converListToArray(grantedCompanies) : [],
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingProfile: state.profile.get('isLoadingProfile'),
		isSaving: state.externalUsers.get('isSavingUser'),
		acceptedNotices: state.officialNotices.get('acceptedNotices')
			? state.officialNotices.get('acceptedNotices').toJS()
			: null,
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		advanceOptionsSupplier: state.suppliers.get('advanceOptionsSupplier')
			? state.suppliers.get('advanceOptionsSupplier').toJS()
			: null,
		ethicQuestionnaires: state.ethicCodes.get('ethicCodesListHome')
			? state.ethicCodes.get('ethicCodesListHome').toJS()
			: null,
		surveysAvailable: state.surveys.get('surveysAvailable')
			? state.surveys.get('surveysAvailable').toJS()
			: null,

		contactsListUpdate: state.contactSuppliers.get('contactsListUpdate')
			? state.contactSuppliers.get('contactsListUpdate').toJS()
			: null,
		totalNotifications: state.officialNotices.get('totalNotifications'),
		activeprivacyNotice: state.companies.get('activePrivacyNotice')
			? state.companies.get('activePrivacyNotice').toJS()
			: null,
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadLoggedUserProfile: () => {
			return dispatch(loadLoggedUserProfile())
		},
		loadUserProfileRoles: () => {
			return dispatch(loadUserProfileRoles())
		},
		loadOfficialNoticeForAccepted: () => {
			return dispatch(loadOfficialNoticeForAccepted())
		},
		loadOfficialNoticeInitialView: () => {
			return dispatch(loadOfficialNoticeInitialView())
		},
		loadNotificationsByUser: () => {
			return dispatch(loadNotificationsByUser())
		},
		loadAdvanceOptionsSupplier: (token) => {
			return dispatch(loadAdvanceOptionsSupplier(token))
		},
		loadEthicCodeFromResponseSupplier: () => {
			return dispatch(loadEthicCodeFromResponseSupplier())
		},
		loadContactSupplierForUpdate: () => {
			return dispatch(loadContactSupplierForUpdate())
		},
		loadSupplier: (token) => {
			return dispatch(loadSupplier(token))
		},
		setIsOpenClosedLateralMenu: (isOpen) => {
			return dispatch(setIsOpenClosedLateralMenu(isOpen))
		},
		getTotalNotifications: () => {
			return dispatch(getTotalNotifications())
		},
		loadNotificationsOutsorcing: (supplierCode) => {
			return dispatch(loadNotificationsOutsorcing(supplierCode))
		},
		getActivePrivacyNotice: (companyToken, localStorage) => {
			return dispatch(getActivePrivacyNotice(companyToken, localStorage))
		},
		getAvailableSurveysBySupplier: () => {
			return dispatch(getAvailableSurveysBySupplier())
		},
	}
}

const layoutStyles = (theme) => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
})
const withauthenticated = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(layoutStyles, { withTheme: true }),
	withauthenticatedLayoutHOC
)
export default withauthenticated
