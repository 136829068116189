import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
// Importaciones de Material-UI
import {
	Card,
	CardHeader,
	CardContent,
	Typography,
	Avatar,
	Button,
	Divider,
	Chip,
	Tooltip,
	IconButton,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PollIcon from '@material-ui/icons/Poll'
// import PercentIcon from '@material-ui/icons/Percent';
import LoadingPage from '../../../common/LoadingPage'
import EmptySections from './EmptySections'
import EmptyQuestion from './EmptyQuestion'
import { SurveyQuestioType } from '../../../../store/helpers/AppConstants'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import SingleChoiceOptions from './SingleChoiceOptions'
import MultipleChoiceOptions from './MultipleChoiceOptions'
import { COLOR_QUESTIONNAIRE_CHERRY, COLOR_STATUS_WARNING } from '../../../../store/helpers/StatusColorConstants'
import OpenQuestion from './OpenQuestion'
const ShowDesktopView = (props) => {
	const {
		classes,
		surveyTemplate,
		survey,
		isLoading,
		canBeEditData,
		supplierSurveyResponse,
		canBeAddedResponse,
		isDndEnabled,
	} = props
	//Functions
	const {
		onOpenCloseSurverSectionConfigPanel,
		onOpenCloseQuestionConfigPanel,
		onOpenCloseEditSurverSectionConfigPanel,
		onOpenCloseDeleteSurverSectionConfigPanel,
		onOpenCloseQuestionOptionConfigPanel,
		onOpenCloseEditQuestionConfigPanel,
		onOpenCloseDeleteQuestionConfigPanel,
		onOpenCloseDeleteOptionConfigPanel,
		onOpenCloseEditOptionConfigPanel,
		onOpenCloseDeleteSurveyTemplateConfigPanel,
		onOpenCloseEditSurveyTemplateConfigPanel,
		onUpdateOrderIndexCommon,
		onAddSurveyAnswerFromBackEnd,
	} = props

	if (isLoading) return <LoadingPage />

	const getTotalPercentajeChipColor = (percentage) => {
		if (percentage > 75) return '#4caf50' // Verde
		if (percentage > 50) return '#ffeb3b' // Amarillo
		return '#f44336' // Rojo
	}

	return (
		<div className={classes.container}>
			{/* Información General */}
			{surveyTemplate && (
				<Card className={classes.card}>
					<CardHeader
						avatar={
							<Avatar className={classes.avatar}>
								<PollIcon fontSize='small' />
							</Avatar>
						}
						title={<Typography variant='subtitle1'>{surveyTemplate.Title}</Typography>}
						subheader={
							<Typography variant='subtitle2' color='textSecondary'>
								{surveyTemplate.Description}
							</Typography>
						}
						action={
							<div style={{ display: 'flex', gap: '1px', marginTop: '16px' }}>
								{canBeEditData && (
									<Chip
										// icon={<AddIcon />}
										label={`${surveyTemplate.Percentage}%`}
										style={{
											backgroundColor: getTotalPercentajeChipColor(surveyTemplate.Percentage),
											color: '#fff',
											fontWeight: 'bold',
										}}
										size='medium'
									/>
								)}
								{canBeEditData && (
									<Tooltip title='Editar encuesta'>
										<Button
											startIcon={<EditIcon />}
											className={classes.editButton}
											onClick={() => onOpenCloseEditSurveyTemplateConfigPanel()}
										>
											<EditIcon fontSize='small' />
											{/* Editar */}
										</Button>
									</Tooltip>
								)}
								{canBeEditData && (
									<Tooltip title='Eliminar encuesta'>
										<Button
											startIcon={<DeleteIcon fontSize='small' />}
											color='secondary'
											className={classes.deleteButton}
											onClick={() => onOpenCloseDeleteSurveyTemplateConfigPanel()}
										>
											<DeleteIcon fontSize='small' />
											{/* Eliminar */}
										</Button>
									</Tooltip>
								)}

								{canBeEditData && (
									<Tooltip title='Agregar sección'>
										<Button
											className={classes.addButton}
											onClick={onOpenCloseSurverSectionConfigPanel}
										>
											<AddIcon fontSize='small' />
										</Button>
									</Tooltip>
								)}

								{/* </ButtonGroup> */}
							</div>
						}
						style={{
							padding: 5,
						}}
					/>
				</Card>
			)}

			{/* Secciones */}

			<EmptySections
				//bool props
				canBeEditData={canBeEditData}
				//objects
				surveyTemplate={surveyTemplate}
				//functions
				onOpenCloseSurverSectionConfigPanel={onOpenCloseSurverSectionConfigPanel}
			/>
			<DragDropContext onDragEnd={isDndEnabled ? (event) => onUpdateOrderIndexCommon(event) : undefined}>
				<Droppable droppableId='sections' type='sections'>
					{(provided) => (
						<div
							// className='characters'
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{surveyTemplate &&
								surveyTemplate.Sections.map((section, sectionIndex) => (
									<Draggable key={section.Id} draggableId={section.Id} index={sectionIndex}>
										{(provided) => (
											<div
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												ref={provided.innerRef}
											>
												<Card className={classes.sectionCard} key={section.OrderIndex}>
													<CardHeader
														title={
															<Typography
																variant='subtitle1'
																style={{
																	color: section.TitleColor,
																}}
															>
																{`${section.RomanOrderIndex}. ${section.Title}`}
															</Typography>
														}
														subheader={
															<Typography
																variant='subtitle2'
																color='textSecondary'
																style={{
																	color: section.TitleColor,
																}}
															>
																{section.Description}
															</Typography>
														}
														style={{
															backgroundColor: section.BackgroundColor,
															color: section.TitleColor,
															padding: 5,
														}}
														action={
															<div
																style={{
																	display: 'flex',
																	gap: '1px',
																	marginTop: '16px',
																}}
															>
																{canBeEditData && (
																	<Chip
																		// icon={<AddIcon />}
																		label={`${section.Percentage}%`}
																		color='primary'
																		style={{
																			// backgroundColor: getTotalPercentajeChipColor(section.Percentage),
																			color: '#fff',
																			fontWeight: 'bold',
																		}}
																		size='medium'
																	/>
																)}
																{canBeEditData && (
																	<Tooltip title='Agregar pregunta'>
																		<Button
																			className={classes.addButton}
																			onClick={() =>
																				onOpenCloseQuestionConfigPanel(section)
																			}
																		>
																			<AddIcon fontSize='small' />
																		</Button>
																	</Tooltip>
																)}
																{canBeEditData && (
																	<Tooltip title='Editar sección'>
																		<Button
																			startIcon={<EditIcon />}
																			className={classes.editButton}
																			onClick={() =>
																				onOpenCloseEditSurverSectionConfigPanel(
																					section
																				)
																			}
																		>
																			<EditIcon fontSize='small' />
																			{/* Editar */}
																		</Button>
																	</Tooltip>
																)}
																{canBeEditData && (
																	<Tooltip title='Eliminar sección'>
																		<Button
																			startIcon={<DeleteIcon />}
																			color='secondary'
																			className={classes.deleteButton}
																			onClick={() =>
																				onOpenCloseDeleteSurverSectionConfigPanel(
																					section
																				)
																			}
																		>
																			<DeleteIcon fontSize='small' />
																			{/* Eliminar */}
																		</Button>
																	</Tooltip>
																)}
																{/* </ButtonGroup> */}
															</div>
														}
													/>
													<Divider />
													<Questions
														canBeEditData={canBeEditData}
														canBeAddedResponse={canBeAddedResponse}
														//objects
														section={section}
														survey={survey}
														classes={classes}
														supplierSurveyResponse={supplierSurveyResponse}
														//functions
														onOpenCloseQuestionOptionConfigPanel={
															onOpenCloseQuestionOptionConfigPanel
														}
														onAddSurveyAnswerFromBackEnd={onAddSurveyAnswerFromBackEnd}
														onOpenCloseEditQuestionConfigPanel={
															onOpenCloseEditQuestionConfigPanel
														}
														onOpenCloseDeleteQuestionConfigPanel={
															onOpenCloseDeleteQuestionConfigPanel
														}
														onOpenCloseDeleteOptionConfigPanel={
															onOpenCloseDeleteOptionConfigPanel
														}
														onOpenCloseEditOptionConfigPanel={
															onOpenCloseEditOptionConfigPanel
														}
														onOpenCloseQuestionConfigPanel={onOpenCloseQuestionConfigPanel}
													/>
												</Card>
											</div>
										)}
									</Draggable>
								))}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	)
}

const Questions = (props) => {
	const { section, survey, classes, canBeEditData, supplierSurveyResponse, canBeAddedResponse } = props
	const {
		onOpenCloseQuestionOptionConfigPanel,
		onOpenCloseEditQuestionConfigPanel,
		onOpenCloseDeleteQuestionConfigPanel,
		onOpenCloseDeleteOptionConfigPanel,
		onOpenCloseQuestionConfigPanel,
		onOpenCloseEditOptionConfigPanel,
		onAddSurveyAnswerFromBackEnd,
	} = props

	return (
		<>
			<CardContent style={{ padding: 0 }}>
				<EmptyQuestion
					//Array section
					section={section}
					//Functions section
					onOpenCloseQuestionConfigPanel={onOpenCloseQuestionConfigPanel}
				/>

				{/* <DragDropContext onDragEnd={(event) => console.log('event')}> */}
				<Droppable droppableId={section.Id} type='questions'>
					{(provided) => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{section.Questions.map((question, questionIndex) => (
								<Draggable
									key={question.Id}
									sectionId={section.Id}
									draggableId={question.Id}
									index={questionIndex}
								>
									{(provided) => (
										<div
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											ref={provided.innerRef}
										>
											<Card
												key={questionIndex}
												style={{
													marginBottom: '2px',
													paddingLeft: '5px',
													paddingRight: '5px',
													border: '1px solid #ddd',
												}}
											>
												<CardHeader
													title={
														<Typography
															variant='subtitle1'
															className={classes.questionTitle}
														>
															{`${section.OrderIndex}.${questionIndex + 1} ${
																question.Title
															}`}
														</Typography>
													}
													subheader={
														<>
															{question.Recommendation && (
																<Typography variant='caption'>
																	{
																		<span
																			style={{
																				color: COLOR_QUESTIONNAIRE_CHERRY,
																			}}
																		>
																			{'Recomendación'}
																		</span>
																	}
																	: {question.Recommendation}{' '}
																</Typography>
															)}
															{question.Warning && (
																<Typography variant='caption'>
																	{
																		<span
																			style={{
																				color: COLOR_STATUS_WARNING,
																			}}
																		>
																			{'Warning'}
																		</span>
																	}
																	: {question.Warning}{' '}
																</Typography>
															)}
														</>
													}
													action={
														<div
															style={{
																display: 'flex',
																gap: '4px',
															}}
														>
															{canBeEditData && (
																<Chip
																	// icon={<AddIcon />}
																	label={`${question.Percentage}%`}
																	color='primary'
																	style={{
																		color: '#fff',
																		fontWeight: 'bold',
																	}}
																	size='medium'
																/>
															)}
															{canBeEditData &&
																question.Type !== SurveyQuestioType.OPEN_ENDED && (
																	<Tooltip title='Agregar opción pregunta'>
																		<IconButton
																			color='primary'
																			size='small'
																			onClick={() =>
																				onOpenCloseQuestionOptionConfigPanel(
																					question
																				)
																			}
																		>
																			<AddIcon fontSize='small' />
																		</IconButton>
																	</Tooltip>
																)}
															{canBeEditData && (
																<Tooltip title='Editar pregunta'>
																	<IconButton
																		color='secondary'
																		size='small'
																		onClick={() =>
																			onOpenCloseEditQuestionConfigPanel(question)
																		}
																	>
																		<EditIcon fontSize='small' />
																	</IconButton>
																</Tooltip>
															)}
															{canBeEditData && (
																<Tooltip title='Eliminar pregunta'>
																	<IconButton
																		color='error'
																		size='small'
																		onClick={() =>
																			onOpenCloseDeleteQuestionConfigPanel(
																				question
																			)
																		}
																	>
																		<DeleteIcon fontSize='small' />
																	</IconButton>
																</Tooltip>
															)}
														</div>
													}
													
												/>

												{question.Type === SurveyQuestioType.OPEN_ENDED && (
													<OpenQuestion
														onAddSurveyAnswerFromBackEnd={onAddSurveyAnswerFromBackEnd}
														//objects
														survey={survey}
														question={question}
														supplierSurveyResponse={supplierSurveyResponse}
														canBeAddedResponse={canBeAddedResponse}
													/>
												)}
												{question.Type === SurveyQuestioType.SINGLE_CHOICE && (
													<SingleChoiceOptions
														//bool
														canBeEditData={canBeEditData}
														canBeAddedResponse={canBeAddedResponse}
														//object
														question={question}
														survey={survey}
														supplierSurveyResponse={supplierSurveyResponse}
														//fucntions
														onOpenCloseDeleteOptionConfigPanel={
															onOpenCloseDeleteOptionConfigPanel
														}
														onOpenCloseEditOptionConfigPanel={
															onOpenCloseEditOptionConfigPanel
														}
														onAddSurveyAnswerFromBackEnd={onAddSurveyAnswerFromBackEnd}
													/>
												)}
												{question.Type === SurveyQuestioType.MULTIPLE_CHOICE && (
													<MultipleChoiceOptions
														//bool
														canBeEditData={canBeEditData}
														canBeAddedResponse={canBeAddedResponse}
														//object
														question={question}
														survey={survey}
														supplierSurveyResponse={supplierSurveyResponse}
														//fucntions
														onOpenCloseDeleteOptionConfigPanel={
															onOpenCloseDeleteOptionConfigPanel
														}
														onOpenCloseEditOptionConfigPanel={
															onOpenCloseEditOptionConfigPanel
														}
														onAddSurveyAnswerFromBackEnd={onAddSurveyAnswerFromBackEnd}
													/>
												)}
											</Card>
										</div>
									)}
								</Draggable>
							))}
						</div>
					)}
				</Droppable>
				{/* </DragDropContext> */}
			</CardContent>
		</>
	)
}

const styles = (theme) => ({
	container: {
		padding: '5px',
		backgroundColor: '#f9f9f9',
	},
	card: {
		marginBottom: '5px',
		borderRadius: '8px',
		boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
		padding: 0,
		'&:last-child': {
			paddingBottom: 0,
		},
	},
	avatar: {
		backgroundColor: theme.palette.primary.main,
	},
	sectionCard: {
		marginBottom: '5px',
		borderRadius: '8px',
		boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
	},
	questionBlock: {
		marginTop: '16px',
	},
	questionTitle: {
		fontWeight: 'bold',
		// marginBottom: '8px',
	},
	addOptionContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		marginBottom: '16px',
	},
	addOptionButton: {
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
		textTransform: 'none',
		fontSize: '0.85rem',
		fontWeight: 'bold',
		'&:hover': {
			backgroundColor: theme.palette.primary.light,
			borderColor: theme.palette.primary.dark,
		},
	},
	emptyStateContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '32px',
		backgroundColor: '#fff',
		borderRadius: '8px',
		boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
		marginTop: '16px',
	},
	addButton: {
		backgroundColor: '#1976d2',
		color: '#fff',
		border: '1px solid #1976d2',
		borderRadius: '4px',
	},
	editButton: {
		backgroundColor: '#f0f0f0',
		color: '#000',
		border: '1px solid #ccc',
		borderRadius: '4px',
	},
	deleteButton: {
		backgroundColor: '#d32f2f',
		color: '#fff',
		border: '1px solid #d32f2f',
		borderRadius: '4px',
	},
})

export default withStyles(styles)(ShowDesktopView)
