export const ROLE_COMPANYADMINISTRATOR = 'CompanyAdministrator'
export const ROLE_SIMULATOR_SUPPLIER = 'RoleSimulatorSupplier'
export const ROLE_PLANNING_RELEASE_CALENDAR = 'RolePlanningReleaseCalendar'

/* Roles in app for supplier development */
export const ROLE_SUPPLIERDEVELOPMENT = 'SupplierDevelopmentAdministrator'
export const ROLE_SUPPLIERDEVELOPMENTENGINEER = 'SupplierDevelopmentEngineer'

/* Roles in app for quality */
export const ROLE_QUALITYMANAGER = 'QualityManager'
export const ROLE_QUALITYENGINEER = 'QualityEngineer'
export const ROLE_QUALITYREWORKAUTHORIZATOR = 'QualityReworkAuthorizator'
export const ROLE_QUALITYMATERIALES = 'QualityMaterial'
export const ROLE_QUALITYRMIEVALUATOR = 'QualityRMIEvaluator'

/* Roles in app for logistic */
export const ROLE_LOGISTICPLANNER = 'LogisticPlanner'
export const ROLE_LOGISTICREWORKAUTHORIZATOR = 'LogisticReworkAuthorizator'

/* Roles for create quote and sample*/
export const ROLE_LOGISTICMANAGER = 'LogisticManager'

/* Roles for warehouse management */
export const ROLE_WAREHOUSEMAN = 'warehouseman'
export const ROLE_WAREHOUSERAWMATERIAL = 'warehouseRawMaterial'

/* Roles for create guarantees */
export const ROLE_RELIABILITYAREA = 'ReliabilityArea'
export const ROLE_ACCOUNTPAYABLE_ADMINISTRATION = 'AccountsPayableAdministration'
export const ROLE_ACCOUNTRECEIVABLE_ADMINISTRATION = 'AccountsReceivableAdministration'

/* Roles for create shipping orders */
export const ROLE_COORDINATEINTERNATIONALTRADE = 'CoordinateInternationalTrade'

/* Roles for Access Control Contractor  */
export const ROLE_ACCESSCONTROL_INDUSTRIALSECURITY = 'AccessControlIndustrialSecurity'

/* Role for medical services crew */
export const ROLE_ACCESSCONTROL_MEDICALSERVICE = 'AccessControlMedicalService'

/* Roles for create sustantability questionnaire */
export const ROLE_SUSTANTABILITYQUESTIONNAIREGENERATOR = 'SustantabilityQuestionaire'

/* Roles for responsible indirect rating */
export const ROLE_INDIRECT_RATING_RESPONSIBLE = 'IndirectRatingResponsible'

/* Roles for constracts suppliers */
export const ROLE_CONTRACTS_SUPPLIERS = 'AreaContractsSupplier'
export const ROLE_CONTRACTS_SUPPLIERS_APPLICANT = 'requestContractsSupplier'
export const ROLE_CONTRACTS_VIEWER_SUPPLIERS = 'AreaContractsViewerSupplier'
export const ROLE_DOCUMENTS_ENV_SUPPLIERS = 'AreaDocumentsEnvSupplier'
export const ROLE_PROSPECTS_SUPPLIERS = 'AreaProspectsSupplier'
export const ROLE_PROSPECTS_CREATOR_SUPPLIERS = 'AreaProspectsSupplierCreator'
export const ROLE_RESPONSIBLE_SUPPLIER_DOSSIER = 'ResponsibleSupplierDossier'
/* Roles for bidding module */
export const ROLE_BIDDING_MODULE_ADMINISTRATOR = 'BiddingAdministrator'
export const ROLE_BIDDING_RESPONSIBLE = 'BiddingResponsible'

/* Roles for maquila module */
export const ROLE_MAQUILAORDER_MOLDE = 'MoldersAreas'
export const ROLE_MAQUILAORDER_ADMINISTRATION = 'AdministrationAreas'

/* Roles for Consignment Note module */
export const ROLE_CONSIGNMENTNOTE_RESPONSIBLE = 'ConsignmentNoteResponsible'

/* Roles for administrator phone directory imbera */
export const ROLE_PHONE_DIRECTORY_ADMINISTRATOR = 'PhoneDirectoryAdministrator'

/* Roles for CO2 Control module */
export const ROLE_CO2_COTROL_SERVICE_EDITOR = 'CO2ServiceEditor'
export const ROLE_CO2_CONTROL_REQUEST_BLOCK = 'CO2ControlRequestBlock'
/* Roles for Expedient viewer */
export const ROLE_EXPEDIENT_VIEWER = 'ExpedientViewer'
/*Roles for Alta Adminitrator */
export const ROLE_PROSPECT_ADMINISTRATOR = 'ProspectAdminitrator'

export const ROLE_RESPONSIBLE_SPAREPARTS = 'ResponsibleSparePart'

/* Roles in app for supplier  */
export const ROLE_SUPPLIERADMINISTRATOR = 'SupplierAdministrator'
export const ROLE_SUPPLIER_SALESENGINEER = 'SupplierSalesEnginner'
export const ROLE_SUPPLIER_QUALITYNGINEER = 'SupplierQualityEnginner'
export const ROLE_SUPPLIER_BILLINGENGINEER = 'SupplierBillingEngineer'
export const ROLE_SUPPLIER_ENGINEERINGENGINEER = 'SupplierEngineeringEngineer'
export const ROLE_SUPPLIER_IMPORTS = 'SupplierImports'
export const ROLE_SUPPLIER_FREIGHT_IMPORTS = 'SupplierFreightImports'
export const ROLE_SUPPLIER_LOGISTICPLANNER = 'SupplierLogisticPlanner'
export const ROLE_ACCESSCONTROL_ADMINISTRATOR_SUPPLIER = 'AccessControlAdministratorSupplier'
export const ROLE_SUPPLIER_BIDDINGS = 'SupplierBiddings'
export const ROLE_SUPPLIER_SUSTAINABILITY = 'SupplierSustainability'
export const ROLE_INDIRECT_RATING_SUPPLIER = 'IndirectRatingSupplier'
export const ROLE_SUPPLIER_FILE_MANAGER = 'SupplierFileManager'
export const ROLE_SUPPLIER_CONSIGNMENTNOTE_RESPONSIBLE = 'SupplierConsignmentNoteResponsible'
export const ROLE_TRANSPORT_TENDER_ADMINISTRATOR = 'TransportTenderAdminitrator'
export const ROLE_SUPPLIER_DEVELOPMENT_ADMINISTRATOR_SAP_TARIFF = 'SupplierDevelopmentAdministratorSAPTariff'
export const ROLE_FACTORING_AFFILIATION_ADMINISTRATOR = 'FactoringAffiliationAdministrator'
export const ROLE_FACTORING_AFFILIATION_CREATOR = 'FactoringAffiliateCreator'
export const ROLE_CREATE_LOAD_ALERTS = 'CreateLoadAlerts'
export const SEE_LOAD_ALERTS = 'LoadAlert'
export const FOREIGN_TRADE_VIEWER = 'ForeignTradeViewer'
export const FOREIGN_TRADE_VIEWER_EXTERNAL = 'ForeignTradeViewerExternal'
export const PLANER_LOAD_ALERT = 'PlannerLoadAlert'
export const INTERNATIONAL_LOAD_ALERT = 'InternationalLoadAlert'
export const NATIONAL_LOAD_ALERT = 'NationalLoadAlert'
export const ROLE_SUPPLIER_DEVELOPERS_EVALUATION_QUESTIONNAIRE_FILE = 'SupplierDevelopersEvaluationQuestionnaireFile'

/*Transporte distribución  */
export const TRANSPORT_GILDER = 'TransportGlider'
export const TRANSPORTATION_ASSIGMENT = 'TransportationAssignment'

/**
 * Function to determine is user in role
 * @param {*} role
 * @param {*} roles
 */
export function IsUserInRole(roleName, roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (roles.find((role) => role.name === roleName)) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Detemrine is is user role warehouse from Warehouse Material delivery
 * @param {*} roles
 */
export function rolesToShowWarehouseRawMaterial(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_WAREHOUSERAWMATERIAL) ||
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_QUALITYENGINEER) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENT) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENTENGINEER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Action determine can be supplier
 * @param {*} roles
 */
export function canBeLonginSupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SIMULATOR_SUPPLIER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to get list supplier
 * @param {} roles
 */
export function CaBeShowListSuppliers(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENT)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function CaBeShowListSuppliersEngineer(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENTENGINEER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to get module expedient supplier
 * @param {} roles
 */
export function canBeModuleExpeditByCompany(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_RESPONSIBLE_SUPPLIER_DOSSIER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function canBeModuleLoadAlert(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_CREATE_LOAD_ALERTS) ||
			roles.find((role) => role.name === SEE_LOAD_ALERTS) ||
			roles.find((role) => role.name === PLANER_LOAD_ALERT)
		) {
			return true
		} else {
			return false
		}
	}
}


export function canBeAdminsitratorTransportTender(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (roles.find((role) => role.name === ROLE_TRANSPORT_TENDER_ADMINISTRATOR)) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function canBeAssignedSupplierInLoadAlert(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (roles.find((role) => role.name === SEE_LOAD_ALERTS)) {
			return true
		} else {
			return false
		}
	}
}

export function canCreateLoadAlert(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (roles.find((role) => role.name === ROLE_CREATE_LOAD_ALERTS)) {
			return true
		} else {
			return false
		}
	}
}

export function canSeeModuleForeignTrade(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === FOREIGN_TRADE_VIEWER) ||
			roles.find((role) => role.name === FOREIGN_TRADE_VIEWER_EXTERNAL)
		) {
			return true
		} else {
			return false
		}
	}
}

/**
 * Method to clock supplier
 * @param {} roles
 */
export function CaBeBlockSupplier(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_LOGISTICMANAGER) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENT)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Action determine can be supplier
 * @param {*} roles
 */
export function canBeModuleContractsSupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_CONTRACTS_SUPPLIERS)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function canBeModuleContractsView(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (roles.find((role) => role.name === ROLE_CONTRACTS_VIEWER_SUPPLIERS)) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function canBeModuleContractsSupplierApplicant(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_CONTRACTS_VIEWER_SUPPLIERS) ||
			roles.find((role) => role.name === ROLE_CONTRACTS_SUPPLIERS_APPLICANT)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Action determine can be supplier
 * @param {*} roles
 */
export function canBeModuleDocumentsEnvSupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_DOCUMENTS_ENV_SUPPLIERS)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Action determine can be supplier
 * @param {*} roles
 */
export function canBeModuleBidding(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_BIDDING_RESPONSIBLE) ||
			roles.find((role) => role.name === ROLE_BIDDING_MODULE_ADMINISTRATOR)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function canBeModuleProspectsSupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_PROSPECTS_CREATOR_SUPPLIERS)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/// Module DEVELOMPMENT

/**
 * Can be show modulde development
 * @param {*} roles
 */
export function caBeModuleDevelopment(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENT) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENTENGINEER) ||
			roles.find((role) => role.name === ROLE_QUALITYMANAGER) ||
			roles.find((role) => role.name === ROLE_LOGISTICPLANNER) ||
			roles.find((role) => role.name === ROLE_LOGISTICMANAGER) ||
			roles.find((role) => role.name === ROLE_QUALITYRMIEVALUATOR)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function caBeModuleSparePart(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_RESPONSIBLE_SPAREPARTS)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function caBeModuleProfileSupplier(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENT) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENTENGINEER) ||
			roles.find((role) => role.name === ROLE_QUALITYMANAGER) ||
			roles.find((role) => role.name === ROLE_LOGISTICPLANNER) ||
			roles.find((role) => role.name === ROLE_LOGISTICMANAGER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to can be active quote to supplier
 * @param {*} roles
 */
export function caBeActiveQuote(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_LOGISTICMANAGER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to cancel documents in module from development for quote
 * @param {*} roles
 */
export function canBeValidateModuleDevelopment(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENT) ||
			roles.find((role) => role.name === ROLE_LOGISTICMANAGER) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENTENGINEER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Action to close sac development
 * @param {} roles
 */
export function canBeCloseSacDevelopment(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENT)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to cancel documents in module from development for quote
 * @param {*} roles
 */
export function CaBeCancelQuote(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_LOGISTICMANAGER) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENT) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENTENGINEER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to cancel documents in module from development for sample request
 * @param {*} roles
 */
export function canSampleRequestBeCanceled(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENT) ||
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_LOGISTICMANAGER) ||
			roles.find((role) => role.name === ROLE_QUALITYRMIEVALUATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENTENGINEER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Action to can be close sample request
 * @param {*} roles
 */
export function canBeCloseSampleRequest(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_QUALITYRMIEVALUATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENT)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Action to validad can be add arrave date samplete
 * @param {*} roles
 */
export function canBeAddArriveSampleRequest(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_QUALITYRMIEVALUATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENTENGINEER) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENT)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to cancel documents in module from development for CorrectiveActions
 * @param {*} roles
 */
export function CaBeCancelCorrectiveActions(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENT)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/// MODULE QUALITY
/**
 * Determine if a user can create a raw material non conformity
 * @param {*} roles
 */
export function canUserCreateReportModuleQuality(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_QUALITYENGINEER) ||
			roles.find((role) => role.name === ROLE_QUALITYMATERIALES) ||
			roles.find((role) => role.name === ROLE_QUALITYMANAGER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Determine can by rework quality
 * @param {*} roles
 */
export function canUserCreateRework(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_LOGISTICPLANNER) ||
			roles.find((role) => role.name === ROLE_QUALITYENGINEER) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENTENGINEER) ||
			roles.find((role) => role.name === ROLE_QUALITYMANAGER) ||
			roles.find((role) => role.name === ROLE_LOGISTICMANAGER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 *  Determine can by quality
 * @param {*} roles
 */
export function canUserCreateGeneratedUpdateForm(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_LOGISTICPLANNER) ||
			roles.find((role) => role.name === ROLE_QUALITYENGINEER) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENTENGINEER) ||
			roles.find((role) => role.name === ROLE_QUALITYMANAGER) ||
			roles.find((role) => role.name === ROLE_LOGISTICMANAGER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Determine can be validate module quality
 */
export function canUserValideModuleQuality(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_QUALITYENGINEER) ||
			roles.find((role) => role.name === ROLE_QUALITYMANAGER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Determine can be folios from caliaty manualment
 * @param {*} roles
 */
export function canUserCloseModuleQuality(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_QUALITYMANAGER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Determine if a user can create a raw material non conformity
 * @param {*} roles
 */
export function canUserRetunrDevolutionFromMaterial(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_QUALITYENGINEER) ||
			roles.find((role) => role.name === ROLE_QUALITYMATERIALES)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Determine if a user can create action the rework
 * @param {*} roles
 */
export function canUserCreateDevolutionFromRework(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_QUALITYENGINEER) ||
			roles.find((role) => role.name === ROLE_QUALITYMATERIALES)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to create warehouse raw material
 * @param {*} roles
 */
export function canBeCreateWarehouseRawMaterial(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_WAREHOUSEMAN) ||
			roles.find((role) => role.name === ROLE_WAREHOUSERAWMATERIAL)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to validate the roles permission from shipping order
 * @param {*} roles
 */
export function canBeModuleShippingOrder(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_COORDINATEINTERNATIONALTRADE)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to can be information from release plan by warehouse
 * @param {*} roles
 */
export function canBeOperationAdministratorWarehouse(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (roles.find((role) => role.name === ROLE_WAREHOUSEMAN)) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function canBeOperationAdministratorReleaseCalendar(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (roles.find((role) => role.name === ROLE_PLANNING_RELEASE_CALENDAR)) {
			return true
		} else {
			return false
		}
	}
}

/**
 * Method to can be operation module delivery order in warehouse
 * @param {*} roles
 */
export function canBeOperationDeliveryWarehouse(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_WAREHOUSEMAN) ||
			roles.find((role) => role.name === ROLE_WAREHOUSERAWMATERIAL)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Determine if a user can validate quality
 * @param {*} roles
 */
export function canValidateDeliveryOrderQuality(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_QUALITYMATERIALES) ||
			roles.find((role) => role.name === ROLE_QUALITYENGINEER) ||
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Can update the release plan manually
 * @param {*} roles
 */
export function canUpdateTheReleaseForPTM(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR)) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Can be add materials in release
 * @param {*} roles
 */
export function canUpdateAddMaterialsInRelease(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_LOGISTICPLANNER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Can be close register in portal from accesso control with 2 days
 * register obsolets registers less than two days before today
 * This method is used in Security Course module to validate creation and delete.
 * @param {*} roles
 */
export function canCloseRegistersAccessControl(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_ACCESSCONTROL_INDUSTRIALSECURITY)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Can validate medical checks for contractors users
 * @param {*} roles
 */
export function canValidateMedicalCheck(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (roles.find((role) => role.name === ROLE_ACCESSCONTROL_MEDICALSERVICE)) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to validate the roles permission from sustantability questionnaire
 * @param {*} roles
 */
export function canBeModuleSustantabilityQuestionnaire(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUSTANTABILITYQUESTIONNAIREGENERATOR)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to validate the roles permission from rating indirects
 * @param {*} roles
 */
export function canBeModuleRatingIndirects(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_INDIRECT_RATING_RESPONSIBLE)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function canUpdateMaquilaOrder(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENTENGINEER) ||
			roles.find((role) => role.name === ROLE_LOGISTICPLANNER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function canShowMaquilaOrder(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIERDEVELOPMENTENGINEER) ||
			roles.find((role) => role.name === ROLE_QUALITYENGINEER) ||
			roles.find((role) => role.name === ROLE_WAREHOUSEMAN) ||
			roles.find((role) => role.name === ROLE_WAREHOUSERAWMATERIAL) ||
			roles.find((role) => role.name === ROLE_MAQUILAORDER_MOLDE) ||
			roles.find((role) => role.name === ROLE_MAQUILAORDER_ADMINISTRATION) ||
			roles.find((role) => role.name === ROLE_LOGISTICPLANNER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to validate the roles permission from Consignment Note
 * @param {*} roles
 */
export function canBeModuleConsignmentNote(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_CONSIGNMENTNOTE_RESPONSIBLE)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

///// ROLES SUPPLIER EXTERNAL //////
/**
 * ROLES SUPPLIER
 * Method to validate the roles permission from Supplier
 * @param {*} roles
 */
export function canBeModulePlaneationSupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_SUPPLIERADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIER_LOGISTICPLANNER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function canBeAdminsitratorSupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (roles.find((role) => role.name === ROLE_SUPPLIERADMINISTRATOR)) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to validate the roles permission from Supplier shipping order
 * @param {*} roles
 */
export function canBeModuleShippingOrderSupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_SUPPLIERADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIER_IMPORTS)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to validate the roles permission from Supplier shipping order
 * @param {*} roles
 */
export function canBeModuleFreightSupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_SUPPLIERADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIER_FREIGHT_IMPORTS)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Action to can be colsult invoices
 * @param {*} roles
 */
export function canBeConsultInvocesSupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_SUPPLIERADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIER_BILLINGENGINEER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to can be responder module from development
 * @param {*} roles
 */
export function canBeRespondeModuleDevelopmentSupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_SUPPLIERADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIER_ENGINEERINGENGINEER) ||
			roles.find((role) => role.name === ROLE_SUPPLIER_SALESENGINEER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to can be responder module from quality
 * @param {*} roles
 */
export function canBeRespondeModuleQualitySupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_SUPPLIERADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIER_QUALITYNGINEER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function canBeModuleExpedientSupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_SUPPLIERADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIER_FILE_MANAGER)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function canBeModuleExpedientViewer(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (roles.find((role) => role.name === ROLE_EXPEDIENT_VIEWER)) {
			return true
		} else {
			return false
		}
	}
	return false
}
//ROLE_RESPONSIBLE_SUPPLIER_DOSSIER
export function canBeModuleResposibleExpedient(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (roles.find((role) => role.name === ROLE_RESPONSIBLE_SUPPLIER_DOSSIER)) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function canBeModuleProspectAdminitrator(roles) {
	if (roles === undefined) return false
	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_PROSPECT_ADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_COMPANYADMINISTRATOR)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Action to can be supplier module binding
 * @param {*} roles
 */
export function canBeModuleBiddingSupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_SUPPLIERADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIER_BIDDINGS)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Action to can be supplier module sustainability
 * @param {*} roles
 */
export function canBeModuleSustainabilitySupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_SUPPLIERADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_SUPPLIER_SUSTAINABILITY)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Action to can be supplier module rating
 * @param {*} roles
 */
export function canBeModuleIndirectRatingSupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_INDIRECT_RATING_SUPPLIER) ||
			roles.find((role) => role.name === ROLE_SUPPLIERADMINISTRATOR)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Method to validate the roles permission from Consignment Note
 * @param {*} roles
 */
export function canBeModuleConsignmentNoteSupplier(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_SUPPLIER_CONSIGNMENTNOTE_RESPONSIBLE) ||
			roles.find((role) => role.name === ROLE_SUPPLIERADMINISTRATOR)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

export function canBeAccessModuleFactoringAffiliation(roles) {
	if (roles === undefined) return false

	if (roles.length !== 0) {
		if (
			roles.find((role) => role.name === ROLE_FACTORING_AFFILIATION_ADMINISTRATOR) ||
			roles.find((role) => role.name === ROLE_FACTORING_AFFILIATION_CREATOR)
		) {
			return true
		} else {
			return false
		}
	}
	return false
}

/**
 * Function to get description the roles
 * @param {*} name
 */
export function getDescriptionRole(name) {
	let descriptionRole = null
	switch (name) {
		/** Roles internal */
		case ROLE_COMPANYADMINISTRATOR: {
			descriptionRole = 'companyAdministrator'
			break
		}
		case ROLE_SIMULATOR_SUPPLIER: {
			descriptionRole = 'roleSimulatorSupplier'
			break
		}
		case ROLE_PLANNING_RELEASE_CALENDAR: {
			descriptionRole = 'rolePlanningReleaseCalendar'
			break
		}
		case ROLE_SUPPLIERDEVELOPMENT: {
			descriptionRole = 'supplierDevelopmentAdministrator'
			break
		}
		case ROLE_SUPPLIERDEVELOPMENTENGINEER: {
			descriptionRole = 'supplierDevelopmentEngineer'
			break
		}
		case ROLE_QUALITYMANAGER: {
			descriptionRole = 'qualityManager'
			break
		}
		case ROLE_QUALITYENGINEER: {
			descriptionRole = 'qualityEngineer'
			break
		}
		case ROLE_QUALITYREWORKAUTHORIZATOR: {
			descriptionRole = 'qualityReworkAuthorizator'
			break
		}
		case ROLE_QUALITYMATERIALES: {
			descriptionRole = 'qualityMaterial'
			break
		}
		case ROLE_QUALITYRMIEVALUATOR: {
			descriptionRole = 'qualityRMIEvaluator'
			break
		}
		case ROLE_LOGISTICPLANNER: {
			descriptionRole = 'logisticPlanner'
			break
		}
		case ROLE_LOGISTICREWORKAUTHORIZATOR: {
			descriptionRole = 'logisticReworkAuthorizator'
			break
		}
		case ROLE_LOGISTICMANAGER: {
			descriptionRole = 'logisticManager'
			break
		}
		case ROLE_WAREHOUSEMAN: {
			descriptionRole = 'warehouseman'
			break
		}
		case ROLE_WAREHOUSERAWMATERIAL: {
			descriptionRole = 'warehouseRawMaterial'
			break
		}
		case ROLE_RELIABILITYAREA: {
			descriptionRole = 'reliabilityArea'
			break
		}
		case ROLE_ACCOUNTPAYABLE_ADMINISTRATION: {
			descriptionRole = 'accountsPayableAdministration'
			break
		}
		case ROLE_ACCOUNTRECEIVABLE_ADMINISTRATION: {
			descriptionRole = 'accountsReceivableAdministration'
			break
		}
		case ROLE_COORDINATEINTERNATIONALTRADE: {
			descriptionRole = 'coordinateInternationalTrade'
			break
		}
		case ROLE_ACCESSCONTROL_INDUSTRIALSECURITY: {
			descriptionRole = 'accessControlIndustrialSecurity'
			break
		}
		case ROLE_ACCESSCONTROL_MEDICALSERVICE: {
			descriptionRole = 'accessControlMedicalService'
			break
		}
		case ROLE_SUSTANTABILITYQUESTIONNAIREGENERATOR: {
			descriptionRole = 'sustantabilityQuestionaire'
			break
		}
		case ROLE_CONTRACTS_SUPPLIERS: {
			descriptionRole = 'administratorContractsSupplier'
			break
		}
		case ROLE_CONTRACTS_VIEWER_SUPPLIERS: {
			descriptionRole = 'administratorContractsViewerSupplier'
			break
		}
		case ROLE_CONTRACTS_SUPPLIERS_APPLICANT: {
			descriptionRole = 'requestContractsSupplier'
			break
		}
		case ROLE_DOCUMENTS_ENV_SUPPLIERS: {
			descriptionRole = 'areaDocumentsEnvSupplier'
			break
		}
		case ROLE_PROSPECTS_SUPPLIERS: {
			descriptionRole = 'authorizerDocumentsProspects'
			break
		}
		case ROLE_PROSPECTS_CREATOR_SUPPLIERS: {
			descriptionRole = 'areaProspectsSupplierCreator'
			break
		}
		case ROLE_BIDDING_MODULE_ADMINISTRATOR: {
			descriptionRole = 'biddingAdministrator'
			break
		}
		case ROLE_BIDDING_RESPONSIBLE: {
			descriptionRole = 'biddingResponsible'
			break
		}
		case ROLE_MAQUILAORDER_MOLDE: {
			descriptionRole = 'maquilaMoldersAreas'
			break
		}
		case ROLE_MAQUILAORDER_ADMINISTRATION: {
			descriptionRole = 'maquilaAdministrationAreas'
			break
		}

		case ROLE_PHONE_DIRECTORY_ADMINISTRATOR: {
			descriptionRole = 'phoneDirectoryAdministrator'
			break
		}
		case ROLE_INDIRECT_RATING_RESPONSIBLE: {
			descriptionRole = 'indirectRatingResponsible'
			break
		}
		case ROLE_CONSIGNMENTNOTE_RESPONSIBLE: {
			descriptionRole = 'consignmentNoteResponsible'
			break
		}
		case ROLE_CO2_COTROL_SERVICE_EDITOR: {
			descriptionRole = 'CO2ServiceEditor'
			break
		}
		case ROLE_CO2_CONTROL_REQUEST_BLOCK: {
			descriptionRole = 'CO2ControlRequestBlock'
			break
		}

		/** Roles supplier */
		case ROLE_SUPPLIERADMINISTRATOR: {
			descriptionRole = 'supplierAdministrator'
			break
		}
		case ROLE_SUPPLIER_SALESENGINEER: {
			descriptionRole = 'supplierSalesEnginner'
			break
		}
		case ROLE_SUPPLIER_QUALITYNGINEER: {
			descriptionRole = 'supplierQualityEnginner'
			break
		}
		case ROLE_SUPPLIER_BILLINGENGINEER: {
			descriptionRole = 'supplierBillingEngineer'
			break
		}
		case ROLE_SUPPLIER_ENGINEERINGENGINEER: {
			descriptionRole = 'supplierEngineeringEngineer'
			break
		}
		case ROLE_SUPPLIER_IMPORTS: {
			descriptionRole = 'supplierImports'
			break
		}
		case ROLE_SUPPLIER_LOGISTICPLANNER: {
			descriptionRole = 'supplierLogisticPlanner'
			break
		}
		case ROLE_ACCESSCONTROL_ADMINISTRATOR_SUPPLIER: {
			descriptionRole = 'accessControlAdministratorSupplier'
			break
		}
		case ROLE_SUPPLIER_BIDDINGS: {
			descriptionRole = 'supplierBiddings'
			break
		}
		case ROLE_SUPPLIER_SUSTAINABILITY: {
			descriptionRole = 'supplierSustantability'
			break
		}
		case ROLE_INDIRECT_RATING_SUPPLIER: {
			descriptionRole = 'indirectRatingSupplier'
			break
		}
		case ROLE_RESPONSIBLE_SUPPLIER_DOSSIER: {
			descriptionRole = 'responsibleSupplierDossier'
			break
		}
		case ROLE_TRANSPORT_TENDER_ADMINISTRATOR: {
			descriptionRole = 'transportTenderAdminitrator'
			break
		}
		case ROLE_CREATE_LOAD_ALERTS: {
			descriptionRole = 'createLoadAlert'
			break
		}
		case SEE_LOAD_ALERTS: {
			descriptionRole = 'loadAlert'
			break
		}
		case FOREIGN_TRADE_VIEWER: {
			descriptionRole = 'foreignTradeViewer'
			break
		}
		case FOREIGN_TRADE_VIEWER_EXTERNAL: {
			descriptionRole = 'foreignTradeViewerExternal'
			break
		}
		case INTERNATIONAL_LOAD_ALERT: {
			descriptionRole = 'internationalLoadAlert'
			break
		}
		case NATIONAL_LOAD_ALERT: {
			descriptionRole = 'nationalLoadAlert'
			break
		}
		case PLANER_LOAD_ALERT: {
			descriptionRole = 'plannerLoadAlert'
			break
		}

		/**Transporte distribución  */
		case TRANSPORT_GILDER: {
			descriptionRole = 'transportGlider'
			break
		}
		case TRANSPORTATION_ASSIGMENT: {
			descriptionRole = 'transportationAssignment'
			break
		}
		case ROLE_SUPPLIER_DEVELOPMENT_ADMINISTRATOR_SAP_TARIFF: {
			descriptionRole = 'supplierDevelopmentAdministratorSAPTariff'
			break
		}
		case ROLE_SUPPLIER_DEVELOPERS_EVALUATION_QUESTIONNAIRE_FILE:{
			descriptionRole = 'SupplierDevelopersEvaluationQuestionnaireFile'
			break
		}
		case ROLE_FACTORING_AFFILIATION_ADMINISTRATOR: {
			descriptionRole = 'factoringAffiliationAdministrator'
			break
		}
		case ROLE_FACTORING_AFFILIATION_CREATOR: {
			descriptionRole = 'factoringAffiliateCreator'
			break
		}
		case ROLE_EXPEDIENT_VIEWER: {
			descriptionRole = 'ExpedientViewer'
			break
		}
		case ROLE_PROSPECT_ADMINISTRATOR: {
			descriptionRole = 'ProspectAdminitrator'
			break
		}
		case ROLE_RESPONSIBLE_SPAREPARTS: {
			descriptionRole = 'ResponsibleSparePart'
			break
		}
		default:
			break
	}
	return descriptionRole
}
