import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

// Importaciones de Material-UI
import { withStyles } from '@material-ui/core/styles'
import SuppliersSelector from '../../common/SupplierSelector'
import ImportExcludeSupplierFromExcelDialog from './ImportExcludeSupplierFromExcelDialog'
import { CloudUpload } from '@material-ui/icons'

const ExcludeSuppliers = (props) => {
	const { isSaving, survey, classes } = props
	const {
		onAddExcludeSupplierFromBackEnd,
		onRemoveExcludeSupplierFromBackEnd,
		onAddExcludeSupplierFromExcelFileFromBackEnd,
	} = props

	const [uploadExcludeSupplierMassivePanelConfig, setUploadExcludeSupplierMassivePanelConfig] = useState(false)

	const onExcludeSupplierPanelConfig = () => {
		setUploadExcludeSupplierMassivePanelConfig(!uploadExcludeSupplierMassivePanelConfig)
	}

	const onAddExcludeSupplierFromExcelFile = (files) => {
		return onAddExcludeSupplierFromExcelFileFromBackEnd(files).then((_) => {
			onExcludeSupplierPanelConfig()
		})
	}
	const onGetSupplierList = () => {
		if (survey) {
			return (
				<List>
					{survey &&
						survey.ExcludeSuppliers.map((supplier) => {
							return (
								<div key={supplier.name}>
									<ListItem divider>
										<ListItemText
											primary={
												<Typography >
													{supplier.Code + ' | ' + supplier.Name}
												</Typography>
											}
										/>
										<ListItemSecondaryAction>
											<IconButton
												id={supplier.code}
												onClick={(event) => {
													onRemoveExcludeSupplierFromBackEnd(supplier.Code)
												}}
												disabled={isSaving}
											>
												<DeleteIcon color='error' />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</div>
							)
						})}
				</List>
			)
		} else {
			return null
		}
	}

	return (
		<Grid container spacing={8}>
			<Grid item xs={11}>
				<SuppliersSelector
					className={classes.textField}
					onSupplierSelected={(supplier) => {
						onAddExcludeSupplierFromBackEnd(supplier)
					}}
				/>
			</Grid>
			<Grid item xs={1}>
				<Tooltip title='Importar proveedores a excluir'>
					<Fab size='small' onClick={onExcludeSupplierPanelConfig} disabled={isSaving}>
						<CloudUpload size='small' />
					</Fab>
				</Tooltip>
			</Grid>
			<Grid item xs={12}>
				<List>
					{onGetSupplierList()}
					{survey && survey.ExcludeSuppliers.length === 0 && (
						<div>
							<ListItem>
								<ListItemText
									primary={
										<Typography variant='subtitle2' gutterBottom>
											No existen proveedores excluidos
										</Typography>
									}
								/>
							</ListItem>
							<Divider />
						</div>
					)}
				</List>
			</Grid>

			<ImportExcludeSupplierFromExcelDialog
				isSaving={isSaving}
				open={uploadExcludeSupplierMassivePanelConfig}
				onClose={onExcludeSupplierPanelConfig}
				onAddExcludeSupplierFromExcelFileFromBackEnd={onAddExcludeSupplierFromExcelFile}
			/>
		</Grid>
	)
}

const styles = (theme) => ({
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 240,
	},
})

export default withStyles(styles)(ExcludeSuppliers)
