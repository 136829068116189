/** Helpers import section */
import axios from 'axios'
import { getAuthorizedRequestConfigurationUser, getAuthorizedRequestConfigurationUserFiles } from '../helpers/SessionHelper'
import { onGetBlobType } from '../helpers/BlobTypeHelper'
import moment from 'moment'

export const IS_SAVING = 'IS_SAVING'
export const IS_LOADING = 'IS_LOADING'
export const SET_SUPPLIER_DRIVER_BLOCKING_LIST = 'SET_SUPPLIER_DRIVER_BLOCKING_LIST'
export const ADD_SUPPLIER_DRIVER_BLOCKING = 'ADD_SUPPLIER_DRIVER_BLOCKING'
export const EDIT_SUPPLIER_DRIVER_BLOCKING = 'EDIT_SUPPLIER_DRIVER_BLOCKING'
export const DELETE_SUPPLIER_DRIVER_BLOCKING = 'DELETE_SUPPLIER_DRIVER_BLOCKING'
export const SUPPLIER_DRIVER_BLOCKING_DETAIL = 'SUPPLIER_DRIVER_BLOCKING_DETAIL'

/**
 * Action start loading common data
 * @param {*} isLoading
 */
function isLoadingCommon(isLoading, identifier) {
	return {
		type: IS_LOADING,
		isLoading: isLoading,
		identifier: identifier,
	}
}

/**
 * Action start saving information
 * @param {*} isLoading
 */
function isSavingCommon(isSaving, identifier) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
		identifier: identifier,
	}
}

export function setSupplierDriverBlockings(data) {
	return {
		type: SET_SUPPLIER_DRIVER_BLOCKING_LIST,
		data: data,
	}
}

export function addSupplierDriverBlockings(data) {
	return {
		type: ADD_SUPPLIER_DRIVER_BLOCKING,
		item: data,
	}
}

export function editSupplierDriverBlockings(data) {
	return {
		type: EDIT_SUPPLIER_DRIVER_BLOCKING,
		item: data,
	}
}

export function setSupplierDriverBlockingsById(data) {
	return {
		type: SUPPLIER_DRIVER_BLOCKING_DETAIL,
		item: data,
	}
}

export function setDeleteSupplierDriverBlockings(id) {
	return {
		type: DELETE_SUPPLIER_DRIVER_BLOCKING,
		id,
	}
}

export function loadSupplierDriverBlockingsPaginatedFromBackEnd(queryParams, activeTab) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SET_SUPPLIER_DRIVER_BLOCKING_LIST))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/SupplierDriverBlockings?IsActive=${activeTab}||equal`

		if (queryParams && queryParams.query) endPoint = endPoint + `&query=${queryParams.query}`
		if (queryParams && queryParams.pageNumber) endPoint = endPoint + `&pageNumber=${queryParams.pageNumber - 1}`
		if (queryParams && queryParams.rowsPerPage && queryParams.rowsPerPage !== 1000)
			endPoint = endPoint + `&pageSize=${queryParams.rowsPerPage}`
		if (queryParams && queryParams.rowsPerPage && queryParams.rowsPerPage === 1000)
			endPoint = endPoint + `?pageSize=${queryParams.rowsPerPage}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSupplierDriverBlockings(serverResponse.data))
					dispatch(isLoadingCommon(false, SET_SUPPLIER_DRIVER_BLOCKING_LIST))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SET_SUPPLIER_DRIVER_BLOCKING_LIST))
				console.error('Error getting SupplierDriverBlockings LIST', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function addSupplierDriverBlockingFromBackEnd(SupplierDriverBlockingsModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_SUPPLIER_DRIVER_BLOCKING))
		let selectedCompany = getState().profile.get('selectedCompany')
		let user = getState().oidc.user

		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/SupplierDriverBlockings`

			let command = {
				SupplierDriverBlockingModel: {
					...SupplierDriverBlockingsModel,
					RequestedBy: 'adimbptm',
					CreatedByUserName: user.profile.preferred_username,
					CreatedByName: user.profile.name,
					Id: '-',
				},

				// ...SupplierDriverBlockingsModel,
				// RequestedBy: "adimbptm",
				// CreatedByUserName: user.profile.preferred_username,
				// CreatedByName: user.profile.name
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(addSupplierDriverBlockings(capitalizeKeys(serverResponse.data)))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, ADD_SUPPLIER_DRIVER_BLOCKING))
					console.error('Error in add supplier Vehicle Blocking', error.response)
					return Promise.reject(error.response.data.detail)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_SUPPLIER_DRIVER_BLOCKING))
		}
	}
}

export function updateSupplierDriverBlockingsFromBackEnd(id, supplierDriverBlockingsModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, EDIT_SUPPLIER_DRIVER_BLOCKING))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/SupplierDriverBlockings`

			let command = {
				SupplierDriverBlockingModel: {
					...supplierDriverBlockingsModel,
					Id: id,
				},
			}
			return axios
				.put(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setSupplierDriverBlockingsById(serverResponse.data))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, EDIT_SUPPLIER_DRIVER_BLOCKING))
					console.error('Error in edit supplier diver Blocking', error.response)
					return Promise.reject(error.response.data.detail)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_SUPPLIER_DRIVER_BLOCKING))
		}
	}
}

export function deleteSupplierDriverBlockingsFromBackEnd(id) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, DELETE_SUPPLIER_DRIVER_BLOCKING))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/SupplierDriverBlockings/${id}`

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, DELETE_SUPPLIER_DRIVER_BLOCKING))
					dispatch(setDeleteSupplierDriverBlockings(id))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, DELETE_SUPPLIER_DRIVER_BLOCKING))
				console.error('Error delete supplier Vehicle Blocking', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function setSupplierDriverBlockingActiveFlag(id, isActive) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, EDIT_SUPPLIER_DRIVER_BLOCKING))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/SupplierDriverBlockings/isActive`

			let command = {
				Id: id,
				IsActive: isActive,
			}
			return axios
				.put(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setDeleteSupplierDriverBlockings(serverResponse.data.Id))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, EDIT_SUPPLIER_DRIVER_BLOCKING))
					console.error('Error in edit supplier Vehicle Blocking', error.response)
					return Promise.reject(error.response.data.detail)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_SUPPLIER_DRIVER_BLOCKING))
		}
	}
}

export function getSupplierDriverBlockingByIdFromBackEnd(id) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SUPPLIER_DRIVER_BLOCKING_DETAIL))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/SupplierDriverBlockings/${id}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCommon(false, SUPPLIER_DRIVER_BLOCKING_DETAIL))
					dispatch(setSupplierDriverBlockingsById(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SUPPLIER_DRIVER_BLOCKING_DETAIL))
				console.error('Error supplier driver Blocking detail', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export const downloadSupplierDriverBlockingFile = (id, fileName) => {
	return async (dispatch, getState) => {
		try {
			const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			config.responseType = 'arraybuffer'
			const endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/SupplierDriverBlockings/files/${id}/${fileName}`
			let command = {}
			let serverResponse = await axios.put(endPoint, command, {
				responseType: 'arraybuffer',
				headers: config.headers,
			})
			let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName
			if (serverResponse.status === 200) {
				let fileBlog = new Blob([serverResponse.data], {
					type: onGetBlobType(fileExtension),
				})
				let url = window.URL.createObjectURL(fileBlog)
				let a = document.createElement('a')
				a.href = url
				a.download = `${fileName}`
				a.click()
				return Promise.resolve(url)
			}
			return Promise.resolve('Ok')
		} catch (exp) {
			console.error(exp)
			return Promise.reject(exp.reject)
		}
	}
}


export function addSupplierDriverBlockingFiles(id, attachments) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/SupplierDriverBlockings/files/${id}`
		let formData = new FormData()
		attachments.forEach((file) => {
			formData.append('data', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSupplierDriverBlockingsById(serverResponse.data))
					dispatch(isSavingCommon(false, IS_SAVING))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, IS_SAVING))
				console.error('Error add attachments in the SupplierDriverBlocking', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Delete SupplierDriverBlocking attachments
 * @param {string} id
 * @returns
 */
export function deleteSupplierDriverBlockingFiles(id, fileName, folderName) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))

		const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/SupplierDriverBlockings/files/${id}/${fileName}`
		return axios
			.delete(endPoint, config)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSupplierDriverBlockingsById(serverResponse.data))
					dispatch(isSavingCommon(false, IS_SAVING))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, IS_SAVING))
				console.error('Error add attachments in the SupplierDriverBlocking', error.response)
				return Promise.reject()
			})
	}
}



export function downloadDriverBlockingReport(startDate, endDate, codes,downloadType) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/SupplierDriverBlockings/report`
		let data = {
			RequestedBy: 'adimbptm',
			StartDate: moment(startDate).format().toString(),
			EndDate: moment(endDate).format().toString(),
			Codes: codes,
			DownloadType: downloadType
		}

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Folios_en_proceso.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.log({ error })
			})
	}
}


function capitalizeKeys(obj) {
	let result = {}
	for (let key in obj) {
		if (obj.hasOwnProperty(key)) {
			let newKey = key.charAt(0).toLocaleLowerCase() + key.slice(1)
			result[newKey] = obj[key]
		}
	}
	return result
}
