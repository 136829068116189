/** Helpers import section */
import axios from 'axios'
import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'
import { onGetBlobType } from '../helpers/BlobTypeHelper'

export const IS_SAVING = 'IS_SAVING'
export const IS_LOADING = 'IS_LOADING'
export const SET_SURVEY_LIST = 'SET_SURVEY_LIST'
export const ADD_SURVEY_TEMPLATE = 'ADD_SURVEY_TEMPLATE'
export const EDIT_SURVEY_TEMPLATE = 'EDIT_SURVEY_TEMPLATE'
export const ADD_SURVEY_SECTION = 'ADD_SURVEY_SECTION'
export const DELETE_SURVEY_SECTION = 'DELETE_SURVEY_SECTION'
export const SURVEY_DETAIL = 'SURVEY_DETAIL'
export const SET_AVAILABLE_SURVEYS_BY_SUPPLIER = 'SET_AVAILABLE_SURVEYS_BY_SUPPLIER'
export const SET_SURVEY_RESPONSE_SUPPLIER = 'SET_SURVEY_RESPONSE_SUPPLIER'
export const DELETE_SURVEY_ANSWERED_BY_SUPPLIER = 'DELETE_SURVEY_ANSWERED_BY_SUPPLIER'

export function setSupplierAnswers(item) {
	return {
		type: SET_SURVEY_RESPONSE_SUPPLIER,
		item: item,
	}
}

export function setDeleteSurveyAnswered(item) {
	return {
		type: DELETE_SURVEY_ANSWERED_BY_SUPPLIER,
		item: item,
	}
}

export function setAvailableSurveysBySupplier(items) {
	return {
		type: SET_AVAILABLE_SURVEYS_BY_SUPPLIER,
		items: items,
	}
}

/**
 * Action start loading common data
 * @param {*} isLoading
 */
function isLoadingCommon(isLoading, identifier) {
	return {
		type: IS_LOADING,
		isLoading: isLoading,
		identifier: identifier,
	}
}

/**
 * Action start saving information
 * @param {*} isLoading
 */
function isSavingCommon(isSaving, identifier) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
		identifier: identifier,
	}
}

export function setSurveyList(data) {
	return {
		type: SET_SURVEY_LIST,
		data: data,
	}
}

export function addSurveyTemplate(data) {
	return {
		type: ADD_SURVEY_TEMPLATE,
		item: data,
	}
}

export function editSurveyTemplate(data) {
	return {
		type: EDIT_SURVEY_TEMPLATE,
		item: data,
	}
}

export function setSurveyById(data) {
	return {
		type: SURVEY_DETAIL,
		item: data,
	}
}

export function setAddSurveySection(item) {
	return {
		type: ADD_SURVEY_SECTION,
		item: item,
	}
}

export function setDeleteSurveySection(id) {
	return {
		type: DELETE_SURVEY_SECTION,
		id,
	}
}

export function loadSurveysPaginatedFromBackEnd(queryParams) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SET_SURVEY_LIST))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys`

		if (queryParams && queryParams.query) endPoint = endPoint + `?query=${queryParams.query}`
		if (queryParams && queryParams.rowsPerPage) endPoint = endPoint + `&pageSize=${queryParams.rowsPerPage}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					console.log('serverResponse.data', serverResponse.data)
					dispatch(setSurveyList(serverResponse.data))
					dispatch(isLoadingCommon(false, SET_SURVEY_LIST))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SET_SURVEY_LIST))
				console.error('Error getting survey', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

/**
 * Action to create new Ethic code
 *
 * @export
 * @param {*} surveyModel
 * @returns
 */
export function createSurveysData(surveyModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))

		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys`
		let selectedCompany = getState().profile.get('selectedCompany').toJS()
		let formData = new FormData()
		surveyModel.attachments.forEach((file) => {
			formData.append('file', file)
		})

		let command = {
			CompanyCode: selectedCompany.company_code,
			Name: surveyModel.name,
			Nationals: surveyModel.optionNationals,
			Foreigns: surveyModel.optionForeigns,
			SurveySuppliers: surveyModel.suppliers,
			ActivationDate: surveyModel.activationDate,
			ExpirationDate: surveyModel.expirationDate,
			SurveyTemplateId: surveyModel.surveyTemplateId,
		}
		command = JSON.stringify(command)

		formData.append('command', command)

		return axios
			.post(endPoint, formData, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSavingCommon(false))
					return Promise.resolve(response.data)
				} else {
					dispatch(isSavingCommon(false))
				}
			})
			.catch((error) => {
				console.error('Error create Surveys', error.response)
				dispatch(isSavingCommon(false))
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function getSurveyByIdFromBackEnd(id) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SURVEY_DETAIL))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys/${id}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCommon(false, SURVEY_DETAIL))
					dispatch(setSurveyById(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SURVEY_DETAIL))
				console.error('Error getting survey by id', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function getAvailableSurveysBySupplier() {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SURVEY_DETAIL))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys/availableSurveysBySupplier`
		let selectedCompany = getState().profile.get('selectedCompany').toJS()
		let supplier = getState().suppliers.get('supplier')
		let supplierCode = undefined
		let supplierEmail = undefined
		let supplierName = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierCode = getState().oidc.user.profile.company_code
			supplierEmail = getState().oidc.user.profile.email
			supplierName = getState().oidc.user.profile.name
		}
		if (supplier) {
			supplierName = supplier.get('name')
			supplierEmail = supplier.get('email')
		}
		let command = {
			SurveySupplier: {
				Code: supplierCode,
				Name: supplierName,
				Email: supplierEmail,
				RFC: '-',
				Country: '-',
			},
			CompanyCode: selectedCompany.company_code,
		}

		return axios
			.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCommon(false, SURVEY_DETAIL))
					dispatch(setAvailableSurveysBySupplier(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SURVEY_DETAIL))
				console.error('Error getting survey by id', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function addSurveyAnswer(surveyId, questionId, answer) {
	return function (dispatch, getState) {
		// dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys/setAnswer`
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierCode = getState().oidc.user.profile.company_code
		}

		let command = {
			SurveyId: surveyId,
			Answer: answer,
			QuestionId: questionId,
			SupplierCode: supplierCode,
		}
		console.log('command', command)

		return axios
			.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false))
					dispatch(setSupplierAnswers(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error to add survey answer', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function addExcludeSupplierFromBackEnd(surveyId, supplierCode, supplierName) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys/excludeSupplier`
		let command = {
			SurveyId: surveyId,
			SupplierCode: supplierCode,
			SupplierName: supplierName,
		}
		return axios
			.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false))
					dispatch(setSurveyById(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error add supplier to list to exclude', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function removeExcludeSupplierFromList(surveyId, supplierCode) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys/remove/excludeSupplier`
		let command = {
			SurveyId: surveyId,
			SupplierCode: supplierCode,
		}
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false))
					dispatch(setSurveyById(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error add supplier to list to exclude', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function importExcludeSupplierFromExcelFile(file, surveyId) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys/excludeSuppliers/import/${surveyId}`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSurveyById(serverResponse.data))
					dispatch(isSavingCommon(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))

				console.error('Error add transportZone', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function updateSupplierSurveyResponseStatusFromBackend(item) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let user = getState().oidc.user
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys/supplierResponse/status`
		let command = {
			SurveyId: item.Id,
			SupplierCode: item && item.SupplierSurveyResponse && item.SupplierSurveyResponse.SurveySupplier.Code,
			SurveySupplierResponseId: item && item.SupplierSurveyResponse.Id,
			Status: 2, // closed
			AnsweredBy: user.profile.name
		}
		return axios
			.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setDeleteSurveyAnswered(serverResponse.data))
					dispatch(isSavingCommon(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error add supplier to list to exclude', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function editSurveyFromBackEnd(surveyModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys`
		let command = {
			SurveyId: surveyModel.Id,
			Name: surveyModel.Name,
			ActivationDate: surveyModel.ActivationDate,
			ExpirationDate: surveyModel.ExpirationDate,
		}
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false))
					dispatch(setSurveyById(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('Error to edit survey', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function downloadSurverSupplierResponses(surveyId) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		const url = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys/download/report/${surveyId}`
		return axios
			.get(url, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'ReporteRespuestas de proveedor.xlsx'
					a.click()
					dispatch(isSavingCommon(false))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false))
				console.error('positionsActions: error to download level <=> Error: ', error)
				return Promise.reject()
			})
	}
}


export const downloadSuveySharedWhithSupplierFile = (id, fileName) => {
	return async (dispatch, getState) => {
		try {
			const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			config.responseType = 'arraybuffer'
			const endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys/files/${id}/${fileName}`
			let command = {}
			let serverResponse = await axios.put(endPoint, command, {
				responseType: 'arraybuffer',
				headers: config.headers,
			})
			let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName
			if (serverResponse.status === 200) {
				let fileBlog = new Blob([serverResponse.data], {
					type: onGetBlobType(fileExtension),
				})
				let url = window.URL.createObjectURL(fileBlog)
				let a = document.createElement('a')
				a.href = url
				a.download = `${fileName}`
				a.click()
				return Promise.resolve(url)
			}
			return Promise.resolve('Ok')
		} catch (exp) {
			console.error(exp)
			return Promise.reject(exp.reject)
		}
	}
}