import React from 'react'
// Importaciones de Material-UI
import { RadioGroup, FormControlLabel, Chip, IconButton, Tooltip, Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const MultipleChoiceOptions = (props) => {
	const { question, canBeEditData, survey, supplierSurveyResponse, canBeAddedResponse } = props
	//Functions
	const { onOpenCloseEditOptionConfigPanel, onOpenCloseDeleteOptionConfigPanel, onAddSurveyAnswerFromBackEnd } = props
	let existQuestionResponseBySupplier =
		supplierSurveyResponse && supplierSurveyResponse.Answers.find((x) => x.QuestionId === question.Id)

	return (
		<RadioGroup>
			{question.Options.map((option, optionIndex) => {
				let existResponseBySupplier =
					existQuestionResponseBySupplier &&
					existQuestionResponseBySupplier.Responses &&
					existQuestionResponseBySupplier.Responses.find((x) => x.Id === option.Id)

				return (
					<div
						key={optionIndex}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							// marginBottom: '8px',
							backgroundColor: canBeEditData && option.IsCorrect ? '#D6EAF8' : 'transparent', // Fondo normal para incorrectas
							paddingLeft: '8px', // Espaciado interno opcional
							paddingRight: '8px', // Espaciado interno opcional
							borderRadius: '4px', // Bordes redondeados opcionales
						}}
					>
						<FormControlLabel
							style={{ '& .MuiTypography-root': { fontSize: '12px' } }}
							
							value={option.Title}
							control={
								<Checkbox
									disabled={!canBeAddedResponse}
									color='primary'
									checked={existResponseBySupplier ? true : false}
								/>
							}
							label={option.Title}
							disabled={!canBeAddedResponse}
							onClick={(event) =>
								onAddSurveyAnswerFromBackEnd(survey ? survey.Id : '', question.Id, option.Id)
							}
						/>
						{canBeEditData && (
							<div style={{ display: 'flex', gap: '8px' }}>
								<Chip label={`${option.Percentage}%`} color='primary' size='small' />

								<Tooltip title='Editar opción'>
									<IconButton
										color='primary'
										size='small'
										onClick={() => onOpenCloseEditOptionConfigPanel(option)}
									>
										<EditIcon fontSize='small' />
									</IconButton>
								</Tooltip>

								<Tooltip title='Eliminar opción'>
									<IconButton
										// color='secondary'
										size='small'
										onClick={() => onOpenCloseDeleteOptionConfigPanel(option)}
									>
										<DeleteIcon fontSize='small' />
									</IconButton>
								</Tooltip>
							</div>
						)}
					</div>
				)
			})}
		</RadioGroup>
	)
}

const styles = (theme) => ({
	emptyStateContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '32px',
		backgroundColor: '#fff',
		borderRadius: '8px',
		boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
		marginTop: '16px',
	},
	addSectionButton: {
		marginTop: '16px',
		textTransform: 'none',
		fontSize: '1rem',
	},
})

export default withStyles(styles)(MultipleChoiceOptions)
