import React from 'react'
import { Translate } from 'react-localize-redux'
import moment from 'moment'
import { withRouter } from 'react-router-dom'

/** Material UI imports section */
import { CardHeader, Typography, Fab, withStyles, Button } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/MenuOutlined'
/** resources imports section */
import yourlogohere from '../../../resources/images/imagotipo.png'
/**store imports section */
import {
	descriptionCarrierStatus,
	descriptionTransportTEnderStatus,
	getColorStatusCarierText,
	getColorStatusText,
} from '../../../store/helpers/TransportTenderHelper'
import { CarrierStatus, TransportTendeStatus } from '../../../store/helpers/AppConstants'
import { CloudDownload, Sync } from '@material-ui/icons'

const HeaderDetail = (props) => {
	const {
		classes,
		transportTenderdetail,
		selectedCompany,
		setOpenDialog,
		isInternalUser,
		carrierInformation,
		onDonwloadCarrierLog,
		onDownloadConsignmentNoteInformation,
		isLoadingDownlaod,
		isTransportWinner,
		isLoading,
		onSetIsOpenDialogUpdateSchedule,
		isAdministratorTransportTender
	} = props
	return (
		<CardHeader
			title={
				<Typography variant='h6'>
					<strong>
						{<Translate id='carrierTenders.detail.folio' />}{' '}
						{transportTenderdetail ? transportTenderdetail.Folio : ''}
					</strong>
				</Typography>
			}
			subheader={
				<Typography variant='caption' display='block'>
					{
						<strong>
							{<Translate id='carrierTenders.detail.sender' />}
							{selectedCompany && selectedCompany.name}
						</strong>
					}{' '}
					<br />
					{'RFC: '} {selectedCompany.rfc && selectedCompany.rfc} <br />
					<div style={{ width: '250px' }}>{selectedCompany.address && `${selectedCompany.address} `}</div>
					<br />
				</Typography>
			}
			action={
				<span>
					<Typography variant='caption' className={classes.textHeader}>
						{isInternalUser && (
							<strong
								className={classes.btnStatus}
								style={{
									backgroundColor: getColorStatusText(
										transportTenderdetail ? transportTenderdetail.Status : 0
									),
									padding: '5px',
									borderRadius: '6px',
								}}
							>
								{' '}
								{descriptionTransportTEnderStatus(
									transportTenderdetail ? transportTenderdetail.Status : 0
								)}{' '}
							</strong>
						)}
						{!isInternalUser && (
							<strong
								className={classes.btnStatus}
								style={{
									backgroundColor: getColorStatusCarierText(
										carrierInformation ? carrierInformation.Status : 1
									),
									padding: '5px',
									borderRadius: '6px',
								}}
							>
								{' '}
								<Translate
									id={descriptionCarrierStatus(
										carrierInformation ? carrierInformation.Status : 1,
										isTransportWinner,
										transportTenderdetail ? transportTenderdetail.Status : null
									)}
								/>
							</strong>
						)}
						<br />
						<div className={classes.dateContainer}>
							<div className={classes.dateTitle}>
								<Typography variant='caption'>
									<strong>
										<Translate id='carrierTenders.detail.creationDate' />
									</strong>
								</Typography>
							</div>
							<div className={classes.dateMoment}>
								<Typography variant='caption'>
									{transportTenderdetail
										? moment(transportTenderdetail.CreationDate).format('DD/MM/YYYY')
										: ''}
								</Typography>
							</div>
							{false && (
								<div className={classes.dateTitle2}>
									<Typography variant='caption'>
										<strong>
											<Translate id='carrierTenders.applicationDate' />:
										</strong>
									</Typography>
								</div>
							)}
							{false && (
								<div className={classes.dateMoment2}>
									<Typography variant='caption'>
										{transportTenderdetail && transportTenderdetail.Applicationdate ? (
											moment(transportTenderdetail.Applicationdate).format('DD/MM/YYYY')
										) : (
											<Translate id='carrierTenders.notHaveApplicationDate' />
										)}
									</Typography>
								</div>
							)}
						</div>
					</Typography>
					{(isInternalUser ||
						(carrierInformation && carrierInformation.Status !== CarrierStatus.EVALUATION)) && (
						<p className={classes.textHeader}>
							<Fab
								variant='extended'
								size='small'
								color='primary'
								className={classes.margin}
								onClick={() => setOpenDialog(true)}
								disabled={isLoading}
							>
								<MenuIcon className={classes.extendedIcon} />
								{<Translate id='common.AvailableOperations' />}
							</Fab>
						</p>
					)}
					{((isInternalUser &&
						transportTenderdetail &&
						transportTenderdetail.Status === TransportTendeStatus.SENT_LONG_TO_WINNING_CARRIER) ||
						(isTransportWinner &&
							transportTenderdetail.Status === TransportTendeStatus.SENT_LONG_TO_WINNING_CARRIER)) && (
						<div className={classes.contentDownlaodLogBtn}>
							<Button
								className={classes.cartaPorteBtn}
								onClick={onDownloadConsignmentNoteInformation}
								disabled={isLoadingDownlaod}
							>
								<CloudDownload style={{ paddingRight: '8px' }} />
								<Typography variant='caption' className={classes.textDownloadLogBtn}>
									<Translate id={'carrierTenders.detail.downloadCartaPorte'} />
								</Typography>
							</Button>
							<Button
								className={classes.downloadLogBtn}
								onClick={onDonwloadCarrierLog}
								disabled={isLoadingDownlaod}
							>
								<CloudDownload style={{ paddingRight: '8px' }} />
								<Typography variant='caption' className={classes.textDownloadLogBtn}>
									<Translate id={'carrierTenders.detail.downlaodLog'} />
								</Typography>
							</Button>
							{isInternalUser && isAdministratorTransportTender&&(
								<Button
									className={classes.downloadLogBtn}
									onClick={onSetIsOpenDialogUpdateSchedule}
									disabled={isLoadingDownlaod}
								>
									<Sync style={{ paddingRight: '8px' }} />
									<Typography variant='caption' className={classes.textDownloadLogBtn}>
										<Translate id={'carrierTenders.detail.syncDateCharge'} />
									</Typography>
								</Button>
							)}
						</div>
					)}
				</span>
			}
			avatar={<img src={yourlogohere} alt='logo' className={classes.logoImg} />}
		/>
	)
}

const styles = (theme) => ({
	textHeader: {
		textAlign: 'end',
	},
	logoImg: {
		maxHeight: 85,
	},
	margin: {
		margin: theme.spacing.unit,
	},
	extendedIcon: {
		marginRight: theme.spacing.unit,
	},
	btnStatus: {
		fontSize: '106%',
		whiteSpace: 'normal',
		color: 'white',
		padding: '5px',
		borderRadius: '6px',
		margin: '0px 10px 10px 0px',
	},
	addIcon: {
		gridColumnStart: '2/3',
		color: 'red',
		display: 'flex',
	},
	contentDownlaodLogBtn: {
		// paddingTop: '8px',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	cartaPorteBtn: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		border: 'none',
		borderRadius: '.5rem',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	downloadLogBtn: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		border: 'none',
		borderRadius: '.5rem',
		marginLeft: '5px',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	textDownloadLogBtn: {
		textTransform: 'initial',
		color: 'white',
	},
	dateContainer: {
		display: 'grid',
		gridTemplateColumns: 'auto auto',
	},
	dateTitle: {
		gridRow: '1/2',
		gridColumn: '1/2',
	},
	dateMoment: {
		gridRow: '1/2',
		gridColumn: '2/3',
	},
	dateTitle2: {
		gridRow: '2/3',
		gridColumn: '1/2',
	},
	dateMoment2: {
		gridRow: '2/3',
		gridColumn: '2/3',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(HeaderDetail))
