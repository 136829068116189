import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
	Card,
	CardContent,
	Typography,
	Divider,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Avatar,
	Tabs,
	Tab,
	ListItemSecondaryAction,
	IconButton,
	Grid,
	CardHeader,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'

import {
	CalendarToday,
	Edit,
	Person,
	CheckCircle,
	Cancel,
	AttachFile,
	CloudDownload,
	Delete,
	Comment,
	Receipt,
	ArrowBack,
} from '@material-ui/icons'

// Importaciones de Material-UI
import { withStyles } from '@material-ui/core/styles'
import Evidence from '../../../common/Evidence'
const ShowDesktop = (props) => {
	const { supplierDriverBlocking, isSaving } = props
	const {
		onOpenCloseSupplierVehicleBlocking,
		onDownloadSupplierDriverBlockingFile,
		onAddSupplierDriverBlockingFile,
		onDeleteSupplierDriverBlockingsFiles,
	} = props

	const [activeTab, setActiveTab] = useState(0)

	if (!supplierDriverBlocking) {
		return (
			<Typography variant='h6' color='textSecondary' align='center'>
				No hay información disponible.
			</Typography>
		)
	}

	const history = useHistory()

	const handleGoBack = () => {
		history.goBack()
	}

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue)
	}

	console.log('supplierDriverBlocking', supplierDriverBlocking)
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#f5f5f5',
				padding: 16,
			}}
		>
			<Card
				style={{
					width: '100%',
					borderRadius: 16,
					boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
					backgroundColor: '#ffffff',
				}}
			>
				<CardHeader
					avatar={
						<Avatar style={{ cursor: 'pointer' }} onClick={handleGoBack}>
							<ArrowBack />
						</Avatar>
					}
					title={
						<Typography
							variant='h5'
							color='primary'
							align='center'
							style={{ fontWeight: 'bold', marginBottom: 16 }}
						>
							Detalles del bloqueo del chófer del proveedor
						</Typography>
					}
					// subheader='September 14, 2016'
				/>
				<CardContent>
					{/* <Typography
						variant='h5'
						color='primary'
						align='center'
						style={{ fontWeight: 'bold', marginBottom: 16 }}
					>
						Detalles del bloqueo del chófer del proveedor
					</Typography> */}
					<Divider style={{ marginBottom: 24 }} />

					{/* Tabs Header */}
					<Tabs
						value={activeTab}
						onChange={handleTabChange}
						indicatorColor='primary'
						textColor='primary'
						centered
					>
						<Tab label='Detalle del bloqueo' />
						<Tab label='Archivos' />
					</Tabs>

					<Divider style={{ margin: '16px 0' }} />

					{/* Tabs Content */}
					{activeTab === 0 && (
						<List>
							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<Person />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={<Translate id='factoringAffiliation.table.supplierName' />}
									secondary={
										`${supplierDriverBlocking.SupplierCode}|${supplierDriverBlocking.SupplierName}` ||
										'No disponible'
									}
								/>
								<ListItemSecondaryAction>
									<IconButton onClick={onOpenCloseSupplierVehicleBlocking}>
										<Edit />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>

							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<CalendarToday />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary='Fecha de Creación'
									secondary={supplierDriverBlocking.CreationDate || 'No disponible'}
								/>
							</ListItem>

							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<CalendarToday />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={<Translate id='supplierDriversBlocking.create.blockingDate' />}
									secondary={supplierDriverBlocking.BlockingDate || 'No disponible'}
								/>
							</ListItem>

							<ListItem>
								<ListItemAvatar>
									<Avatar
										style={{ backgroundColor: supplierDriverBlocking.IsActive ? 'green' : 'red' }}
									>
										{supplierDriverBlocking.IsActive ? <CheckCircle /> : <Cancel />}
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary='Estado'
									secondary={supplierDriverBlocking.IsActive ? 'Activo' : 'Inactivo'}
								/>
							</ListItem>
							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<Person />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={<Translate id='supplierDriversBlocking.create.driverName' />}
									secondary={supplierDriverBlocking.DriverName || 'No disponible'}
								/>
							</ListItem>

							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<Receipt />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={<Translate id='supplierDriversBlocking.create.vehicleLicensePlate' />}
									secondary={supplierDriverBlocking.VehicleLicensePlate || 'No disponible'}
								/>
							</ListItem>

							<ListItem
								style={{
									maxWidth: '950px',
									wordBreak: 'break-word',
									overflowWrap: 'break-word',
									whiteSpace: 'normal',
								}}
							>
								<ListItemAvatar>
									<Avatar>
										<Comment />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={<Translate id='supplierDriversBlocking.create.reason' />}
									secondary={supplierDriverBlocking.Reason || 'No disponible'}
								/>
							</ListItem>
						</List>
					)}

					{activeTab === 1 && (
						<Grid container>
							<Grid item xs={12}>
								<Typography variant='subtitle2' color={'textSecondary'}>
									<Translate id={'supplierDriversBlocking.edit.messageToAddFile'} />
								</Typography>
								<Evidence
									text={'Arrastra y suelta un archivo o haz clic'}
									baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
									uploadFiles={onAddSupplierDriverBlockingFile}
									disabledLink
								/>
							</Grid>
							<Grid item xs={12}>
								<List>
									{supplierDriverBlocking &&
										supplierDriverBlocking.Attachments &&
										supplierDriverBlocking.Attachments.map((file) => {
											return (
												<>
													<ListItem>
														<ListItemAvatar>
															<Avatar>
																<AttachFile />
															</Avatar>
														</ListItemAvatar>
														<ListItemText primary={file.name} />
														<ListItemSecondaryAction>
															<>
																<IconButton
																	aria-label='download'
																	onClick={() =>
																		onDownloadSupplierDriverBlockingFile(file.name)
																	}
																	disabled={isSaving}
																>
																	<CloudDownload />
																</IconButton>
																<IconButton
																	aria-label='download'
																	onClick={() =>
																		onDeleteSupplierDriverBlockingsFiles(file.name)
																	}
																	disabled={isSaving}
																>
																	<Delete />
																</IconButton>
															</>
														</ListItemSecondaryAction>
													</ListItem>
													<Divider />
												</>
											)
										})}
									{supplierDriverBlocking &&
										supplierDriverBlocking.Attachments &&
										supplierDriverBlocking.Attachments.length === 0 && (
											<Typography>No existen archivos cargados hasta el momento</Typography>
										)}
								</List>
							</Grid>
						</Grid>
					)}
				</CardContent>
			</Card>
		</div>
	)
}

const styles = (theme) => ({})

export default withStyles(styles)(ShowDesktop)
