/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'
import moment from 'moment'

/** SocialInsurancePayments index constants */
export const IS_LOADING_SOCIAL_INSURANCE_PAYMENTS = 'IS_LOADING_SOCIAL_INSURANCE_PAYMENTS'
export const SET_SOCIAL_INSURANCE_PAYMENTS_LIST = 'SET_SOCIAL_INSURANCE_PAYMENTS_LIST'
export const CHANGE_SOCIAL_INSURANCE_PAYMENTS_SORT_CRITERIA = 'CHANGE_SOCIAL_INSURANCE_PAYMENTS_SORT_CRITERIA'
export const CHANGE_SOCIAL_INSURANCE_PAYMENTS_QUERY = 'CHANGE_SOCIAL_INSURANCE_PAYMENTS_QUERY'
export const CHANGE_SOCIAL_INSURANCE_PAYMENTS_PAGINATION = 'CHANGE_SOCIAL_INSURANCE_PAYMENTS_PAGINATION'
export const SET_SOCIAL_INSURANCE_PAYMENTS_COUNT = 'SET_SOCIAL_INSURANCE_PAYMENTS_COUNT'
export const ADD_SOCIAL_INSURANCE_PAYMENT = 'ADD_SOCIAL_INSURANCE_PAYMENT'

/** SocialInsurancePayment detail Constants */
export const IS_LOADING_SOCIAL_INSURANCE_PAYMENT = 'IS_LOADING_SOCIAL_INSURANCE_PAYMENT'
export const SET_SOCIAL_INSURANCE_PAYMENT = 'SET_SOCIAL_INSURANCE_PAYMENT'
export const IS_SAVING_SOCIAL_INSURANCE_PAYMENT = 'IS_SAVING_SOCIAL_INSURANCE_PAYMENT'
export const UPDATE_SOCIAL_INSURANCE_PAYMENT = 'UPDATE_SOCIAL_INSURANCE_PAYMENT'
export const ADD_EMPLOYEE_IN_PAYMENT = 'ADD_EMPLOYEE_IN_PAYMENT'
export const REMOVE_EMPLOYEE_IN_PAYMENT = 'REMOVE_EMPLOYEE_IN_PAYMENT'

/**
 * Set flag to determine if is loading socialInsurancePayments list
 *
 * @param {*} isLoadingSocialInsurancePayments
 * @returns
 */
function isLoadingSocialInsurancePayments(isLoadingSocialInsurancePayments) {
	return {
		type: IS_LOADING_SOCIAL_INSURANCE_PAYMENTS,
		isLoadingSocialInsurancePayments,
	}
}

/**
 * Set socialInsurancePayments list in store
 *
 * @param {*} socialInsurancePayments
 * @returns
 */
function setSocialInsurancePaymentsList(socialInsurancePayments) {
	return {
		type: SET_SOCIAL_INSURANCE_PAYMENTS_LIST,
		socialInsurancePayments,
	}
}

/**
 * Set socialInsurancePayments list count in store
 *
 * @param {*} count
 * @returns
 */
function setSocialInsurancePaymentsCount(count) {
	return {
		type: SET_SOCIAL_INSURANCE_PAYMENTS_COUNT,
		count,
	}
}

/**
 * Add an socialInsurancePayment to list
 *
 * @param {*} socialInsurancePayment
 * @returns
 */
function createSocialInsurancePayment(socialInsurancePayment) {
	return {
		type: ADD_SOCIAL_INSURANCE_PAYMENT,
		socialInsurancePayment,
	}
}

/**
 * Set flag to determine if is loading specific socialInsurancePayment data
 *
 * @param {*} isLoadingSocialInsurancePayment
 * @returns
 */
function isLoadingSocialInsurancePayment(isLoadingSocialInsurancePayment) {
	return {
		type: IS_LOADING_SOCIAL_INSURANCE_PAYMENT,
		isLoadingSocialInsurancePayment,
	}
}

/**
 * Set flag to determine if is saving specific socialInsurancePayment data
 *
 * @param {*} isSavingSocialInsurancePayment
 * @returns
 */
function isSavingSocialInsurancePayment(isSavingSocialInsurancePayment) {
	return {
		type: IS_SAVING_SOCIAL_INSURANCE_PAYMENT,
		isSavingSocialInsurancePayment,
	}
}

/**
 * Set socialInsurancePayment data in store
 *
 * @param {*} socialInsurancePayment
 * @returns
 */
function setSocialInsurancePayment(socialInsurancePayment) {
	return {
		type: SET_SOCIAL_INSURANCE_PAYMENT,
		socialInsurancePayment,
	}
}

/**
 * Add empployee in payment
 *
 * @param {*} employee
 * @returns
 */
function addEmployeeInListInPayment(employee) {
	return {
		type: ADD_EMPLOYEE_IN_PAYMENT,
		employee,
	}
}

/**
 * Add empployee in payment
 *
 * @param {*} employee
 * @returns
 */
function removeEmployeeInListInPayment(employeeToken) {
	return {
		type: REMOVE_EMPLOYEE_IN_PAYMENT,
		employeeToken,
	}
}

/**
 * Get socialInsurancePayments list from backend
 *
 * @export
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @returns
 */
export function loadSocialInsurancePaymentsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingSocialInsurancePayments(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let selectedSupplier = getState().suppliers.get('supplier')
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		if (supplierCode === undefined && selectedSupplier) {
			supplierCode = selectedSupplier.get('code')
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/socialinsurancepayment/all`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			SupplierCode: supplierCode,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSocialInsurancePaymentsList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingSocialInsurancePayments(false))
				console.error('Error getting the socialInsurancePayments by company list', error.response)
			})
	}
}

/**
 * Get SocialInsurancePayments list count
 *
 * @export
 * @returns
 */
export function getSocialInsurancePaymentsCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().contractorSocialInsurancePayments.get('socialInsurancePaymentsCount')
		if (actualCounter) {
			return
		}
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/socialinsurancepayment/count`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			supplierCode: supplierCode,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSocialInsurancePaymentsCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the socialInsurancePayment's count", error.response)
			})
	}
}

/**
 * Add new socialInsurancePayment to list
 *
 * @export
 * @param {*} socialInsurancePayment
 * @returns
 */
export function addSocialInsurancePayment(socialInsurancePayment, supplierName) {
	return (dispatch, getState) => {
		dispatch(isSavingSocialInsurancePayment(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUserFiles(user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierCode = undefined
		if (user.profile && user.profile.company_code) {
			supplierCode = user.profile.company_code
		}

		let formData = new FormData()
		socialInsurancePayment.file_sua.forEach((file) => {
			formData.append('FileSUA', file)
		})
		socialInsurancePayment.file_proof_payment.forEach((file) => {
			formData.append('FileProofPayment', file)
		})
		formData.set('CompanyId', selectedCompany.get('id'))
		formData.set('SupplierCode', supplierCode)
		formData.set('SupplierName', supplierName)
		formData.set('Description', socialInsurancePayment.payment_description)
		// formData.set('PaymentDateStart', socialInsurancePayment.date_start.format("DD/MM/YYYY"));
		// formData.set('PaymentDateEnd', socialInsurancePayment.date_end.format("DD/MM/YYYY"));
		formData.set('dateStartSua', moment(socialInsurancePayment.date_start_Sua,'MM YYYY').format('MM/YYYY'))
		formData.set('dateEndSua', moment(socialInsurancePayment.date_end_Sua,'MM YYYY').format('MM/YYYY'))
		formData.set('DocumentType', socialInsurancePayment.document_type)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/socialinsurancepayment`
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingSocialInsurancePayment(false))
					dispatch(createSocialInsurancePayment(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingSocialInsurancePayment(false))
				console.error('Error adding a socialInsurancePayment', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Get an specific SocialInsurancePayment data from backend
 *
 * @export
 * @param {*} token
 * @returns
 */
export function loadSocialInsurancePaymentFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingSocialInsurancePayment(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/socialinsurancepayment/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSocialInsurancePayment(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load social insurancePayment', error.response)
				dispatch(isLoadingSocialInsurancePayment(false))
				return Promise.reject()
			})
	}
}

/**
 * Update data of an SocialInsurancePayment
 *
 * @export
 * @param {*} socialInsurancePayment
 * @returns
 */
export function updateDataSocialInsurancePayment(socialInsurancePayment) {
	return function (dispatch, getState) {
		dispatch(isSavingSocialInsurancePayment(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let selectedCompany = getState().profile.get('selectedCompany')

		let formData = new FormData()
		socialInsurancePayment.file_sua.forEach((file) => {
			formData.append('FileSUA', file)
		})
		socialInsurancePayment.file_proof_payment.forEach((file) => {
			formData.append('FileProofPayment', file)
		})
		formData.set('PaymentToken', socialInsurancePayment.token)
		formData.set('Description', socialInsurancePayment.payment_description)
		formData.set('CompanyId', selectedCompany.get('id'))

		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/socialinsurancepayment/update`
		return axios
			.put(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingSocialInsurancePayment(true))
					dispatch(setSocialInsurancePayment(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error update data of an social insurance payment', error.response)
				dispatch(isSavingSocialInsurancePayment(false))
				return Promise.reject()
			})
	}
}

/**
 * Add an employee in social insurance payment
 *
 * @export
 * @param {*} employeeToken
 * @param {*} paymentToken
 * @returns
 */
export function addEmployeeInPayment(employeeToken, paymentToken, selectedCompanyToken) {
	return (dispatch, getState) => {
		dispatch(isSavingSocialInsurancePayment(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/socialinsurancepayment/addemployedinpayment/${paymentToken}/${employeeToken}/${selectedCompanyToken}`
		return axios
			.post(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					serverResponse.data.forEach((employeed) => {
						dispatch(addEmployeeInListInPayment(employeed))
					})
					dispatch(isSavingSocialInsurancePayment(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingSocialInsurancePayment(false))
				console.error('Error adding an employee in socialInsurancePayment', error.response)
				return Promise.reject(error.response)
			})
	}
}

/**
 * Remove an employee in social insurance payment
 *
 * @export
 * @param {*} employeeToken
 * @param {*} paymentToken
 * @returns
 */
export function removeEmployeeInPayment(employeeToken, paymentToken) {
	return (dispatch, getState) => {
		dispatch(isSavingSocialInsurancePayment(true))
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/socialinsurancepayment/remove/${paymentToken}/${employeeToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(removeEmployeeInListInPayment(employeeToken))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingSocialInsurancePayment(false))
				console.error('Error removing an employee in socialInsurancePayment', error.response)
				return Promise.reject()
			})
	}
}
