import React from 'react'
import classNames from 'classnames'
// Importaciones de Material-UI
import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const OpenQuestion = (props) => {
	const { question, classes, survey, canBeAddedResponse,supplierSurveyResponse } = props
	//Functions
	const { onAddSurveyAnswerFromBackEnd } = props
	let existSupplierAnswer=
		supplierSurveyResponse && supplierSurveyResponse.Answers.find((x) => x.QuestionId === question.Id)

	return (
		<div>
			<TextField
				label='Respuesta'
				fullWidth
				margin='dense'
				variant='outlined'
				className={classNames(classes.textField, classes.dense)}
				onBlur={(event) =>
					onAddSurveyAnswerFromBackEnd(survey ? survey.Id : '', question.Id, event.target.value)
				}
				disabled={!canBeAddedResponse}
				defaultValue={existSupplierAnswer ? existSupplierAnswer.OpenResponse:''}
				multiline
			/>
		</div>
	)
}

const styles = (theme) => ({
	emptyStateContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '32px',
		backgroundColor: '#fff',
		borderRadius: '8px',
		boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
		marginTop: '16px',
	},
	addSectionButton: {
		marginTop: '16px',
		textTransform: 'none',
		fontSize: '1rem',
	},
})

export default withStyles(styles)(OpenQuestion)
