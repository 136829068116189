///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import queryString from 'query-string'
/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AddIcon from '@material-ui/icons/AddTwoTone'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import BiddingIcon from '@material-ui/icons/ViewCarousel'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import ViewIcon from '@material-ui/icons/Visibility'
import Tabs from '@material-ui/core/Tabs'
import Tooltip from '@material-ui/core/Tooltip'
import Tab from '@material-ui/core/Tab'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import SearchInput from '../common/SearchInput/SearchInput'
import AddSupplierDriverBlockingPanel from './components/index/AddSupplierDriverBlockingPanel'

/**import actions section */
import {
	deleteSupplierDriverBlockingsFromBackEnd,
	loadSupplierDriverBlockingsPaginatedFromBackEnd,
	setSupplierDriverBlockingActiveFlag,
	downloadDriverBlockingReport,
} from '../../store/supplierDriverBlocking/supplierDriverBlockingActions'
/**import helper section */
import { converListToArray, toSafeObject } from '../../store/helpers/ListHelper'
import { ROLE_COMPANYADMINISTRATOR, IsUserInRole } from '../../store/helpers/RolesHelper'
import { FormControlLabel, IconButton, Switch } from '@material-ui/core'
import ConfirmDialog from './components/index/ConfirmDialog'
import DownloadSupplierBlockingReport from './components/index/DownloadSupplierBlockingReport'

const IndexView = (props) => {
	const { classes } = props
	/**Render hooks section */
	// const classes = styles()
	const dispatch = useDispatch()
	let location = useLocation()
	let history = useHistory()
	const queryParams = queryString.parse(location.search)

	/**Redux store information section*/
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)
	const isSaving = useSelector((state) => state.supplierDriverBlockings.get('isSaving'))

	const isLoading = useSelector((state) => state.supplierDriverBlockings.get('isLoading'))

	const supplierDriverBlockingsPaginated = useSelector((state) =>
		state.supplierDriverBlockings.get('supplierDriverBlockingsPaginated')
			? toSafeObject(state.supplierDriverBlockings.get('supplierDriverBlockingsPaginated'))
			: null
	)

	// const userProfileLogged = useSelector((state) => state.oidc.user)

	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	const [isOpenDownloadReportDialog, setIsOpenDownloadReportDialog] = useState(false)

	const [deleteSupplierBlockinConfigPanel, setDeleteSupplierBlockingConfigPanel] = useState({
		item: null,
		open: false,
	})
	const [activeTab, setActiveTab] = useState(1) // 0: Activos, 1: Inactivos
	const [isOpenSupplierVehicleBlockings, setIsOpenSupplierVehicleBlockings] = useState(false)

	const onOpenCloseDeleteDriverBlockingConfigPanel = (item) => {
		setDeleteSupplierBlockingConfigPanel({ item: item, open: !deleteSupplierBlockinConfigPanel.open })
	}
	const onOpenCloseSupplierVehicleBlockings = () => {
		setIsOpenSupplierVehicleBlockings(!isOpenSupplierVehicleBlockings)
	}

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue)
	}

	const onOpenDownloadReportDialog = () => {
		setIsOpenDownloadReportDialog(!isOpenDownloadReportDialog)
	}

	// /**Use effect section */
	useEffect(() => {
		dispatch(loadSupplierDriverBlockingsPaginatedFromBackEnd(activeTab))
	}, [dispatch])

	/**Use effect section */
	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		dispatch(loadSupplierDriverBlockingsPaginatedFromBackEnd(queryParams, activeTab))
	}, [dispatch, location.search, activeTab])

	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	const getCounterMessage = () => {
		if (supplierDriverBlockingsPaginated.items) {
			let totalBiddings = supplierDriverBlockingsPaginated.items.length
			return (
				<div>
					{<Translate id='common.showing' />}
					<span className={classes.showingCounter}>{totalBiddings}</span>&nbsp;
					{<Translate id='common.of' />}
					<span className={classes.showingCounter}>{supplierDriverBlockingsPaginated.total}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		if ((queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20) === rowsPerPage) {
			queryParams.pageNumber = page
		}
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onDeleteSupplierDriverBlockingFromBackEnd = () => {
		// if (event) event.stopPropagation()
		return dispatch(deleteSupplierDriverBlockingsFromBackEnd(deleteSupplierBlockinConfigPanel.item.id))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.removeCorrectly',
				})
				onOpenCloseDeleteDriverBlockingConfigPanel(null)
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onSetSupplierDriverBlockingActiveFlag = (id, isActive) => {
		// if (event) event.stopPropagation()
		return dispatch(setSupplierDriverBlockingActiveFlag(id, isActive))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onDownloadDriverBlockingReport = (allCodes, dateConfigurationModel, downloadType) => {
		// if (event) event.stopPropagation()
		return dispatch(
			downloadDriverBlockingReport(
				dateConfigurationModel.startDate,
				dateConfigurationModel.endDate,
				allCodes,
				downloadType
			)
		)
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * onSeeSurveyTemplate
	 */
	const onRedirectToDetail = (item) => {
		props.history.push(`/supplierDriverBlockings/${item.id}`)
	}
	// const onRowClick = (item, event) => {
	// 	let tagName = event.target.tagName
	// 	if (tagName !== 'svg' && tagName !== 'A') {
	// 		props.history.push(`/survey/${item.Id}`)
	// 	}
	// }

	// const onCreateSurveys = () => {
	// 	props.history.push(`/survey/create`)
	// }

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'reason') {
			return (
				<Typography
					style={{
						maxWidth: '200px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{item.reason}
				</Typography>
			)
		}

		if (dataSource === 'isActive') {
			return (
				<FormControlLabel
					control={
						<Switch
							checked={item.isActive}
							onChange={() => onSetSupplierDriverBlockingActiveFlag(item.id, !item.isActive)}
							disabled={isSaving}
							// value='checkedA'
						/>
					}
					label={item.isActive ? 'Desactivar' : 'Activar'}
				/>
			)
		}

		if (dataSource === 'Options') {
			return (
				<div className={classes.flex}>
					<IconButton
						onClick={() => {
							onOpenCloseDeleteDriverBlockingConfigPanel(item)
						}}
					>
						<DeleteIcon />
					</IconButton>
					<IconButton
						onClick={() => {
							onRedirectToDetail(item)
						}}
					>
						<ViewIcon />
					</IconButton>
				</div>
			)
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	const getCardContent = () => {
		if (isLoading === true) {
			return <LoadingPage />
		} else {
			return (
				<>
					<DataTable
						data={supplierDriverBlockingsPaginated ? supplierDriverBlockingsPaginated.items : []}
						configuration={TableConfiguration()}
						onRenderCellItem={onRenderCellItem}
						onChangeSortCriteria={onChangeSortCriteria}
						// onRowClick={onRowClick}
						isIndex
						showFilters
						totalRows={supplierDriverBlockingsPaginated.items.length}
						sortBy={queryParams && queryParams.sortBy ? queryParams.sortBy : 'FormatedFolio'}
						sortAscending={
							queryParams && queryParams.sortDirection
								? queryParams.sortDirection === 'desc'
									? false
									: true
								: false
						}
						page={queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0}
						rowsPerPage={queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20}
						onChangePage={(newPage, newRowsPerPage) => {
							onChangePage(newPage, newRowsPerPage)
						}}
					/>
				</>
			)
		}
	}

	return (
		<div>
			<CardHeader
				avatar={
					<Avatar>
						<BiddingIcon />
					</Avatar>
				}
				action={
					<div style={{ display: 'flex' }}>
						<div style={{ marginRight: '5px' }}>
							<SearchInput
								onChangeSearchValue={onChangeSearchValue}
								value={!!queryParams && queryParams.query ? queryParams.query : ''}
							/>
						</div>
						<div>
							{IsUserInRole(ROLE_COMPANYADMINISTRATOR, userRolesByCompany) && (
								<Fab size='small' color='primary' onClick={onOpenCloseSupplierVehicleBlockings}>
									<AddIcon />
								</Fab>
							)}

							{IsUserInRole(ROLE_COMPANYADMINISTRATOR, userRolesByCompany) && (
								<Tooltip title='Descargar reporte de bloqueos de proveedor'>
									<Fab size='small' color='primary' onClick={onOpenDownloadReportDialog}>
										<CloudDownloadIcon />
									</Fab>
								</Tooltip>
							)}
						</div>
					</div>
				}
				title={
					<div className={classes.flex}>
						<div>
							<Typography variant='button'>{<Translate id='supplierDriversBlocking.title' />}</Typography>
							{getCounterMessage()}
						</div>
						<Tabs
							value={activeTab}
							onChange={handleTabChange}
							indicatorColor='primary'
							textColor='primary'
							centered
						>
							<Tab
								label={
									<div className={classes.flex}>
										<CancelIcon />
										<Typography>Inactivos</Typography>
									</div>
								}
							/>
							<Tab
								label={
									<div className={classes.flex}>
										<CheckCircleIcon />
										<Typography>Activos</Typography>
									</div>
								}
							/>
						</Tabs>
					</div>
				}
				// subheader={}
			/>
			<CardContent>{getCardContent()}</CardContent>

			{isOpenSupplierVehicleBlockings && (
				<AddSupplierDriverBlockingPanel onCloseButtonClick={onOpenCloseSupplierVehicleBlockings} />
			)}

			{deleteSupplierBlockinConfigPanel.open && (
				<ConfirmDialog
					open={deleteSupplierBlockinConfigPanel.open}
					//bool props
					isSaving={isSaving}
					// open={deleteQuestionConfigPanel.open}
					//string props
					title={`Eliminar bloqueo del chófer`}
					message={`Estás seguro de que deseas eliminar el boqueo ${deleteSupplierBlockinConfigPanel.item.reason} del chofér: ${deleteSupplierBlockinConfigPanel.item.driverName}, esta acción no puede revertirse.`}
					//functions props
					onClose={onOpenCloseDeleteDriverBlockingConfigPanel}
					onConfirmOperation={onDeleteSupplierDriverBlockingFromBackEnd}
				/>
			)}
			<Toaster
				message={
					<Translate id={toasterMessageConfig.toasterMessage} data={{ name: toasterMessageConfig.name }} />
				}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
			{isOpenDownloadReportDialog && (
				<DownloadSupplierBlockingReport
					open={isOpenDownloadReportDialog}
					onDownloadDriverBlockingReport={onDownloadDriverBlockingReport}
					onClose={onOpenDownloadReportDialog}
					isSaving={isSaving}
				/>
			)}
		</div>
	)
}

function TableConfiguration() {
	let columns = [
		{
			header: <Translate id='biddingsModule.table.creationDate' />,
			dataSource: 'creationDate',
		},
		{
			header: <Translate id='supplierDriversBlocking.create.blockingDate' />,
			dataSource: 'blockingDate',
		},
		{
			header: <Translate id='factoringAffiliation.table.supplierCode' />,
			dataSource: 'supplierCode',
		},
		{
			header: <Translate id='factoringAffiliation.table.supplierName' />,
			dataSource: 'supplierName',
		},
		{
			header: <Translate id='supplierDriversBlocking.create.driverName' />,
			dataSource: 'driverName',
		},
		{
			header: <Translate id='supplierDriversBlocking.create.vehicleLicensePlate' />,
			dataSource: 'vehicleLicensePlate',
		},
		{
			header: <Translate id='supplierDriversBlocking.create.reason' />,
			dataSource: 'reason',
		},
		{
			header: 'Estatus',
			dataSource: 'isActive',
		},
		{
			header: 'Opciones',
			dataSource: 'Options',
		},
	]

	return { columns }
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	showingCounter: {
		marginLeft: '5px',
	},
	biddingContainer: {
		maxHeight: `calc(100vh - ${275}px)`,
		minHeight: `calc(100vh - ${275}px)`,
		overflowY: 'hidden',
		backgroundColor: 'red',
	},
	categoryIcon: {
		marginLeft: '5px',
	},
	flex: {
		display: 'flex',
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(IndexView)))
