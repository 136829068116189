//import react section
import React from 'react'
import PropTypes from 'prop-types'
//import mui section
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'

const SendSurveyResponseToReview = (props) => {
	const { open, onClose, onConfirmOperation, isSaving } = props
	return (
		<div>
			<Dialog
				// fullScreen={fullScreen}
				open={open}
				onClose={onClose}
				// aria-labelledby='responsive-dialog-title'
			>
				<DialogTitle id='responsive-dialog-title'>{'Enviar encuesta a revisión'}</DialogTitle>
				<DialogContent>
					<DialogContentText>{'¿Estás seguro de enviar tu encuesta a revisión?'}</DialogContentText>
					<DialogContentText>{'Una vez enviada la información no podrá revertirse los cambios'}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button olor='primary' onClick={onClose} disabled={isSaving}>
						Cancelar
					</Button>
					<Button color='primary' autoFocus onClick={onConfirmOperation} disabled={isSaving}>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

SendSurveyResponseToReview.propTypes = {
	onClose: PropTypes.func.isRequired,
	onConfirmOperation: PropTypes.func.isRequired,
}

export default withMobileDialog()(SendSurveyResponseToReview)
