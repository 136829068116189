///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import Toaster from '../common/Toaster'
/**Import actions section */

import {
	addSupplierDriverBlockingFiles,
	deleteSupplierDriverBlockingFiles,
	downloadSupplierDriverBlockingFile,
	getSupplierDriverBlockingByIdFromBackEnd,
} from '../../store/supplierDriverBlocking/supplierDriverBlockingActions'
import LoadingPage from '../common/LoadingPage'
import ShowDesktop from './components/show/ShowDesktop'
import EditSupplierDriverBlockingPanel from './components/show/EditSupplierDriverBlockingPanel'
// import UpdateDatesDialog from './show/UpdateDatesDialog'

const Show = (props) => {
	/**Render hooks section */
	const dispatch = useDispatch()

	/**Redux store information section*/

	const isLoading = useSelector((state) => state.supplierDriverBlockings.get('isLoading'))
	const isSaving = useSelector((state) => state.supplierDriverBlockings.get('isSaving'))

	/**Redux store information section*/
	const supplierDriverBlocking = useSelector((state) =>
		state.supplierDriverBlockings.get('supplierDriverBlocking')
			? state.supplierDriverBlockings.get('supplierDriverBlocking').toJS()
			: null
	)
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	const [editSupplierVehicleBlockingConfigPanel, setEditSupplierVehicleBlockingConfigPanel] = useState(false)

	/**Use effect section */
	useEffect(() => {
		dispatch(getSupplierDriverBlockingByIdFromBackEnd(props.match.params.id))
	}, [dispatch, props.match.params.id])

	if (isLoading) return <LoadingPage />

	const onOpenCloseSupplierVehicleBlocking = () => {
		setEditSupplierVehicleBlockingConfigPanel(!editSupplierVehicleBlockingConfigPanel)
	}

	const onAddSupplierDriverBlockingFile = (attachments) => {
		return dispatch(addSupplierDriverBlockingFiles(props.match.params.id, attachments))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onDeleteSupplierDriverBlockingsFiles = (fileName) => {
		return dispatch(deleteSupplierDriverBlockingFiles(props.match.params.id, fileName))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onDownloadSupplierDriverBlockingFile = (fileName) => {
		return dispatch(downloadSupplierDriverBlockingFile(props.match.params.id, fileName))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	return (
		<>
			<ShowDesktop
				// //objects
				supplierDriverBlocking={supplierDriverBlocking}
				// //functions
				onOpenCloseSupplierVehicleBlocking={onOpenCloseSupplierVehicleBlocking}
                onAddSupplierDriverBlockingFile={onAddSupplierDriverBlockingFile}
                onDeleteSupplierDriverBlockingsFiles={onDeleteSupplierDriverBlockingsFiles}
                onDownloadSupplierDriverBlockingFile={onDownloadSupplierDriverBlockingFile}
				// //bools
				isSaving={isSaving}
			/>
			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>

			{editSupplierVehicleBlockingConfigPanel && (
				<EditSupplierDriverBlockingPanel
					item={supplierDriverBlocking}
					onCloseButtonClick={onOpenCloseSupplierVehicleBlocking}
				/>
			)}
		</>
	)
}

const styles = (theme) => ({})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Show)))
