///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import Toaster from '../common/Toaster'
import CreateSurveySectionPanel from './components/show/AddSurveySectionPanel'
import {
	getSurveyTemplateByIdFromBackEnd,
	deleteSurveySectionFromBackEnd,
	deleteQuestionFromBackEnd,
	deleteOptionFromBackEnd,
	deleteSurveyTemplateByIdFromBackEnd,
	updateQuestionOrderFromBackEnd,
	updateSurveySectionOrderFromBackEnd,
} from '../../store/surveyTemplates/SurveyTemplatesActions'
/**Import actions section */

import ShowDesktopView from './components/show/ShowDesktop'
import AddQuestionPanel from './components/show/AddQuestionPanel'
import AddOptionPanel from './components/show/AddOptionPanel'
import EditSurveySectionPanel from './components/show/EditSurveySectionPanel'
import ConfirmDialog from './components/show/ConfirmDialog'
import EditQuestionPanel from './components/show/EditQuestionPanel'
import EditOptionPanel from './components/show/EditOptionPanel'
import EditSurveyTemplatePanel from './components/show/EditSurveyTemplatePanel'

const Show = (props) => {
	/**Render hooks section */
	const dispatch = useDispatch()

	/**Redux store information section*/
	const surveyTemplate = useSelector((state) =>
		state.surveyTemplates.get('surveyTemplate') ? state.surveyTemplates.get('surveyTemplate').toJS() : null
	)
	const isLoading = useSelector((state) => state.surveyTemplates.get('isLoading'))
	const isSaving = useSelector((state) => state.surveyTemplates.get('isSaving'))

	console.log('surveyTemplate', surveyTemplate)
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	const [surverSectionConfigPanel, setSurverSectionConfigPanel] = useState(false)
	const [editSurverTemplateConfigPanel, setEditSurverTemplateConfigPanel] = useState(false)
	const [editSurverSectionConfigPanel, setEditSurverSectionConfigPanel] = useState({ opened: false, item: null })
	const [questionConfigPanel, setQuestionConfigPanel] = useState({ item: null, open: false })
	const [editQuestionConfigPanel, setEditQuestionConfigPanel] = useState({ item: null, open: false })
	const [deleteQuestionConfigPanel, setDeleteQuestionConfigPanel] = useState({ item: null, open: false })
	const [deleteOptionConfigPanel, setDeleteOptionConfigPanel] = useState({ item: null, open: false })
	const [deleteSurveyTemplateConfigPanel, setDeleteSurveyTemplateConfigPanel] = useState(false)
	const [questionOptionConfigPanel, setQuestionOptionConfigPanel] = useState({ item: null, open: false })
	const [editQuestionOptionConfigPanel, setEditQuestionOptionConfigPanel] = useState({ item: null, open: false })
	const [deleteSurverSectionConfigPanel, setDeleteSurverSectionConfigPanel] = useState({ open: false, item: null })

	const onOpenCloseEditSurveyTemplateConfigPanel = () => {
		setEditSurverTemplateConfigPanel(!editSurverTemplateConfigPanel)
	}

	const onOpenCloseDeleteSurveyTemplateConfigPanel = () => {
		setDeleteSurveyTemplateConfigPanel(!deleteSurveyTemplateConfigPanel)
	}

	const onOpenCloseEditOptionConfigPanel = (item) => {
		setEditQuestionOptionConfigPanel({ item: item, open: !editQuestionOptionConfigPanel.open })
	}

	const onOpenCloseDeleteOptionConfigPanel = (item) => {
		setDeleteOptionConfigPanel({ item: item, open: !deleteOptionConfigPanel.open })
	}

	const onOpenCloseDeleteQuestionConfigPanel = (item) => {
		setDeleteQuestionConfigPanel({ item: item, open: !deleteQuestionConfigPanel.open })
	}

	const onOpenCloseEditQuestionConfigPanel = (item) => {
		setEditQuestionConfigPanel({ item: item, open: !editQuestionConfigPanel.open })
	}

	const onOpenCloseDeleteSurverSectionConfigPanel = (item) => {
		setDeleteSurverSectionConfigPanel({ item: item, open: !deleteSurverSectionConfigPanel.open })
	}

	const onOpenCloseQuestionOptionConfigPanel = (item) => {
		setQuestionOptionConfigPanel({ item: item, open: !questionOptionConfigPanel.open })
	}

	const onOpenCloseSurverSectionConfigPanel = () => {
		setSurverSectionConfigPanel(!surverSectionConfigPanel)
	}

	const onOpenCloseEditSurverSectionConfigPanel = (item) => {
		setEditSurverSectionConfigPanel({
			...editSurverSectionConfigPanel,
			opened: !editSurverSectionConfigPanel.opened,
			item: item,
		})
	}

	const onOpenCloseQuestionConfigPanel = (item) => {
		setQuestionConfigPanel({ item: item, open: !questionConfigPanel.open })
	}

	/**Use effect section */
	useEffect(() => {
		dispatch(getSurveyTemplateByIdFromBackEnd(props.match.params.id))
	}, [dispatch, props.match.params.id])

	const onDeletedQuestionFromBackEnd = () => {
		// if (event) event.stopPropagation()
		return dispatch(deleteQuestionFromBackEnd(deleteQuestionConfigPanel.item.Id))
			.then(() => {
				dispatch(getSurveyTemplateByIdFromBackEnd(props.match.params.id, false))
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.removeCorrectly',
				})
				onOpenCloseDeleteQuestionConfigPanel(null)
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onDeletedSurveyTemplateFromBackEnd = () => {
		// if (event) event.stopPropagation()
		return dispatch(deleteSurveyTemplateByIdFromBackEnd(props.match.params.id))
			.then(() => {
				props.history.push(`/surveyTemplate`)
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onDeleteSurveySectionFromBackEnd = () => {
		return dispatch(deleteSurveySectionFromBackEnd(deleteSurverSectionConfigPanel.item.Id))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.removeCorrectly',
				})
				onOpenCloseDeleteSurverSectionConfigPanel(null)
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onDeleteOptionFromBackEnd = () => {
		return dispatch(deleteOptionFromBackEnd(deleteOptionConfigPanel.item.Id))
			.then(() => {
				dispatch(getSurveyTemplateByIdFromBackEnd(props.match.params.id, false))

				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.removeCorrectly',
				})
				onOpenCloseDeleteOptionConfigPanel(null)
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onUpdateOrderIndexCommon = (event) => {
		console.log('event=>>>>>>>>>>>>>>>>>>>>>>>>>>>', event)
		let destinationIndex = event.destination.index
		let dragableId = event.draggableId
		if (event.type === 'sections') {
			return dispatch(updateSurveySectionOrderFromBackEnd(props.match.params.id, dragableId, destinationIndex))
				.then(() => {
					dispatch(getSurveyTemplateByIdFromBackEnd(props.match.params.id, false))

					setToasterMessageConfig({
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: 'common.removeCorrectly',
					})
				})
				.catch(() => {
					setToasterMessageConfig({
						showToaster: true,
						toasterMessage: 'common.error',
						toasterVariant: 'error',
					})
				})
		} else {
			let dropableId = event.destination.droppableId

			return dispatch(updateQuestionOrderFromBackEnd(dropableId, dragableId, destinationIndex))
				.then(() => {
					dispatch(getSurveyTemplateByIdFromBackEnd(props.match.params.id, false))

					setToasterMessageConfig({
						showToaster: true,
						toasterVariant: 'success',
						toasterMessage: 'common.removeCorrectly',
					})
				})
				.catch(() => {
					setToasterMessageConfig({
						showToaster: true,
						toasterMessage: 'common.error',
						toasterVariant: 'error',
					})
				})
		}
	}

	return (
		<>
			<ShowDesktopView
				//object
				surveyTemplate={surveyTemplate}
				//bools
				isLoading={isLoading}
				canBeEditData={true}
				canBeAddedResponse={false}
				isDndEnabled={true}
				//fucntions
				onOpenCloseSurverSectionConfigPanel={onOpenCloseSurverSectionConfigPanel}
				onOpenCloseQuestionConfigPanel={onOpenCloseQuestionConfigPanel}
				onOpenCloseQuestionOptionConfigPanel={onOpenCloseQuestionOptionConfigPanel}
				onOpenCloseEditSurverSectionConfigPanel={onOpenCloseEditSurverSectionConfigPanel}
				onOpenCloseDeleteSurverSectionConfigPanel={onOpenCloseDeleteSurverSectionConfigPanel}
				onOpenCloseEditQuestionConfigPanel={onOpenCloseEditQuestionConfigPanel}
				onOpenCloseDeleteQuestionConfigPanel={onOpenCloseDeleteQuestionConfigPanel}
				onOpenCloseDeleteOptionConfigPanel={onOpenCloseDeleteOptionConfigPanel}
				onOpenCloseEditOptionConfigPanel={onOpenCloseEditOptionConfigPanel}
				onOpenCloseDeleteSurveyTemplateConfigPanel={onOpenCloseDeleteSurveyTemplateConfigPanel}
				onOpenCloseEditSurveyTemplateConfigPanel={onOpenCloseEditSurveyTemplateConfigPanel}
				onUpdateOrderIndexCommon={onUpdateOrderIndexCommon}
			/>
			{surverSectionConfigPanel && (
				<CreateSurveySectionPanel
					//bool
					isSaving={isSaving}
					//object
					surveyTemplate={surveyTemplate}
					//Functions
					onCloseButtonClick={onOpenCloseSurverSectionConfigPanel}
					setToasterMessageConfig={setToasterMessageConfig}
				/>
			)}
			{editSurverSectionConfigPanel && editSurverSectionConfigPanel.opened && (
				<EditSurveySectionPanel
					{...editSurverSectionConfigPanel}
					//bool
					isSaving={isSaving}
					//object
					surveyTemplate={surveyTemplate}
					//Functions
					onCloseButtonClick={onOpenCloseEditSurverSectionConfigPanel}
					setToasterMessageConfig={setToasterMessageConfig}
				/>
			)}
			{questionConfigPanel.open && (
				<AddQuestionPanel
					//bools
					isSaving={isSaving}
					//object
					surveyTemplate={surveyTemplate}
					{...questionConfigPanel}
					//functions
					onCloseButtonClick={onOpenCloseQuestionConfigPanel}
					setToasterMessageConfig={setToasterMessageConfig}
				/>
			)}
			{editQuestionConfigPanel.open && (
				<EditQuestionPanel
					//bools
					isSaving={isSaving}
					//object
					surveyTemplate={surveyTemplate}
					{...editQuestionConfigPanel}
					//functions
					onCloseButtonClick={onOpenCloseEditQuestionConfigPanel}
					setToasterMessageConfig={setToasterMessageConfig}
				/>
			)}
			{questionOptionConfigPanel.open && (
				<AddOptionPanel
					//bool
					isSaving={isSaving}
					//object
					{...questionOptionConfigPanel}
					surveyTemplate={surveyTemplate}
					//function
					setToasterMessageConfig={setToasterMessageConfig}
					onCloseButtonClick={onOpenCloseQuestionOptionConfigPanel}
				/>
			)}
			{editQuestionOptionConfigPanel.open && (
				<EditOptionPanel
					//bool
					isSaving={isSaving}
					//object
					{...editQuestionOptionConfigPanel}
					surveyTemplate={surveyTemplate}
					//function
					setToasterMessageConfig={setToasterMessageConfig}
					onCloseButtonClick={onOpenCloseEditOptionConfigPanel}
				/>
			)}
			{editSurverTemplateConfigPanel && (
				<EditSurveyTemplatePanel
					onCloseButtonClick={onOpenCloseEditSurveyTemplateConfigPanel}
					setToasterMessageConfig={setToasterMessageConfig}
					isSaving={isSaving}
					surveyTemplate={surveyTemplate}
				/>
			)}
			{deleteSurverSectionConfigPanel.open && (
				<ConfirmDialog
					//bool props
					open={deleteSurverSectionConfigPanel.open}
					isSaving={isSaving}
					//strings
					title={`Eliminar sección`}
					message={`Estás seguro de que deseas eliminar la sección: ${deleteSurverSectionConfigPanel.item.Title}, se eliminarán preguntas y opciones, esta acción no puede revertirse.`}
					//functions
					onClose={onOpenCloseDeleteSurverSectionConfigPanel}
					onConfirmOperation={onDeleteSurveySectionFromBackEnd}
				/>
			)}
			{deleteQuestionConfigPanel.open && (
				<ConfirmDialog
					//bool props
					isSaving={isSaving}
					open={deleteQuestionConfigPanel.open}
					//string props
					title={`Eliminar pregunta`}
					message={`Estás seguro de que deseas eliminar la pregunta: ${deleteQuestionConfigPanel.item.Title}, se eliminarán opciones, esta acción no puede revertirse.`}
					//functions props
					onClose={onOpenCloseDeleteQuestionConfigPanel}
					onConfirmOperation={onDeletedQuestionFromBackEnd}
				/>
			)}
			{deleteOptionConfigPanel.open && (
				<ConfirmDialog
					//bool props
					isSaving={isSaving}
					open={deleteOptionConfigPanel.open}
					//string props
					title={`Eliminar opción`}
					message={`Estás seguro de que deseas eliminar la opción: ${deleteOptionConfigPanel.item.Title}, esta acción no puede revertirse.`}
					//functions props
					onClose={onOpenCloseDeleteOptionConfigPanel}
					onConfirmOperation={onDeleteOptionFromBackEnd}
				/>
			)}
			{deleteSurveyTemplateConfigPanel && surveyTemplate && (
				<ConfirmDialog
					//bool props
					isSaving={isSaving}
					open={deleteSurveyTemplateConfigPanel}
					//string props
					title={`Eliminar plantilla`}
					message={`Estás seguro de que deseas eliminar la plantilla: ${surveyTemplate.Title}, se eliminarán secciones, preguntas y opciones. Esta acción no puede revertirse.`}
					//functions props
					onClose={onOpenCloseDeleteSurveyTemplateConfigPanel}
					onConfirmOperation={onDeletedSurveyTemplateFromBackEnd}
				/>
			)}
			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</>
	)
}

const styles = (theme) => ({})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Show)))
