import autoBind from 'auto-bind'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Drawer from '@material-ui/core/Drawer'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import QuotesIcon from '@material-ui/icons/AttachMoneyTwoTone'
import RatesIcon from '@material-ui/icons/BarChartTwoTone'
import SACRequestIcon from '@material-ui/icons/BugReportTwoTone'
import TrazabilityIcon from '@material-ui/icons/CallSplitTwoTone'
import MemosIcon from '@material-ui/icons/FileCopyTwoTone'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import SupplierDevelopmentIcon from '@material-ui/icons/SupervisedUserCircleTwoTone'
import AlternativeSupplierIcon from '@material-ui/icons/SwapCallsTwoTone'
import NoticesOfChangeIcon from '@material-ui/icons/SwapHorizTwoTone'
import SamplesIcon from '@material-ui/icons/UnarchiveTwoTone'

import ReworkIcon from '@material-ui/icons/AutorenewOutlined'
import RawMaterialNonConfIcon from '@material-ui/icons/BugReport'
import WarehouseRawMaterialIcon from '@material-ui/icons/FormatAlignJustify'
import NonConformityIcon from '@material-ui/icons/LibraryBooks'
import QualityUsersIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import DevolutionIcon from '@material-ui/icons/ReplyAllOutlined'
import SacsQualityIcon from '@material-ui/icons/Report'
import QualityAssuranceIcon from '@material-ui/icons/ThumbUpAltTwoTone'
import WarrantyQualityIcon from '@material-ui/icons/VerticalSplit'
import QualityFailureReportIcon from '@material-ui/icons/WarningOutlined'

import DestinationIcon from '@material-ui/icons/AirportShuttle'
import CompaniesIcon from '@material-ui/icons/BusinessTwoTone'
import NoticesIcon from '@material-ui/icons/ChatTwoTone'
import DownloadUploadIcon from '@material-ui/icons/CloudUpload'
import PhoneDirectorycon from '@material-ui/icons/ContactPhone'
import WarehouseIcon from '@material-ui/icons/DeveloperBoard'
import ExpedientsIcon from '@material-ui/icons/FormatListNumbered'
import EthicCodeIcon from '@material-ui/icons/ListAlt'
import ProspectSupplierIncon from '@material-ui/icons/LocalShipping'
import UsersManagementIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import ReleaseClockIcon from '@material-ui/icons/Restore'
import AdministrationIcon from '@material-ui/icons/SettingsTwoTone'
import SuppliersIcon from '@material-ui/icons/ShoppingCartTwoTone'
import WarehouseWindowsIcon from '@material-ui/icons/Store'
import ContactsIcon from '@material-ui/icons/SupervisedUserCircle'

import GuaranteeIcon from '@material-ui/icons/DoneAll'
import GuaranteeReportIcon from '@material-ui/icons/FileCopyOutlined'
import CustomerGuaranteeIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import FailureGuarrantee from '@material-ui/icons/ThumbDown'

import BusinessIcon from '@material-ui/icons/BusinessTwoTone'
import PurchaseIcon from '@material-ui/icons/CalendarTodayOutlined'
import TutorialIcon from '@material-ui/icons/LibraryBooks'
import ShippingIcon from '@material-ui/icons/ShoppingCart'

import Avatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ContractIcon from '@material-ui/icons/ImportContacts'
import TemplatesIcon from '@material-ui/icons/LibraryBooks'
import { default as QuestionnaireIcon, default as SustainabilityIcon } from '@material-ui/icons/NaturePeople'
import PersonIcon from '@material-ui/icons/Person'

import { Typography } from '@material-ui/core'
import ServicesProjectsIcon from '@material-ui/icons/Build'
import CloseRegistersIcon from '@material-ui/icons/ClosedCaptionOutlined'
import MaquilaIcon from '@material-ui/icons/Description'
import DocumentsIcon from '@material-ui/icons/DoneAll'
import FindInPage from '@material-ui/icons/FindInPage'
import AccreditedWorkShopsIcon from '@material-ui/icons/HowToReg'
import BillingsIcon from '@material-ui/icons/LocalAtm'
import IndirectQualificationIcon from '@material-ui/icons/RateReviewTwoTone'
import MedicalChecksIcon from '@material-ui/icons/SpellcheckOutlined'
import ContractorsIcon from '@material-ui/icons/SupervisedUserCircle'
import BiddingIcon from '@material-ui/icons/ViewCarousel'
// import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import BallotIcon from '@material-ui/icons/Ballot'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'
import HomeIcon from '@material-ui/icons/Home'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { Translate, withLocalize } from 'react-localize-redux'

/** Section to resources */
import {
	Assignment,
	AttachMoney,
	CreditCard,
	CreditCardOutlined,
	Description,
	DoneAll,
	Group,
	Notifications,
	Poll,
	School,
	Security,
	ViewList,
} from '@material-ui/icons'
import FactoringAfiliantionIcon from '@material-ui/icons/Money'
import { converListToArray } from '../../store/helpers/ListHelper'
import {
	COMPANY_IDENTIFIER_FERSA,
	COMPANY_IDENTIFIER_IMBERA_BRASIL,
	COMPANY_IDENTIFIER_IMBERA_COLOMBIA,
	COMPANY_IDENTIFIER_IMBERA_MX,
	COMPANY_IDENTIFIER_IMBERA_REPARE,
	COMPANY_IDENTIFIER__METALLIC,
	COMPANY_PTM,
	ENVIROMENT_TYPE_PRODUCTION,
} from '../../store/helpers/ResourcesHelper'
import * as roles from '../../store/helpers/RolesHelper'
import {
	canBeAccessModuleFactoringAffiliation,
	canBeModuleBidding,
	canBeModuleConsignmentNote,
} from '../../store/helpers/RolesHelper'
import HeaderLogosCompany from './HeaderLogosCompany'
/**
 * Lateral menu for internal user
 *
 * @class LateralMenuInternalUser
 * @extends {Component}
 */
class LateralMenuInternalUser extends Component {
	/**
	 *Creates an instance of LateralMenuInternalUser.
	 * @param {*} props
	 * @memberof LateralMenuInternalUser
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuInitialSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/':
				selectedOption = '/'
				break
			case '/officialnotices':
			case '/officialnotices/create':
			case '/officialnotices/:token':
				selectedOption = 'officialnotices'
				break
			case '/shippingorders':
			case '/shippingorders/:token':
				selectedOption = 'shippingorders'
				break
			case '/warehouseRawMaterials':
			case '/warehouseRawMaterials/create':
			case '/warehouseRawMaterials/:token':
				selectedOption = 'warehouseRawMaterials'
				break
			case '/deliveryQualifications':
			case '/deliveryQualifications/create':
			case '/deliveryQualifications/:token':
				selectedOption = 'deliveryQualifications'
				break
			case '/planning/deliveryorder':
			case '/planning/deliveryorder/:token':
			case '/deliveryorder/registerarrival':
				selectedOption = 'planning/deliveryorder'
				break
			case '/sustainability':
			case '/sustainability/create':
			case '/sustainability/:token':
			case '/sustainability/cancel/:token':
				selectedOption = 'sustainability'
				break
			case '/questionnaire':
			case '/questionnaire/create':
			case '/questionnaire/calendar':
			case '/questionnaire/:token':
			case '/questionnaire/cancel/:token':
				selectedOption = 'questionnaire'
				break
			case '/manualsportal':
				selectedOption = 'manualsportal'
				break
			case '/expedientsupplier':
			case '/expedientsupplier/create':
			case '/expedientsupplier/:token':
			case '/expedientsbysupplier/:token':
				selectedOption = 'expedientsupplier'
				break
			case '/accreditedWorkshops':
			case '/accreditedWorkshops/:token':
				selectedOption = 'accreditedWorkshops'
				break
			case '/biddings':
			case '/biddings/base/create/:token':
			case '/biddings/:token':
				selectedOption = 'biddings'
				break
			case '/supplierDriverBlockings':
			case '/supplierDriverBlockings/:id':
				selectedOption = 'supplierDriverBlockings'
				break
			case '/maquilaOrders':
			case '/maquilaOrders/:token':
				selectedOption = 'maquilaOrders'
				break
			case '/warehouseWindows':
				selectedOption = 'warehouseWindows'
				break
			case '/controlCO2':
			case '/controlCO2/:activeTab':
				selectedOption = 'controlCO2'
				break
			case '/qualityCertificate':
			case '/qualityCertificate/:token':
				selectedOption = 'qualityCertificate'
				break
			case '/sustainabilityv2':
			case '/sustainabilityv2/:token':
			case '/sustainabilityv2/template':
			case '/sustainabilityv2/calendar':
				selectedOption = 'sustainabilityv2'
				break
			case '/billings':
			case '/billing/:token':
				selectedOption = 'billings'
				break
			case '/consignmentNote':
			case '/consignmentNote/:token':
				selectedOption = 'consignmentNote'
				selectedOption = 'consignmentNote'
				break
			default:
				break
			case '/planning':
			case '/deliveryOrderFersa/registerarrival':
			case '/planning/deliveryOrderFersa/:token':
				selectedOption = 'planning'
				break
			case '/ethiccode':
			case '/ethiccode/:token':
			case '/ethiccode/create':
				selectedOption = 'ethiccode'
				break
			case '/phoneDirectory':
			case '/phoneDirectory/:token/:activeTab':
				selectedOption = 'phoneDirectory'
				break
			case '/factoringaffiliation':
			case '/factoringaffiliation/:token':
				selectedOption = 'factoringaffiliation'
				break
			case '/securityCourses':
			case '/securityCourses/:token':
				selectedOption = 'securityCourses'
				break
			case '/all/certifications':
				selectedOption = 'all/certifications'
				break
			case '/ferrys':
				selectedOption = 'ferrys'
				break
		}
		return menuOption === selectedOption
	}

	/**
	 * Function is active module administration
	 */
	isActiveAdminsitration() {
		switch (this.props.match.path) {
			case '/companies':
			case '/viewcompany/:token':
			case '/companies/:company_token':
			case '/internalusers':
			case '/internalusers/:token':
			case '/internalusers/profile/:token':
			case '/suppliers':
			case '/suppliers/:token':
			case '/suppliers/ratings/:token':
			case '/warehouseworkers':
			case '/destinations':
			case '/releaseplan/upload':
			case '/releasecalendar':
			case '/releasecalendar/create':
			case '/releasecalendar/:token':
			case '/releasecalendarFersa':
			case '/releasecalendarFersa/create':
			case '/releasecalendarFersa/:token':
			case '/contactsupplier':
			case '/contactsupplier/:token':
			case '/contactsupplier/create':
			case '/expedientsupplier':
			case '/expedientsupplier/create':
			case '/expedientsupplier/:token':
			case '/expedientsbysupplier/:token':
			case '/templates':
			case '/templates/:token':
			case '/prospects':
			case '/prospects/:token':
			case '/prospects/create':
				return true
			default:
				return false
		}
	}

	/**
	 * Function is active module quality
	 */
	isActiveQuality() {
		switch (this.props.match.path) {
			case '/rawmaterialnonconformity':
			case '/rawmaterialnonconformity/create':
			case '/rawmaterialnonconformity/:token':
			case '/rawmaterialnonconformity/edit/:token':
			case '/rawmaterialnonconformity/cancel/:token':
			case '/rawmaterialnonconformity/sendNotification/:token':
			case '/rawmaterialnonconformity/registerDeviation/:token':
			case '/rawmaterialnonconformity/createdevolution/:token':
			case '/rawmaterialnonconformity/createrework/:token':
			case '/rawmaterialnonconformity/createfromDelivery/:token/:itemToken':
			case '/qualityfailurereport':
			case '/qualityfailurereport/create':
			case '/qualityfailurereport/:token':
			case '/qualityfailurereport/edit/:token':
			case '/qualityfailurereport/cancel/:token':
			case '/qualityfailurereport/sendNotification/:token':
			case '/qualityfailurereport/registerDeviation/:token':
			case '/qualityfailurereport/createdevolution/:token':
			case '/qualityfailurereport/createrework/:token':
			case '/reworks':
			case '/reworks/create':
			case '/reworks/:token':
			case '/reworks/reworkreport/:token':
			case '/reworks/createdevolution/:token':
			case '/sacsquality':
			case '/sacsquality/create':
			case '/sacsquality/:token':
			case '/sacsquality/cancel/:token':
			case '/sacsquality/sendNotification/:token':
			case '/sacsquality/createActionsBySupplier/:token':
			case '/devolutions':
			case '/devolutions/create':
			case '/devolutions/:token':
			case '/devolutions/cancel/:token':
			case '/nonconformities':
			case '/managequalityworkers':
			case '/warrantyReportsPtm':
			case '/warrantyReportsPtm/create':
			case '/warrantyReportsPtm/:token':
			case '/warrantyReportsPtm/cancel/:token':
			case '/qualityaudit':
			case '/qualityaudit/:token':
			case '/qualifications':
			case '/qualityCertificate':
			case '/qualityCertificate/:token':
			case '/specifications/guidelines':
			case '/specifications/guidelines/:token':
			case '/specifications/package':
			case '/specifications/package/:token':
				return true
			default:
				break
		}
	}

	/**
	 * Funtion is active module development
	 * @param {*} isActiveDevelopment
	 */
	isActiveDevelopment() {
		switch (this.props.match.path) {
			case '/memos':
			case '/memos/create':
			case '/memos/:token':
			case '/noticechanges':
			case '/noticechanges/create':
			case '/noticechanges/:token':
			case '/noticechanges/notification/:token':
			case '/noticechanges/cancel/:token':
			case '/alternativeprovedure':
			case '/alternativeprovedure/create':
			case '/alternativeprovedure/:token':
			case '/materialdelivery':
			case '/materialdelivery/create':
			case '/materialdelivery/:token':
			case '/quotes':
			case '/quotes/:token':
			case '/quotes/cancel/:token':
			case '/samplerequests':
			case '/samplerequests/:token':
			case '/samplerequests/cancel/:token':
			case '/partnumbers':
			case '/ratings/import':
			case '/ratings/generate':
			case '/ratings/generatingPTM':
			case '/ratings/generatingFERSA':
			case '/correctiveactions':
			case '/correctiveactions/create':
			case '/correctiveactions/:token':
			case '/correctiveactions/cancel/:token':
			case '/indirectqualifications':
			case '/indirectqualifications/:token':
			case '/indirectqualifications/actionsPlant/:token/:supplierId':
			case '/priceChangeRequest':
			case '/priceChangeRequest/create':
			case '/priceChangeRequest/:token':
			case '/sparePartPriceChange':
			case '/sparePartPriceChange/:activeTab':
			case '/sparePartPriceChange/show/:token':
				return true
			default:
				return false
		}
	}

	isActiveImports() {
		switch (this.props.match.path) {
			case '/importRequests':
			case '/importRequests/:token':
			case '/loadAlerts':
			case '/loadAlerts/:token':
				return true
			default:
				return false
		}
	}

	isActiveExports() {
		switch (this.props.match.path) {
			case '/exportRequests':
			case '/exportRequests/:token':
				return true
			default:
				return false
		}
	}

	isDistributionTransport() {
		switch (this.props.match.path) {
			case '/transportTenderShow':
			case '/transportTenderShow/:token':
			case '/transportTenderConfiguration':
			case '/transportZone':
			case '/transportTender/catalog/typesTransport':
			case '/transportTender/catalog/wineCellar':
			case '/transportTender/catalog/availableSchedules':
			case '/transportTender/catalog/carrierTypes':
			case '/transportTender/catalog/requirementsCarriers':
			case '/transportTender/catalog/transportZones':
			case '/transportTender/catalog/rates':
			case '/transportTender/catalog/clients':
				return true
			default:
				return false
		}
	}

	/**
	 * Funtion is active module customer guarantee
	 * @param {*} isActiveDevelopment
	 */
	isActiveCustomerGuarantee() {
		switch (this.props.match.path) {
			case '/customerguarantee':
			case '/failureguarantee':
			case '/guaranteereport':
			case '/guaranteereport/create':
			case '/guaranteereport/:token':
			case '/guaranteereport/edit/:token':
			case '/guaranteereport/sendnotification/:token':
			case '/guaranteereport/cancel/:token':
				return true
			default:
				return false
		}
	}

	isActiveAccessContractor() {
		switch (this.props.match.path) {
			case '/accesscontrol/upload':
			case '/servicesprojects/':
			case '/servicesprojects/:token':
			case '/medicalchecks/':
			case '/medicalchecks/:token':
			case '/trainings/':
			case '/trainings/:token':
				return true
			default:
				return false
		}
	}

	isActiveDocumentsPtm() {
		switch (this.props.match.path) {
			case '/contracts':
			case '/contracts/create':
			case '/contracts/request':
			case '/contracts/:token':
			case '/documents':
			case '/documents/create':
			case '/documents/:token':
				return true
			default:
				return false
		}
	}

	isActiveSurvey() {
		switch (this.props.match.path) {
			case '/survey':
			case '/survey/:token':
			case '/surveyTemplate':
			case '/surveyTemplate/:token':
				return true
			default:
				return false
		}
	}

	/**
	 * option to get avatar user
	 * @param {*} user
	 */
	getAvater(user) {
		const { classes } = this.props
		if (user.avatar_url) {
			let urlImage = `${process.env.REACT_APP_IDENTITY_SERVER}/${user.avatar_url}`
			return <Avatar src={urlImage} className={classes.avatar} data-cy='user-avatar' />
		} else {
			return (
				<Avatar data-cy='user-avatar' className={classes.avatar}>
					<PersonIcon />
				</Avatar>
			)
		}
	}

	/**
	 * Render component
	 *
	 * @returns
	 * @memberof LateralMenuInternalUser
	 */
	render() {
		const { classes, open, user, selectedCompany } = this.props
		return (
			<Drawer
				variant='permanent'
				className={classnames(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: classnames({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
				open={open}
			>
				<div
					className={classes.toolbar}
					style={{ padding: '0px', cursor: 'pointer' }}
					onClick={this.props.onClose}
				>
					<HeaderLogosCompany
						companyIdentifier={selectedCompany && selectedCompany.get_company_identifier}
						onClose={this.props.onClose}
					/>
				</div>

				<Divider />
				<List>
					<ListItem
						divider
						alignItems='center'
						button
						onClick={() => {
							this.props.history.push(`/internalusers/profile/${user.profile.sub}`)
						}}
					>
						<ListItemAvatar>{this.getAvater(user)}</ListItemAvatar>
						<ListItemText secondary={<React.Fragment>{user.profile.name}</React.Fragment>} />
					</ListItem>

					<ListItem
						divider
						selected={this.isMenuInitialSelected('/')}
						button
						onClick={() => {
							this.props.history.push('/')
						}}
					>
						<ListItemIcon>
							<BusinessIcon />
						</ListItemIcon>
						<ListItemText primary={<Translate id='menu.initial' />} />
					</ListItem>

					{(roles.CaBeShowListSuppliers(this.props.userRolesByCompany) ||
						roles.canBeOperationAdministratorWarehouse(this.props.userRolesByCompany) ||
						roles.canBeModuleProspectsSupplier(this.props.userRolesByCompany) ||
						roles.canBeModuleExpedientViewer(this.props.userRolesByCompany) ||
						roles.canBeModuleProspectAdminitrator(this.props.userRolesByCompany) ||
						(roles.IsUserInRole(roles.ROLE_SUPPLIERDEVELOPMENTENGINEER, this.props.userRolesByCompany) &&
							roles.IsUserInRole(roles.ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) &&
							selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX)) && (
						<AdministrationItems {...this.props} isActiveAdminsitration={this.isActiveAdminsitration()} />
					)}

					{/**Directorio telefonico */}
					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
						// roles.CaBeShowListSuppliers(this.props.userRolesByCompany)
						<ListItem
							divider
							selected={this.isMenuInitialSelected('phoneDirectory')}
							button
							onClick={() => {
								this.props.history.push('/phoneDirectory')
							}}
						>
							<ListItemIcon>
								<PhoneDirectorycon />
							</ListItemIcon>
							<ListItemText inset primary={<Translate id='phoneDirectories.title' />} />
						</ListItem>
					)}
					{selectedCompany &&
						(selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
							selectedCompany.get_company_identifier === COMPANY_IDENTIFIER__METALLIC) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('factoringaffiliation')}
								button
								onClick={() => {
									this.props.history.push('/factoringaffiliation')
								}}
							>
								<ListItemIcon>
									<FactoringAfiliantionIcon />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='factoringAffiliation.title' />} />
							</ListItem>
						)}
					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
						// roles.CaBeShowListSuppliers(this.props.userRolesByCompany)
						<ListItem
							divider
							selected={this.isMenuInitialSelected('ethiccode')}
							button
							onClick={() => {
								this.props.history.push('/ethiccode')
							}}
						>
							<ListItemIcon>
								<EthicCodeIcon />
							</ListItemIcon>
							<ListItemText inset primary={<Translate id='surveys.surveysTitle' />} />
						</ListItem>
					)}

					{selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
						process.env.PUBLIC_URL !== ENVIROMENT_TYPE_PRODUCTION && (
							<SurveyItems {...this.props} isActiveSurvey={this.isActiveSurvey()} />
						)}

					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_PTM &&
						roles.canCloseRegistersAccessControl(this.props.userRolesByCompany) && (
							<ContractorsItems {...this.props} isActiveContractors={this.isActiveAccessContractor()} />
						)}

					{selectedCompany &&
						selectedCompany.company_modules &&
						selectedCompany.company_modules.OfficialNotice && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('officialnotices')}
								button
								onClick={() => {
									this.props.history.push('/officialnotices')
								}}
							>
								<ListItemIcon>
									<NoticesIcon />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.officeNotice' />} />
							</ListItem>
						)}
					{selectedCompany &&
						selectedCompany.company_modules &&
						selectedCompany.company_modules.OfficialNotice &&
						selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
						process.env.PUBLIC_URL !== ENVIROMENT_TYPE_PRODUCTION && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('securityCourses')}
								button
								onClick={() => {
									this.props.history.push('/securityCourses')
								}}
							>
								<ListItemIcon>
									<Security />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.securityCourses' />} />
							</ListItem>
						)}
					{/**Certifications */}
					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
						<ListItem
							divider
							selected={this.isMenuInitialSelected('all/certifications')}
							button
							onClick={() => {
								this.props.history.push('/all/certifications')
							}}
						>
							<ListItemIcon>
								<DoneAll />
							</ListItemIcon>
							<ListItemText inset primary={<Translate id='menu.certifications' />} />
						</ListItem>
					)}
					{selectedCompany &&
						selectedCompany.company_modules &&
						selectedCompany.company_modules.PlanningModule &&
						selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_FERSA && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('planning')}
								button
								onClick={() => {
									this.props.history.push('/planning')
								}}
							>
								<ListItemIcon>
									<PurchaseIcon />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.deliveryPlan' />} />
							</ListItem>
						)}

					{selectedCompany &&
						selectedCompany.company_modules &&
						selectedCompany.company_modules.PlanningModule &&
						selectedCompany.get_company_identifier === COMPANY_PTM && (
							<ListItem
								divider
								button
								selected={this.isMenuInitialSelected('planning/deliveryorder')}
								onClick={() => {
									this.props.history.push('/planning/deliveryorder')
								}}
							>
								<ListItemIcon>
									<PurchaseIcon />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.deliveryOrder' />} />
							</ListItem>
						)}

					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE && (
						<GuaranteeItems {...this.props} isActiveCustomerGuarantee={this.isActiveCustomerGuarantee()} />
					)}

					<QualityAssuranceItems {...this.props} isActiveQuality={this.isActiveQuality()} />

					{(roles.caBeModuleDevelopment(this.props.userRolesByCompany) ||
						roles.caBeModuleSparePart(this.props.userRolesByCompany)) && (
						<SupplierDevelopmentItems {...this.props} isActiveDevelopment={this.isActiveDevelopment()} />
					)}

					{selectedCompany &&
						selectedCompany.get_company_identifier !== COMPANY_PTM &&
						roles.rolesToShowWarehouseRawMaterial(this.props.userRolesByCompany) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('warehouseRawMaterials')}
								button
								onClick={() => {
									this.props.history.push('/warehouseRawMaterials')
								}}
							>
								<ListItemIcon>
									<WarehouseRawMaterialIcon />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.warehouseMaterial' />} />
							</ListItem>
						)}
					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
						(roles.canBeModuleLoadAlert(this.props.userRolesByCompany) ||
							roles.canSeeModuleForeignTrade(this.props.userRolesByCompany)) && (
							<ImportItems {...this.props} isActiveImports={this.isActiveImports()} />
						)}

					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
						<ExportItems {...this.props} isActiveExports={this.isActiveExports()} />
					)}

					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
						<ListItem
							divider
							selected={this.isMenuInitialSelected('deliveryQualifications')}
							button
							onClick={() => {
								this.props.history.push('/deliveryQualifications')
							}}
						>
							<ListItemIcon>
								<RatesIcon />
							</ListItemIcon>
							<ListItemText primary={<Translate id='menu.deliveryQualifications' />} />
						</ListItem>
					)}

					{selectedCompany &&
						selectedCompany.company_modules &&
						selectedCompany.company_modules.ImportRequest && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('shippingorders')}
								button
								onClick={() => {
									this.props.history.push('/shippingorders')
								}}
							>
								<ListItemIcon>
									<ShippingIcon />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.imports' />} />
							</ListItem>
						)}
					{selectedCompany &&
						selectedCompany.company_modules &&
						selectedCompany.company_modules.Sustainability &&
						roles.canBeModuleSustantabilityQuestionnaire(this.props.userRolesByCompany) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('sustainabilityv2')}
								button
								onClick={() => {
									this.props.history.push('/sustainabilityv2')
								}}
							>
								<ListItemIcon>
									<SustainabilityIcon />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.sustainability' />} />
							</ListItem>
						)}

					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_PTM &&
						roles.canShowMaquilaOrder(this.props.userRolesByCompany) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('maquilaOrders')}
								button
								onClick={() => {
									this.props.history.push('/maquilaOrders')
								}}
							>
								<ListItemIcon>
									<MaquilaIcon />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='maquilaOrders.title' />} />
							</ListItem>
						)}
					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_PTM && (
						<ListItem
							divider
							selected={this.isMenuInitialSelected('warehouseWindows')}
							button
							onClick={() => {
								this.props.history.push('/warehouseWindows')
							}}
						>
							<ListItemIcon>
								<WarehouseWindowsIcon />
							</ListItemIcon>
							<ListItemText inset primary={<Translate id='warehouseWindows.title' />} />
						</ListItem>
					)}
					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_PTM && (
						<ListItem
							divider
							selected={this.isMenuInitialSelected('controlCO2')}
							button
							onClick={() => {
								this.props.history.push('/controlCO2')
							}}
						>
							<ListItemIcon>
								<Typography>Co2</Typography>
							</ListItemIcon>
							<ListItemText inset primary={<Translate id='controlCO2.title' />} />
						</ListItem>
					)}
					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_PTM &&
						process.env.PUBLIC_URL !== ENVIROMENT_TYPE_PRODUCTION && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('billings')}
								button
								onClick={() => {
									this.props.history.push('/billings')
								}}
							>
								<ListItemIcon>
									<BillingsIcon />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.billing' />} />
							</ListItem>
						)}

					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_PTM &&
						(roles.canBeModuleContractsSupplier(this.props.userRolesByCompany) ||
							roles.canBeModuleDocumentsEnvSupplier(this.props.userRolesByCompany) ||
							roles.canBeModuleContractsSupplierApplicant(this.props.userRolesByCompany)) && (
							<DocumentsPTMItems {...this.props} isActiveDocuments={this.isActiveDocumentsPtm()} />
						)}

					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_PTM &&
						roles.canBeModuleSustantabilityQuestionnaire(this.props.userRolesByCompany) && (
							<ListItem
								divider
								button
								selected={this.isMenuInitialSelected('questionnaire')}
								onClick={() => {
									this.props.history.push('/questionnaire')
								}}
							>
								<ListItemIcon>
									<QuestionnaireIcon />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.questionnaire' />} />
							</ListItem>
						)}

					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
						canBeModuleBidding(this.props.userRolesByCompany) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('biddings')}
								button
								onClick={() => {
									this.props.history.push('/biddings')
								}}
							>
								<ListItemIcon>
									<BiddingIcon />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.tenders' />} />
							</ListItem>
						)}

						{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
						process.env.PUBLIC_URL !== ENVIROMENT_TYPE_PRODUCTION &&
						roles.IsUserInRole(roles.ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) &&
						 (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('supplierVehicleBlocking')}
								button
								onClick={() => {
									this.props.history.push('/supplierDriverBlockings')
								}}
							>
								<ListItemIcon>
									<BiddingIcon />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.supplierDriverBlocking' />} />
							</ListItem>
						)}

					{selectedCompany &&
						selectedCompany.company_modules.ConsignmentNote &&
						canBeModuleConsignmentNote(this.props.userRolesByCompany) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('consignmentNote')}
								button
								onClick={() => {
									this.props.history.push('/consignmentNote')
								}}
							>
								<ListItemIcon>
									<FindInPage />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.consignmentNote' />} />
							</ListItem>
						)}

					{selectedCompany &&
						selectedCompany.company_modules.ConsignmentNote &&
						canBeModuleConsignmentNote(this.props.userRolesByCompany) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('ferrys')}
								button
								onClick={() => {
									this.props.history.push('/ferrys')
								}}
							>
								<ListItemIcon>
									<FindInPage />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.ferrysAdministration' />} />
							</ListItem>
						)}

					{selectedCompany && selectedCompany.company_modules.AccreditedWorkshopsPayments && (
						<ListItem
							divider
							selected={this.isMenuInitialSelected('accreditedWorkshops')}
							button
							onClick={() => {
								this.props.history.push('/accreditedWorkshops')
							}}
						>
							<ListItemIcon>
								<AccreditedWorkShopsIcon />
							</ListItemIcon>
							<ListItemText primary={<Translate id='menu.accreditedWorkshops' />} />
						</ListItem>
					)}

					{selectedCompany &&
						selectedCompany.get_company_identifier === COMPANY_PTM &&
						roles.canBeModuleExpeditByCompany(this.props.userRolesByCompany) && (
							<ListItem
								divider
								selected={this.isMenuInitialSelected('expedientsupplier')}
								button
								onClick={() => {
									this.props.history.push('/expedientsupplier')
								}}
							>
								<ListItemIcon>
									<ExpedientsIcon />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='expedientSupplier.expedientTitle' />} />
							</ListItem>
						)}
					<ListItem
						divider
						selected={this.isMenuInitialSelected('manualsportal')}
						button
						onClick={() => {
							this.props.history.push('/manualsportal')
						}}
					>
						<ListItemIcon>
							<TutorialIcon />
						</ListItemIcon>
						<ListItemText primary={<Translate id='menu.tutorials' />} />
					</ListItem>

					{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
						<DistributionTransport
							{...this.props}
							isDistributionTransport={this.isDistributionTransport()}
						/>
					)}
				</List>
			</Drawer>
		)
	}
}

class DistributionTransport extends Component {
	/**
	 *Creates an instance of ConsultasItems.
	 * @param {*} props
	 * @memberof ConsultasItems
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			open: this.props.isDistributionTransport,
			openCatalog: this.props.isDistributionTransport,
		}
		this.catalog = {}
	}
	isMenuDistributionTransportSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/transportTenderShow':
			case '/transportTenderShow/:token':
				selectedOption = 'transportTenderShow'
				break
			case '/transportTender/catalog/requirementsCarriers':
				selectedOption = 'requirementsCarriers'
				break
			case '/transportTender/catalog/availableSchedules':
				selectedOption = 'availableSchedules'
				break
			case '/transportTender/catalog/wineCellar':
				selectedOption = 'wineCellar'
				break
			case '/transportTender/catalog/transportZones':
				selectedOption = 'transportZones'
				break
			case '/transportTender/catalog/typesTransport':
				selectedOption = 'typesTransport'
				break
			case '/transportTender/catalog/carrierTypes':
				selectedOption = 'carrierTypes'
				break
			case '/transportTender/catalog/rates':
				selectedOption = 'rates'
				break
			case '/transportTender/catalog/clients':
				selectedOption = 'clients'
				break
			case '/transportTender/catalog/maneuvers':
				selectedOption = 'maneuvers'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		const { selectedCompany } = this.props
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<ProspectSupplierIncon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.distributionTransport' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
							<ListItem
								divider
								selected={this.isMenuDistributionTransportSelected('transportTenderShow')}
								button
								onClick={() => {
									this.props.history.push('/transportTenderShow')
								}}
							>
								<ListItemIcon>
									<TutorialIcon color='secondary' />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.transportTender' />} />
							</ListItem>
						)}

						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
							<React.Fragment>
								<ListItem
									divider
									button
									onClick={() => {
										this.setState({ open: true, openCatalog: !this.state.openCatalog })
									}}
								>
									<ListItemIcon>
										<EthicCodeIcon color='secondary' />
									</ListItemIcon>
									<ListItemText primary={<Translate id='menu.transportTenderCatalogues' />} />
									{!this.state.openCatalog ? <ExpandLess /> : <ExpandMore />}
								</ListItem>
								<Collapse in={this.state.openCatalog} timeout='auto' unmountOnExit>
									{/* {selectedCompany &&
										selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
											<ListItem
												divider
												selected={this.isMenuDistributionTransportSelected(
													'availableSchedules'
												)}
												button
												onClick={() => {
													this.props.history.push(
														'/transportTender/catalog/availableSchedules'
													)
												}}
											>
												<ListItemIcon style={{ marginLeft: '15px' }}>
													<AccessAlarmIcon color='secondary' />
												</ListItemIcon>
												<ListItemText
													inset
													primary={
														<Translate
															id={'carrierTenders.configuration.availableSchedules'}
														/>
													}
												/>
											</ListItem>
										)} */}
									{selectedCompany &&
										selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
											<ListItem
												divider
												selected={this.isMenuDistributionTransportSelected('wineCellar')}
												button
												onClick={() => {
													this.props.history.push('/transportTender/catalog/wineCellar')
												}}
											>
												<ListItemIcon style={{ marginLeft: '15px' }}>
													<HomeIcon color='secondary' />
												</ListItemIcon>
												<ListItemText
													inset
													primary={<Translate id={'carrierTenders.wineCellar'} />}
												/>
											</ListItem>
										)}
									{selectedCompany &&
										selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
											<ListItem
												divider
												selected={this.isMenuDistributionTransportSelected('transportZones')}
												button
												onClick={() => {
													this.props.history.push('/transportTender/catalog/transportZones')
												}}
											>
												<ListItemIcon style={{ marginLeft: '15px' }}>
													<LocationOnIcon color='secondary' />
												</ListItemIcon>
												<ListItemText
													inset
													primary={
														<Translate id={'carrierTenders.configuration.transportZones'} />
													}
												/>
											</ListItem>
										)}
									{selectedCompany &&
										selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
											<ListItem
												divider
												selected={this.isMenuDistributionTransportSelected('typesTransport')}
												button
												onClick={() => {
													this.props.history.push('/transportTender/catalog/typesTransport')
												}}
											>
												<ListItemIcon style={{ marginLeft: '15px' }}>
													<LocalShippingIcon color='secondary' />
												</ListItemIcon>
												<ListItemText
													inset
													primary={
														<Translate id={'carrierTenders.configuration.typesTransport'} />
													}
												/>
											</ListItem>
										)}
									{selectedCompany &&
										selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
											<ListItem
												divider
												selected={this.isMenuDistributionTransportSelected('carrierTypes')}
												button
												onClick={() => {
													this.props.history.push('/transportTender/catalog/carrierTypes')
												}}
											>
												<ListItemIcon style={{ marginLeft: '15px' }}>
													<BubbleChartIcon color='secondary' />
												</ListItemIcon>
												<ListItemText
													inset
													primary={
														<Translate id={'carrierTenders.configuration.carrierTypes'} />
													}
												/>
											</ListItem>
										)}
									{selectedCompany &&
										selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
											<ListItem
												divider
												selected={this.isMenuDistributionTransportSelected(
													'requirementsCarriers'
												)}
												button
												onClick={() => {
													this.props.history.push(
														'/transportTender/catalog/requirementsCarriers'
													)
												}}
											>
												<ListItemIcon style={{ marginLeft: '15px' }}>
													<BallotIcon color='secondary' />
												</ListItemIcon>
												<ListItemText
													inset
													primary={
														<Translate
															id={'carrierTenders.configuration.requirementsCarriers'}
														/>
													}
												/>
											</ListItem>
										)}
									{selectedCompany &&
										selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
											<ListItem
												divider
												selected={this.isMenuDistributionTransportSelected('maneuvers')}
												button
												onClick={() => {
													this.props.history.push('/transportTender/catalog/maneuvers')
												}}
											>
												<ListItemIcon style={{ marginLeft: '15px' }}>
													<BallotIcon color='secondary' />
												</ListItemIcon>
												<ListItemText
													inset
													primary={
														<Translate id={'carrierTenders.configuration.maneuvers'} />
													}
												/>
											</ListItem>
										)}

									{selectedCompany &&
										selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
											<ListItem
												divider
												selected={this.isMenuDistributionTransportSelected('rates')}
												button
												onClick={() => {
													this.props.history.push('/transportTender/catalog/rates')
												}}
											>
												<ListItemIcon style={{ marginLeft: '15px' }}>
													<AttachMoney color='secondary' />
												</ListItemIcon>
												<ListItemText
													inset
													primary={<Translate id={'carrierTenders.configuration.rate'} />}
												/>
											</ListItem>
										)}
									{selectedCompany &&
										selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
											<ListItem
												divider
												selected={this.isMenuDistributionTransportSelected('clients')}
												button
												onClick={() => {
													this.props.history.push('/transportTender/catalog/clients')
												}}
											>
												<ListItemIcon style={{ marginLeft: '15px' }}>
													<Group color='secondary' />
												</ListItemIcon>
												<ListItemText
													inset
													primary={<Translate id={'carrierTenders.configuration.client'} />}
												/>
											</ListItem>
										)}
								</Collapse>
							</React.Fragment>
						)}
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}
/**
 * Desarrollo de proveedores menu item
 *
 * @class DesarrolloProveedoresItems
 * @extends {Component}
 */
class SupplierDevelopmentItems extends Component {
	/**
	 *Creates an instance of ConsultasItems.
	 * @param {*} props
	 * @memberof ConsultasItems
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			open: this.props.isActiveDevelopment,
		}
	}
	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuDevelopmentSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/memos':
			case '/memos/create':
			case '/memos/:token':
				selectedOption = 'memos'
				break
			case '/noticechanges':
			case '/noticechanges/create':
			case '/noticechanges/:token':
			case '/noticechanges/notification/:token':
			case '/noticechanges/cancel/:token':
				selectedOption = 'noticechanges'
				break
			case '/alternativeprovedure':
			case '/alternativeprovedure/create':
			case '/alternativeprovedure/:token':
				selectedOption = 'alternativeprovedure'
				break
			case '/materialdelivery':
			case '/materialdelivery/create':
			case '/materialdelivery/:token':
				selectedOption = 'materialdelivery'
				break
			case '/quotes':
			case '/quotes/:token':
			case '/quotes/cancel/:token':
				selectedOption = 'quotes'
				break
			case '/samplerequests':
			case '/samplerequests/:token':
			case '/samplerequests/cancel/:token':
				selectedOption = 'samplerequests'
				break
			case '/partnumbers':
				selectedOption = 'partnumbers'
				break
			case '/ratings/import':
				selectedOption = 'ratings/import'
				break
			case '/ratings/generate':
				selectedOption = 'ratings/generate'
				break
			case '/ratings/generatingPTM':
				selectedOption = 'ratings/generatingPTM'
				break
			case '/ratings/generatingFERSA':
				selectedOption = 'ratings/generatingFERSA'
				break
			case '/correctiveactions':
			case '/correctiveactions/create':
			case '/correctiveactions/:token':
			case '/correctiveactions/cancel/:token':
				selectedOption = 'correctiveactions'
				break
			case '/indirectqualifications':
			case '/indirectqualifications/:token':
			case '/indirectqualifications/actionsPlant/:token/:supplierId':
				selectedOption = 'indirectqualifications'
				break
			case '/priceChangeRequest':
			case '/priceChangeRequest/create':
			case '/priceChangeRequest/:token':
				selectedOption = 'priceChangeRequest'
				break
			case '/sparePartPriceChange':
			case '/sparePartPriceChange/:activeTab':
			case '/sparePartPriceChange/show/:token':
				selectedOption = 'sparePartPriceChange'
				break
			case '/indirectPriceQuoteWithoutCode':
			case '/indirectPriceQuoteWithoutCode/internalUser/:token':
			case '/indirectPriceQuoteWithoutCode/show/:token':
				selectedOption = 'indirectPriceQuoteWithoutCode'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}
	render() {
		const { selectedCompany } = this.props
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<SupplierDevelopmentIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.developmentSuppliers' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.get_company_identifier !== COMPANY_PTM &&
							selectedCompany.company_modules.Memos && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('memos')}
									button
									onClick={() => {
										this.props.history.push('/memos')
									}}
								>
									<ListItemIcon>
										<MemosIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.memos' />} />
								</ListItem>
							)}
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_PTM && (
							<ListItem
								divider
								selected={this.isMenuDevelopmentSelected('materialdelivery')}
								button
								onClick={() => {
									this.props.history.push('/materialdelivery')
								}}
							>
								<ListItemIcon>
									<MemosIcon color='secondary' />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.materialDelivery' />} />
							</ListItem>
						)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.NoticeChanges &&
							roles.caBeModuleDevelopment(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('noticechanges')}
									button
									onClick={() => {
										this.props.history.push('/noticechanges')
									}}
								>
									<ListItemIcon>
										<NoticesOfChangeIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.noticeChange' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.AlternativeProvedure &&
							roles.caBeModuleDevelopment(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('alternativeprovedure')}
									button
									onClick={() => {
										this.props.history.push('/alternativeprovedure')
									}}
								>
									<ListItemIcon>
										<AlternativeSupplierIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.alternatingProveeduria' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.get_company_identifier === COMPANY_PTM &&
							roles.caBeModuleSparePart(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('sparePartPriceChange')}
									button
									onClick={() => {
										this.props.history.push('/sparePartPriceChange')
									}}
								>
									<ListItemIcon>
										<CreditCard color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.priceChangeRequestPTM' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
							selectedCompany.company_modules && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('priceChangeRequest')}
									button
									onClick={() => {
										this.props.history.push('/priceChangeRequest')
									}}
								>
									<ListItemIcon>
										<CreditCard color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.priceChangeRequest' />} />
								</ListItem>
							)}

						{selectedCompany &&
							(selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
								selectedCompany.get_company_identifier === COMPANY_IDENTIFIER__METALLIC) &&
							selectedCompany.company_modules && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('indirectPriceQuoteWithoutCode')}
									button
									onClick={() => {
										this.props.history.push('/indirectPriceQuoteWithoutCode')
									}}
								>
									<ListItemIcon>
										<CreditCard color='secondary' />
									</ListItemIcon>
									<ListItemText
										inset
										primary={<Translate id='menu.indirectPriceQuoteWithoutCode' />}
									/>
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.Quotes &&
							roles.caBeModuleDevelopment(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('quotes')}
									button
									onClick={() => {
										this.props.history.push('/quotes')
									}}
								>
									<ListItemIcon>
										<QuotesIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.quotes' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.Samples &&
							roles.caBeModuleDevelopment(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('samplerequests')}
									button
									onClick={() => {
										this.props.history.push('/samplerequests')
									}}
								>
									<ListItemIcon>
										<SamplesIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.sample' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.Traceability &&
							roles.caBeModuleDevelopment(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('partnumbers')}
									button
									onClick={() => {
										this.props.history.push('/partnumbers')
									}}
								>
									<ListItemIcon>
										<TrazabilityIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.trazability' />} />
								</ListItem>
							)}

						{selectedCompany &&
							(selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
								selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE ||
								selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_COLOMBIA) &&
							roles.canBeCloseSacDevelopment(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('ratings/import')}
									button
									onClick={() => {
										this.props.history.push('/ratings/import')
									}}
								>
									<ListItemIcon>
										<RatesIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.importRatings' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.get_company_identifier !== COMPANY_PTM &&
							selectedCompany.get_company_identifier !== COMPANY_IDENTIFIER_IMBERA_REPARE &&
							selectedCompany.get_company_identifier !== COMPANY_IDENTIFIER_IMBERA_MX &&
							selectedCompany.get_company_identifier !== COMPANY_IDENTIFIER_IMBERA_BRASIL &&
							selectedCompany.get_company_identifier !== COMPANY_IDENTIFIER_IMBERA_COLOMBIA &&
							roles.canBeCloseSacDevelopment(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('ratings/generate')}
									button
									onClick={() => {
										this.props.history.push('/ratings/generate')
									}}
								>
									<ListItemIcon>
										<RatesIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.generatedRating' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.get_company_identifier === COMPANY_PTM &&
							roles.IsUserInRole(roles.ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('ratings/generatingPTM')}
									button
									onClick={() => {
										this.props.history.push('/ratings/generatingPTM')
									}}
								>
									<ListItemIcon>
										<RatesIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.generatedRating' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_FERSA &&
							roles.IsUserInRole(roles.ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('ratings/generatingFERSA')}
									button
									onClick={() => {
										this.props.history.push('/ratings/generatingFERSA')
									}}
								>
									<ListItemIcon>
										<RatesIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.generatedRatingFERSA' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.SacsDevelopment &&
							roles.caBeModuleDevelopment(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuDevelopmentSelected('correctiveactions')}
									button
									onClick={() => {
										this.props.history.push('/correctiveactions')
									}}
								>
									<ListItemIcon>
										<SACRequestIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.correctiveActionDevelopment' />} />
								</ListItem>
							)}
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
							<ListItem
								divider
								selected={this.isMenuDevelopmentSelected('indirectqualifications')}
								button
								onClick={() => {
									this.props.history.push('/indirectqualifications')
								}}
							>
								<ListItemIcon>
									<IndirectQualificationIcon color='secondary' />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.indirectQualifications' />} />
							</ListItem>
						)}
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

class ImportItems extends Component {
	/**
	 *Creates an instance of ImportItems.
	 * @param {*} props
	 * @memberof ImportItems
	 */
	constructor(props) {
		super(props)
		this.state = {
			open: this.props.isActiveImports,
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuImportsSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/importRequests':
			case '/importRequests/:token':
				selectedOption = 'importRequests'
				break
			case '/loadAlerts':
			case '/loadAlerts/:token':
				selectedOption = 'loadAlerts'
				break
			case 'loadalerttracking':
				selectedOption = 'loadalerttracking'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<AdministrationIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.ControlTower' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{roles.canSeeModuleForeignTrade(this.props.userRolesByCompany) && (
							<ListItem
								divider
								button
								selected={this.isMenuImportsSelected('importRequests')}
								onClick={() => {
									this.props.history.push('/importRequests')
								}}
							>
								<ListItemIcon>
									<MemosIcon color='secondary' />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.importRequests' />} />
							</ListItem>
						)}

						{roles.canSeeModuleForeignTrade(this.props.userRolesByCompany) && (
							<ListItem
								divider
								button
								selected={this.isMenuImportsSelected('searchPartNumbers')}
								onClick={() => {
									this.props.history.push('/searchPartNumbers')
								}}
							>
								<ListItemIcon>
									<FormatListNumberedIcon color='secondary' />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.partNumber' />} />
							</ListItem>
						)}
						{roles.canBeModuleLoadAlert(this.props.userRolesByCompany) && (
							<ListItem
								divider
								button
								selected={this.isMenuImportsSelected('loadAlerts')}
								onClick={() => {
									this.props.history.push('/loadAlerts')
								}}
							>
								<ListItemIcon>
									<Notifications color='secondary' />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.loadAlerts' />} />
							</ListItem>
						)}

						{roles.canBeModuleLoadAlert(this.props.userRolesByCompany) && (
							<ListItem
								divider
								button
								selected={this.isMenuImportsSelected('loadalerttracking')}
								onClick={() => {
									this.props.history.push('/loadalerttracking')
								}}
							>
								<ListItemIcon>
									<ViewList color='secondary' />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.loadAlertTracking' />} />
							</ListItem>
						)}
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

class ExportItems extends Component {
	/**
	 *Creates an instance of ImportItems.
	 * @param {*} props
	 * @memberof ExportItems
	 */
	constructor(props) {
		super(props)
		this.state = {
			open: this.props.isActiveExports,
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuExportsSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/exportRequests':
			case '/exportRequests/:token':
				selectedOption = 'exportRequests'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<AdministrationIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.ControlTowerExport' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						<ListItem
							divider
							button
							selected={this.isMenuExportsSelected('exportRequests')}
							onClick={() => {
								this.props.history.push('/exportRequests')
							}}
						>
							<ListItemIcon>
								<MemosIcon color='secondary' />
							</ListItemIcon>
							<ListItemText primary={<Translate id='menu.exportRequests' />} />
						</ListItem>
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

/**
 * Aseguramiento de calidad menu item
 *
 * @class QualityAssuranceItem
 * @extends {Component}
 */
class QualityAssuranceItems extends Component {
	/**
	 *Creates an instance of ConsultasItems.
	 * @param {*} props
	 * @memberof QualityAssuranceItem
	 */
	constructor(props) {
		super(props)
		this.state = {
			open: this.props.isActiveQuality,
			openSpecification: this.props.isActiveQuality,
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuQualitySelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/rawmaterialnonconformity':
			case '/rawmaterialnonconformity/create':
			case '/rawmaterialnonconformity/:token':
			case '/rawmaterialnonconformity/edit/:token':
			case '/rawmaterialnonconformity/cancel/:token':
			case '/rawmaterialnonconformity/sendNotification/:token':
			case '/rawmaterialnonconformity/registerDeviation/:token':
			case '/rawmaterialnonconformity/createdevolution/:token':
			case '/rawmaterialnonconformity/createrework/:token':
			case '/rawmaterialnonconformity/createfromDelivery/:token/:itemToken':
				selectedOption = 'rawmaterialnonconformity'
				break
			case '/qualityfailurereport':
			case '/qualityfailurereport/create':
			case '/qualityfailurereport/:token':
			case '/qualityfailurereport/edit/:token':
			case '/qualityfailurereport/cancel/:token':
			case '/qualityfailurereport/sendNotification/:token':
			case '/qualityfailurereport/registerDeviation/:token':
			case '/qualityfailurereport/createdevolution/:token':
			case '/qualityfailurereport/createrework/:token':
				selectedOption = 'qualityfailurereport'
				break
			case '/reworks':
			case '/reworks/create':
			case '/reworks/:token':
			case '/reworks/reworkreport/:token':
			case '/reworks/createdevolution/:token':
				selectedOption = 'reworks'
				break
			case '/sacsquality':
			case '/sacsquality/create':
			case '/sacsquality/:token':
			case '/sacsquality/cancel/:token':
			case '/sacsquality/sendNotification/:token':
			case '/sacsquality/createActionsBySupplier/:token':
				selectedOption = 'sacsquality'
				break
			case '/devolutions':
			case '/devolutions/create':
			case '/devolutions/:token':
			case '/devolutions/cancel/:token':
				selectedOption = 'devolutions'
				break
			case '/qualityCertificate':
			case '/qualityCertificate/:token':
				selectedOption = 'qualityCertificate'
				break
			case '/nonconformities':
				selectedOption = 'nonconformities'
				break
			case '/managequalityworkers':
				selectedOption = 'managequalityworkers'
				break
			case '/warrantyReportsPtm':
			case '/warrantyReportsPtm/create':
			case '/warrantyReportsPtm/:token':
			case '/warrantyReportsPtm/cancel/:token':
				selectedOption = 'warrantyReportsPtm'
				break
			case '/qualityaudit':
			case '/qualityaudit/:token':
				selectedOption = 'qualityaudit'
				break
			case '/qualifications':
				selectedOption = 'qualifications'
				break
			case '/specifications/guidelines':
			case '/specifications/guidelines/:token':
				selectedOption = 'guidelines'
				break
			case '/specifications/package':
			case '/specifications/package/:token':
				selectedOption = 'package'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		const { selectedCompany } = this.props
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<QualityAssuranceIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.quality' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.NonConformityMaterial && (
								<ListItem
									divider
									selected={this.isMenuQualitySelected('rawmaterialnonconformity')}
									button
									onClick={() => {
										this.props.history.push('/rawmaterialnonconformity')
									}}
								>
									<ListItemIcon>
										<RawMaterialNonConfIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.rawMaterialsNotification' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.QualityFailureReports && (
								<ListItem
									divider
									selected={this.isMenuQualitySelected('qualityfailurereport')}
									button
									onClick={() => {
										this.props.history.push('/qualityfailurereport')
									}}
								>
									<ListItemIcon>
										<QualityFailureReportIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.failureReportQuality' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.Rework && (
								<ListItem
									divider
									selected={this.isMenuQualitySelected('reworks')}
									button
									onClick={() => {
										this.props.history.push('/reworks')
									}}
								>
									<ListItemIcon>
										<ReworkIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.rework' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.SacsQuality && (
								<ListItem
									divider
									selected={this.isMenuQualitySelected('sacsquality')}
									button
									onClick={() => {
										this.props.history.push('/sacsquality')
									}}
								>
									<ListItemIcon>
										<SacsQualityIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.correctiveActionQuality' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.Devolutions && (
								<ListItem
									divider
									selected={this.isMenuQualitySelected('devolutions')}
									button
									onClick={() => {
										this.props.history.push('/devolutions')
									}}
								>
									<ListItemIcon>
										<DevolutionIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.devolutions' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_BRASIL && (
								<ListItem
									divider
									selected={this.isMenuQualitySelected('qualifications')}
									button
									onClick={() => {
										this.props.history.push('/qualifications')
									}}
								>
									<ListItemIcon>
										<RatesIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.ratings' />} />
								</ListItem>
							)}
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
							<ListItem
								divider
								selected={this.isMenuQualitySelected('qualityaudit')}
								button
								onClick={() => {
									this.props.history.push('/qualityaudit')
								}}
							>
								<ListItemIcon>
									<FindInPage color='secondary' />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.audit' />} />
							</ListItem>
						)}
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_PTM && (
							<ListItem
								divider
								selected={this.isMenuQualitySelected('warrantyReportsPtm')}
								button
								onClick={() => {
									this.props.history.push('/warrantyReportsPtm')
								}}
							>
								<ListItemIcon>
									<WarrantyQualityIcon color='secondary' />
								</ListItemIcon>
								<ListItemText inset primary={'Reporte de garantías'} />
							</ListItem>
						)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.NonConformities &&
							roles.canUserValideModuleQuality(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuQualitySelected('nonconformities')}
									button
									onClick={() => {
										this.props.history.push('/nonconformities')
									}}
								>
									<ListItemIcon>
										<NonConformityIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.nonConformity' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.CommonUsersQuality &&
							roles.canUserValideModuleQuality(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuQualitySelected('managequalityworkers')}
									button
									onClick={() => {
										this.props.history.push('/managequalityworkers')
									}}
								>
									<ListItemIcon>
										<QualityUsersIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.usersquality' />} />
								</ListItem>
							)}
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
							<ListItem
								divider
								selected={this.isMenuQualitySelected('qualityCertificate')}
								button
								onClick={() => {
									this.props.history.push('/qualityCertificate')
								}}
							>
								<ListItemIcon>
									<CreditCardOutlined color='secondary' />
								</ListItemIcon>
								<ListItemText
									inset
									primary={<Translate id='qualityCertificate.qualityCertificate' />}
								/>
							</ListItem>
						)}
						{selectedCompany && selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
							<React.Fragment>
								<ListItem
									divider
									button
									onClick={() => {
										this.setState({
											open: true,
											openSpecification: !this.state.openSpecification,
										})
									}}
								>
									<ListItemIcon>
										<Assignment color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.specifications' />} />
									{!this.state.openSpecification ? <ExpandLess /> : <ExpandMore />}
								</ListItem>
								<Collapse in={this.state.openSpecification} timeout='auto' unmountOnExit>
									{selectedCompany &&
										selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX && (
											<List>
												<ListItem
													divider
													selected={this.isMenuQualitySelected('guidelines')}
													button
													onClick={() => {
														this.props.history.push('/specifications/guidelines')
													}}
												>
													<ListItemIcon style={{ marginLeft: '15px' }}>
														<Poll color='secondary' />
													</ListItemIcon>
													<ListItemText
														inset
														primary={
															<Translate id='specificationsSection.guidelines.tileMenu' />
														}
													/>
												</ListItem>
												<ListItem
													divider
													selected={this.isMenuQualitySelected('package')}
													button
													onClick={() => this.props.history.push('/specifications/package')}
												>
													<ListItemIcon style={{ marginLeft: '15px' }}>
														<Description color='secondary' />
													</ListItemIcon>
													<ListItemText
														inset
														primary={<Translate id='specificationsSection.package.title' />}
													/>
												</ListItem>
											</List>
										)}
								</Collapse>
							</React.Fragment>
						)}
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

/**
 * Administración del portal
 */
class AdministrationItems extends Component {
	/**
	 *Creates an instance of AdministrationItems.
	 * @param {*} props
	 * @memberof AdministrationItems
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			open: this.props.isActiveAdminsitration,
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuAdministrationSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/companies':
			case '/viewcompany/:token':
			case '/companies/:company_token':
				selectedOption = 'companies'
				break
			case '/internalusers':
			case '/internalusers/:token':
			case '/internalusers/profile/:token':
				selectedOption = 'internalusers'
				break
			case '/suppliers':
			case '/suppliers/:token':
			case '/suppliers/ratings/:token':
				selectedOption = 'suppliers'
				break
			case '/warehouseworkers':
				selectedOption = 'warehouseworkers'
				break
			case '/releasecalendar':
			case '/releasecalendar/create':
			case '/releasecalendar/:token':
				selectedOption = 'releasecalendar'
				break
			case '/releasecalendarFersa':
			case '/releasecalendarFersa/create':
			case '/releasecalendarFersa/:token':
				selectedOption = 'releasecalendarFersa'
				break
			case '/releaseplan/upload':
				selectedOption = 'releaseplan/upload'
				break
			case '/destinations':
				selectedOption = 'destinations'
				break
			case '/contactsupplier':
			case '/contactsupplier/:token':
			case '/contactsupplier/create':
				selectedOption = 'contactsupplier'
				break
			case '/expedientsupplier':
			case '/expedientsupplier/create':
			case '/expedientsupplier/:token':
			case '/expedientsbysupplier/:token':
				selectedOption = 'expedientsupplier'
				break
			case '/templates':
			case '/templates/:token':
				selectedOption = 'templates'
				break
			case '/prospects':
			case '/prospects/:token':
			case '/prospects/create':
				selectedOption = 'prospects'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		const { selectedCompany } = this.props
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<AdministrationIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.administration' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{roles.IsUserInRole(roles.ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && (
							<ListItem
								divider
								selected={this.isMenuAdministrationSelected('companies')}
								button
								onClick={() => {
									this.props.history.push('/companies')
								}}
							>
								<ListItemIcon>
									<CompaniesIcon color='secondary' />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.companies' />} />
							</ListItem>
						)}
						{roles.IsUserInRole(roles.ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && (
							<ListItem
								divider
								selected={this.isMenuAdministrationSelected('internalusers')}
								button
								onClick={() => {
									this.props.history.push('/internalusers')
								}}
							>
								<ListItemIcon>
									<UsersManagementIcon color='secondary' />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.internalUsers' />} />
							</ListItem>
						)}
						{roles.CaBeShowListSuppliers(this.props.userRolesByCompany) && (
							<ListItem
								divider
								selected={this.isMenuAdministrationSelected('suppliers')}
								button
								onClick={() => {
									this.props.history.push('/suppliers')
								}}
							>
								<ListItemIcon>
									<SuppliersIcon color='secondary' />
								</ListItemIcon>
								<ListItemText inset primary={<Translate id='menu.suppliers' />} />
							</ListItem>
						)}
						{selectedCompany &&
							(selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
								selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_REPARE ||
								selectedCompany.get_company_identifier === COMPANY_PTM) &&
							roles.CaBeShowListSuppliers(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuAdministrationSelected('contactsupplier')}
									button
									onClick={() => {
										this.props.history.push('/contactsupplier')
									}}
								>
									<ListItemIcon>
										<ContactsIcon color='secondary' />
									</ListItemIcon>
									<ListItemText
										inset
										primary={<Translate id='contactsupplier.contactsupplierTitle' />}
									/>
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.SupplierProspectus &&
							selectedCompany.get_company_identifier !== COMPANY_IDENTIFIER_IMBERA_MX &&
							(roles.CaBeShowListSuppliers(this.props.userRolesByCompany) ||
								roles.canBeModuleProspectsSupplier(this.props.userRolesByCompany) ||
								roles.IsUserInRole(
									roles.ROLE_SUPPLIERDEVELOPMENTENGINEER,
									this.props.userRolesByCompany
								)) && (
								<ListItem
									divider
									selected={this.isMenuAdministrationSelected('prospects')}
									button
									onClick={() => {
										this.props.history.push('/prospects')
									}}
								>
									<ListItemIcon>
										<ProspectSupplierIncon color='secondary' />
									</ListItemIcon>
									<ListItemText
										primary={
											selectedCompany &&
											selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ? (
												<Translate id='prospects.titleProviderregistration' />
											) : (
												<Translate id='menu.prospects' />
											)
										}
									/>
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.SupplierProspectus &&
							selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX &&
							(roles.canBeModuleProspectAdminitrator(this.props.userRolesByCompany) ||
								roles.IsUserInRole(roles.ROLE_SUPPLIERDEVELOPMENT, this.props.userRolesByCompany)) && (
								<ListItem
									divider
									selected={this.isMenuAdministrationSelected('prospects')}
									button
									onClick={() => {
										this.props.history.push('/prospects')
									}}
								>
									<ListItemIcon>
										<ProspectSupplierIncon color='secondary' />
									</ListItemIcon>
									<ListItemText
										primary={
											selectedCompany &&
											selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ? (
												<Translate id='prospects.titleProviderregistration' />
											) : (
												<Translate id='menu.prospects' />
											)
										}
									/>
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules.SupplierProspectus &&
							(roles.canBeModuleExpedientViewer(this.props.userRolesByCompany) ||
								roles.canBeModuleResposibleExpedient(this.props.userRolesByCompany) ||
								roles.IsUserInRole(roles.ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany)) && (
								<ListItem
									divider
									selected={this.isMenuAdministrationSelected('expedientsupplier')}
									button
									onClick={() => {
										this.props.history.push('/expedientsupplier')
									}}
								>
									<ListItemIcon>
										<ExpedientsIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='expedientSupplier.expedientTitle' />} />
								</ListItem>
							)}
						{selectedCompany &&
							(selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
								selectedCompany.get_company_identifier === COMPANY_IDENTIFIER__METALLIC) &&
							canBeAccessModuleFactoringAffiliation(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuAdministrationSelected('factoringaffiliation')}
									button
									onClick={() => {
										this.props.history.push('/factoringaffiliation')
									}}
								>
									<ListItemIcon>
										<FactoringAfiliantionIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='factoringAffiliation.title' />} />
								</ListItem>
							)}

						{selectedCompany &&
							selectedCompany.get_company_identifier === COMPANY_PTM &&
							roles.IsUserInRole(roles.ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuAdministrationSelected('templates')}
									button
									onClick={() => {
										this.props.history.push('/templates')
									}}
								>
									<ListItemIcon>
										<TemplatesIcon color='secondary' />
									</ListItemIcon>
									<ListItemText primary={<Translate id='questionnaire.templates' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.get_company_identifier === COMPANY_PTM &&
							roles.canUpdateTheReleaseForPTM(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuAdministrationSelected('releaseplan/upload')}
									button
									onClick={() => {
										this.props.history.push('/releaseplan/upload')
									}}
								>
									<ListItemIcon>
										<DownloadUploadIcon color='secondary' />
									</ListItemIcon>
									<ListItemText primary={<Translate id='releasePlan.updateRelease' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.PlanningModule &&
							selectedCompany.get_company_identifier !== COMPANY_IDENTIFIER_FERSA &&
							(roles.canBeOperationAdministratorReleaseCalendar(this.props.userRolesByCompany) ||
								roles.IsUserInRole(roles.ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany)) && (
								<ListItem
									divider
									selected={this.isMenuAdministrationSelected('releasecalendar')}
									button
									onClick={() => {
										this.props.history.push('/releasecalendar')
									}}
								>
									<ListItemIcon>
										<ReleaseClockIcon color='secondary' />
									</ListItemIcon>
									<ListItemText primary={<Translate id='menu.releaseCalendarLock' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.PlanningModule &&
							selectedCompany.get_company_identifier === COMPANY_IDENTIFIER_FERSA &&
							(roles.canBeOperationAdministratorWarehouse(this.props.userRolesByCompany) ||
								roles.IsUserInRole(roles.ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany)) && (
								<ListItem
									divider
									selected={this.isMenuAdministrationSelected('releasecalendarFersa')}
									button
									onClick={() => {
										this.props.history.push('/releasecalendarFersa')
									}}
								>
									<ListItemIcon>
										<ReleaseClockIcon color='secondary' />
									</ListItemIcon>
									<ListItemText primary={<Translate id='menu.releaseCalendarLock' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.PlanningModule &&
							(roles.canBeOperationAdministratorWarehouse(this.props.userRolesByCompany) ||
								roles.IsUserInRole(roles.ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany)) && (
								<ListItem
									divider
									selected={this.isMenuAdministrationSelected('warehouseworkers')}
									button
									onClick={() => {
										this.props.history.push('/warehouseworkers')
									}}
								>
									<ListItemIcon>
										<WarehouseIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.warehousesStaff' />} />
								</ListItem>
							)}
						{selectedCompany &&
							selectedCompany.get_company_identifier === COMPANY_PTM &&
							roles.IsUserInRole(roles.ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuAdministrationSelected('destinations')}
									button
									onClick={() => {
										this.props.history.push('/destinations')
									}}
								>
									<ListItemIcon>
										<DestinationIcon color='secondary' />
									</ListItemIcon>
									<ListItemText
										primary={
											<Translate id='deliveryOrderModule.carbonFootprint.transportDestination' />
										}
									/>
								</ListItem>
							)}
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

/**
 * SurveyItems del portal
 */
class SurveyItems extends Component {
	/**
	 *Creates an instance of SurveyItems.
	 * @param {*} props
	 * @memberof SurveyItems
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			open: this.props.isActiveSurvey,
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuSurveySelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/survey':
			case '/survey/:token':
				selectedOption = 'survey'
				break
			case '/surveyTemplate':
			case '/surveyTemplate/:token':
				selectedOption = 'surveyTemplate'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<AdministrationIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.survey' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{roles.IsUserInRole(roles.ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && (
							<ListItem
								divider
								selected={this.isMenuSurveySelected('surveyTemplate')}
								button
								onClick={() => {
									this.props.history.push('/surveyTemplate')
								}}
							>
								<ListItemIcon>
									<CompaniesIcon color='secondary' />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.surveyTemplates' />} />
							</ListItem>
						)}
						{roles.IsUserInRole(roles.ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) && (
							<ListItem
								divider
								selected={this.isMenuSurveySelected('survey')}
								button
								onClick={() => {
									this.props.history.push('/survey')
								}}
							>
								<ListItemIcon>
									<CompaniesIcon color='secondary' />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.survey' />} />
							</ListItem>
						)}
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

/**
 * Contractors Module
 *
 * @class ContractorsItems
 * @extends {Component}
 */
class ContractorsItems extends Component {
	/**
	 *Creates an instance of ContractorsItems.
	 * @param {*} props
	 * @memberof ContractorsItems
	 */
	constructor(props) {
		super(props)
		this.state = {
			open: this.props.isActiveContractors,
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuContractorsSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/servicesprojects/':
			case '/servicesprojects/:token':
				selectedOption = 'servicesprojects'
				break
			case '/medicalchecks/':
			case '/medicalchecks/:token':
				selectedOption = 'medicalchecks'
				break
			case '/accesscontrol/upload':
				selectedOption = 'accesscontrol/upload'
				break
			case '/trainings/':
			case '/trainings/:token':
				selectedOption = 'trainings'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<ContractorsIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.contractors' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						<ListItem
							divider
							button
							selected={this.isMenuContractorsSelected('servicesprojects')}
							onClick={() => {
								this.props.history.push('/servicesprojects')
							}}
						>
							<ListItemIcon>
								<ServicesProjectsIcon color='secondary' />
							</ListItemIcon>
							<ListItemText inset primary={<Translate id='menu.servicesProjects' />} />
						</ListItem>
						<ListItem
							divider
							selected={this.isMenuContractorsSelected('medicalchecks')}
							button
							onClick={() => {
								this.props.history.push('/medicalchecks')
							}}
						>
							<ListItemIcon>
								<MedicalChecksIcon color='secondary' />
							</ListItemIcon>
							<ListItemText
								inset
								primary={<Translate id='contractorsModule.medicalchecks.medicalchecksTitle' />}
							/>
						</ListItem>
						<ListItem
							divider
							selected={this.isMenuContractorsSelected('trainings')}
							button
							onClick={() => {
								this.props.history.push('/trainings')
							}}
						>
							<ListItemIcon>
								<School color='secondary' />
							</ListItemIcon>
							<ListItemText
								inset
								primary={<Translate id='contractorsModule.medicalchecks.trainingsTitle' />}
							/>
						</ListItem>
						<ListItem
							divider
							selected={this.isMenuContractorsSelected('accesscontrol/upload')}
							button
							onClick={() => {
								this.props.history.push('/accesscontrol/upload')
							}}
						>
							<ListItemIcon>
								<CloseRegistersIcon color='secondary' />
							</ListItemIcon>
							<ListItemText primary={<Translate id='contractorsModule.closeRegistersTitle' />} />
						</ListItem>
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

/**
 * Docuemtns PTM Module
 *
 * @class DocumentsPTMItemsv
 * @extends {Component}
 */
class DocumentsPTMItems extends Component {
	/**
	 *Creates an instance of isActiveDocuments.
	 * @param {*} props
	 * @memberof ContractorsItems
	 */
	constructor(props) {
		super(props)
		this.state = {
			open: this.props.isActiveDocuments,
		}
	}

	/**
	 * Determine if the menu option is selected
	 *
	 * @param {*} menuOption
	 * @returns
	 */
	isMenuDocumentsSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/contracts':
			case '/contracts/create':
			case '/contracts/request':
			case '/contracts/:token':
				selectedOption = 'contracts'
				break
			case '/documents':
			case '/documents/create':
			case '/documents/:token':
				selectedOption = 'documents'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<ContractIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.contractDocuments' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{
							<ListItem
								divider
								button
								selected={this.isMenuDocumentsSelected('contracts')}
								onClick={() => {
									this.props.history.push('/contracts')
								}}
							>
								<ListItemIcon>
									<ContractIcon color='secondary' />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.contracts' />} />
							</ListItem>
						}
						{roles.canBeModuleDocumentsEnvSupplier(this.props.userRolesByCompany) && (
							<ListItem
								divider
								button
								selected={this.isMenuDocumentsSelected('documents')}
								onClick={() => {
									this.props.history.push('/documents')
								}}
							>
								<ListItemIcon>
									<DocumentsIcon color='secondary' />
								</ListItemIcon>
								<ListItemText primary={<Translate id='menu.enviorementaDocuments' />} />
							</ListItem>
						)}
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

/**
 * Guarantee of portal
 */
class GuaranteeItems extends Component {
	/**
	 *Creates an instance of CustomerGuarrantyItems.
	 * @param {*} props
	 * @memberof GuaranteeItems
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			open: this.props.isActiveCustomerGuarantee,
		}
	}

	/**
	 * Determine if the menu option is selected
	 */
	isMenuGuaranteeSelected(menuOption) {
		let selectedOption
		switch (this.props.match.path) {
			case '/customerguarantee':
				selectedOption = 'customerguarantee'
				break
			case '/failureguarantee':
				selectedOption = 'failureguarantee'
				break
			case '/guaranteereport':
			case '/guaranteereport/create':
			case '/guaranteereport/:token':
			case '/guaranteereport/edit/:token':
			case '/guaranteereport/sendnotification/:token':
			case '/guaranteereport/cancel/:token':
				selectedOption = 'guaranteereport'
				break
			default:
				break
		}
		return menuOption === selectedOption
	}

	render() {
		const { selectedCompany } = this.props
		return (
			<React.Fragment>
				<ListItem
					divider
					button
					onClick={() => {
						this.setState({ open: !this.state.open })
					}}
				>
					<ListItemIcon>
						<GuaranteeIcon />
					</ListItemIcon>
					<ListItemText primary={<Translate id='menu.guarantee' />} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						<ListItem
							divider
							selected={this.isMenuGuaranteeSelected('guaranteereport')}
							button
							onClick={() => {
								this.props.history.push('/guaranteereport')
							}}
						>
							<ListItemIcon>
								<GuaranteeReportIcon color='secondary' />
							</ListItemIcon>
							<ListItemText inset primary={<Translate id='menu.guaranteeReport' />} />
						</ListItem>

						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.NonConformities &&
							roles.canUserValideModuleQuality(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuGuaranteeSelected('failureguarantee')}
									button
									onClick={() => {
										this.props.history.push('/failureguarantee')
									}}
								>
									<ListItemIcon>
										<FailureGuarrantee color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.failureGuarantee' />} />
								</ListItem>
							)}

						{selectedCompany &&
							selectedCompany.company_modules &&
							selectedCompany.company_modules.NonConformities &&
							roles.canUserValideModuleQuality(this.props.userRolesByCompany) && (
								<ListItem
									divider
									selected={this.isMenuGuaranteeSelected('customerguarantee')}
									button
									onClick={() => {
										this.props.history.push('/customerguarantee')
									}}
								>
									<ListItemIcon>
										<CustomerGuaranteeIcon color='secondary' />
									</ListItemIcon>
									<ListItemText inset primary={<Translate id='menu.customerGuarantee' />} />
								</ListItem>
							)}
					</List>
				</Collapse>
			</React.Fragment>
		)
	}
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { sub: '', name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	let selectedCompany = state.profile.get('selectedCompany')
	return {
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}

const drawerWidth = 240
const styles = (theme) => ({
	avatar: {
		color: theme.palette.secondary.main,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing.unit * 7 + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing.unit * 9 + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0px',
		...theme.mixins.toolbar,
	},
	root: {
		flexGrow: 1,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
	},
})

export default withLocalize(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, null)(LateralMenuInternalUser)))
)
