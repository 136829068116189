///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import Toaster from '../common/Toaster'
/**Import actions section */

import {
	addExcludeSupplierFromBackEnd,
	getSurveyByIdFromBackEnd,
	removeExcludeSupplierFromList,
	importExcludeSupplierFromExcelFile,
	editSurveyFromBackEnd,
	downloadSurverSupplierResponses,
	downloadSuveySharedWhithSupplierFile
} from '../../store/survey/SurveyActions'
import LoadingPage from '../common/LoadingPage'
import ShowDesktop from './show/ShowDesktop'
import UpdateDatesDialog from './show/UpdateDatesDialog'

const Create = (props) => {
	/**Render hooks section */
	const dispatch = useDispatch()

	/**Redux store information section*/

	const isLoading = useSelector((state) => state.surveys.get('isLoading'))
	const isSaving = useSelector((state) => state.surveys.get('isSaving'))

	const [isOpenPanelUpdateSurveyDates, setIsOpenPanelUpdateSurveyDates] = useState(false)
	/**Redux store information section*/
	const survey = useSelector((state) => (state.surveys.get('survey') ? state.surveys.get('survey').toJS() : null))
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	/**Use effect section */
	useEffect(() => {
		dispatch(getSurveyByIdFromBackEnd(props.match.params.id))
	}, [dispatch, props.match.params.id])

	if (isLoading) return <LoadingPage />

	const onOpenClosePanelEditDates = () => {
		setIsOpenPanelUpdateSurveyDates(!isOpenPanelUpdateSurveyDates)
	}

	const onAddExcludeSupplierFromBackEnd = (optionSelected) => {
		return dispatch(addExcludeSupplierFromBackEnd(props.match.params.id, optionSelected.code, optionSelected.name))
			.then(() => {
				// dispatch(getSurveyTemplateByIdFromBackEnd(props.match.params.id, false))

				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onDownloadSurverSupplierResponses = () => {
		return dispatch(downloadSurverSupplierResponses(props.match.params.id))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	

	const onRemoveExcludeSupplierFromBackEnd = (supplierCode) => {
		return dispatch(removeExcludeSupplierFromList(props.match.params.id, supplierCode))
			.then(() => {
				// dispatch(getSurveyTemplateByIdFromBackEnd(props.match.params.id, false))

				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.removeCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onAddExcludeSupplierFromExcelFileFromBackEnd = (file) => {
		return dispatch(importExcludeSupplierFromExcelFile(file, props.match.params.id))
			.then(() => {
				// dispatch(getSurveyTemplateByIdFromBackEnd(props.match.params.id, false))

				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
				return Promise.resolve()
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onEditSurveyDatesFromBackEnd = (surveyModel) => {
		return dispatch(editSurveyFromBackEnd(surveyModel))
			.then(() => {
				onOpenClosePanelEditDates()
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
				return Promise.resolve()
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onDownloadSuveySharedWhithSupplierFile = (fileName) => {
		return dispatch(downloadSuveySharedWhithSupplierFile(props.match.params.id, fileName))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
				return Promise.resolve()
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	return (
		<>
			<ShowDesktop
				//objects
				survey={survey}
				//functions
				onAddExcludeSupplierFromBackEnd={onAddExcludeSupplierFromBackEnd}
				onRemoveExcludeSupplierFromBackEnd={onRemoveExcludeSupplierFromBackEnd}
				onAddExcludeSupplierFromExcelFileFromBackEnd={onAddExcludeSupplierFromExcelFileFromBackEnd}
				onOpenClosePanelEditDates={onOpenClosePanelEditDates}
				onDownloadSurverSupplierResponses={onDownloadSurverSupplierResponses}
				onDownloadSuveySharedWhithSupplierFile={onDownloadSuveySharedWhithSupplierFile}
				//bools
				isSaving={isSaving}
			/>
			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>

			{isOpenPanelUpdateSurveyDates && (
				<UpdateDatesDialog
					survey={survey}
					open={isOpenPanelUpdateSurveyDates}
					onEditSurveyDatesFromBackEnd={onEditSurveyDatesFromBackEnd}
					isSaving={isSaving}
				/>
			)}
		</>
	)
}

const styles = (theme) => ({})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Create)))
